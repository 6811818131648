var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Make Supplemental",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "285px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Amount",
      "prop": "amount"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "amount", $$v);
      },
      expression: "dataForm.amount"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Third party fee",
      "prop": "thirdPartyFee"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.thirdPartyFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "thirdPartyFee", $$v);
      },
      expression: "dataForm.thirdPartyFee"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Description (For External Disclosure)",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Please refer to the fee letter(s) signed on YYYY-MM-DD for the supplemental fees."
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Other Fee",
      "prop": "otherFee"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.otherFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "otherFee", $$v);
      },
      expression: "dataForm.otherFee"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Note For Other Fee",
      "prop": "otherRemark"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width text-left",
    attrs: {
      "placeholder": "Note For Other Fee",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.otherRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "otherRemark", $$v);
      },
      expression: "dataForm.otherRemark"
    }
  }, _vm._l(_vm.otherFeeEnOptions, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Fee Letter"
    }
  }, [_c('el-file-uploader', {
    model: {
      value: _vm.dataForm.feeLetter,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "feeLetter", $$v);
      },
      expression: "dataForm.feeLetter"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }