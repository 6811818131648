var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-select', {
    style: {
      width: "".concat(_vm.width, "px")
    },
    attrs: {
      "multiple": "",
      "clearable": "",
      "collapse-tags": "",
      "placeholder": _vm.placeholder
    },
    on: {
      "change": _vm.selectChange
    },
    model: {
      value: _vm.selectList,
      callback: function callback($$v) {
        _vm.selectList = $$v;
      },
      expression: "selectList"
    }
  }, [_c('el-checkbox', {
    staticClass: "ml20 pt10 pb8",
    on: {
      "change": _vm.selectAll
    },
    model: {
      value: _vm.isMultipleSelectAll,
      callback: function callback($$v) {
        _vm.isMultipleSelectAll = $$v;
      },
      expression: "isMultipleSelectAll"
    }
  }, [_vm._v("Select all")]), _vm._l(_vm.optionList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }