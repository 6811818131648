var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": _vm.type == 'Finish' ? 'Batch Finish Review' : 'Batch Refund Review',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "60px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_vm.type == 'Finish' ? _c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": "AUDIT_FAIL"
    }
  }, [_vm._v("Rejected")]), _c('el-radio', {
    attrs: {
      "label": "AUDIT_SUCCESS"
    }
  }, [_vm._v("Pass")])], 1) : _vm._e(), _vm.type == 'Refund' ? _c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": "REFUND_APPLY_REJECT"
    }
  }, [_vm._v("Rejected")]), _c('el-radio', {
    attrs: {
      "label": "REFUND_APPLY_PASS"
    }
  }, [_vm._v("Pass")])], 1) : _vm._e()], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }