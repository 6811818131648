var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Company Name")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    on: {
      "click": function click($event) {
        return _vm.openEdit();
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Company type")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.companyType || "empty"))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Proposed Company Name")]), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "company-name"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyName1))]), _c('span', {
    staticClass: "company-type"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyType1))])])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "company-name"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyName2))]), _c('span', {
    staticClass: "company-type"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyType2))])])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "company-name"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyName3))]), _c('span', {
    staticClass: "company-type"
  }, [_vm._v(_vm._s(_vm.detail.proposedCompanyType3))])])])])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Primary Activity")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.primaryActivity))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Primary User-Described Activity Description")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.primaryActivityDescription))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Secondary Activity")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.secondaryActivity))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Secondary User-Described Activity Description")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.secondaryActivityDescription))])])]), _vm.isNeedNomineeDirector ? _c('div', {
    staticClass: "no-event desp-items-group",
    attrs: {
      "id": "engageNomineeDirectorCheckbox"
    }
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Nominee Director Service")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('el-checkbox', {
    staticClass: "checkboxCustom",
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.detail.hasNomineeDirector,
      callback: function callback($$v) {
        _vm.$set(_vm.detail, "hasNomineeDirector", $$v);
      },
      expression: "detail.hasNomineeDirector"
    }
  }, [_vm._v(" We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. (\"EStar\"), and we understand that any legal impact caused by the Nominee Director's action has nothing to do with EStar. ")])], 1)])]) : _vm._e(), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "businessType": _vm.detail.businessType,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }