<template>
  <el-dialog width="750px" :title="!dataForm.id ? 'Add Quotation (V)' : 'Edit Quotation (V)'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" autocomplete="off" label-width="286px">
      <el-form-item label="Linked Transaction No." prop="businessNo">
        <el-select
          v-model="dataForm.businessNo"
          placeholder="Transaction No."
          clearable
          filterable
          remote
          :remote-method="getbusinessNoRemote"
          @clear="getbusinessNoRemote('')"
          @focus="getbusinessNoRemote('')"
          :loading="businessNoLoading"
          @change="businessNoChange"
          class="fluid-width"
        >
          <el-option
            v-for="(item, xv) in businessNoList"
            :label="`${item.businessNo}-${item.companyName}`"
            :key="`${item.businessNo}${xv}`"
            :value="item.businessNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Billing Party" prop="billingParty">
        <el-input v-model="dataForm.billingParty" placeholder="Billing party"></el-input>
      </el-form-item>
      <el-form-item label="Address">
        <el-input v-model="dataForm.address" placeholder="Address"></el-input>
      </el-form-item>
      <el-form-item label="Country">
        <el-select v-model="dataForm.country" placeholder="Country" filterable class="fluid-width text-left">
          <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Code">
        <el-input v-model="dataForm.postalCode" placeholder="Code"></el-input>
      </el-form-item>
      <el-form-item label="Currency" prop="paymentDetail.currency">
        <el-select v-model="dataForm.paymentDetail.currency" placeholder="Currency" filterable class="fluid-width" @change="currencyChange">
          <el-option v-for="item in currencyList" :key="item.value" :label="item.title" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Exchange Rate" prop="paymentDetail.exchangeRate" v-if="dataForm.paymentDetail.currency != 'SGD'">
        <el-input v-model="dataForm.paymentDetail.exchangeRate" v-positivePoint4 placeholder="0.0000"></el-input>
      </el-form-item>
      <el-form-item label="Amount" prop="paymentDetail.amount">
        <el-input v-model="dataForm.paymentDetail.amount" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">{{ dataForm.paymentDetail.currencyUnit }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Description (For External Disclosure)" prop="applyReason">
        <el-input
          v-model="dataForm.applyReason"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="English wording is mandatory, Chinese translation is optional"
        ></el-input>
      </el-form-item>
      <el-form-item label="Third party fee" prop="paymentDetail.thirdPartyFee">
        <el-input v-model="dataForm.paymentDetail.thirdPartyFee" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">{{ dataForm.paymentDetail.currencyUnit }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Note For Third Party" prop="noteForThirdParty">
        <el-select v-model="dataForm.noteForThirdParty" placeholder="Note For Third Party" filterable class="fluid-width text-left">
          <el-option v-for="item in thirdPartyFeeEnOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Other Fee" prop="paymentDetail.otherFee">
        <el-input v-model="dataForm.paymentDetail.otherFee" v-minusPoint2 placeholder="0.00">
          <template slot="prefix">{{ dataForm.paymentDetail.currencyUnit }}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Note For Other Fee" prop="noteForOther">
        <el-select v-model="dataForm.noteForOther" placeholder="Note For Other Fee" filterable class="fluid-width text-left">
          <el-option v-for="item in otherFeeEnOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      visible: false,
      id: 0,
      dataForm: {
        billingParty: "",
        businessNo: "",
        address: "",
        country: "",
        postalCode: "",
        paymentDetail: {
          currency: null,
          currencyUnit: null,
          exchangeRate: null,
          amount: null,
          thirdPartyFee: null,
          otherFee: null,
        },
        noteForThirdParty: "",
        noteForOther: "",
        applyReason: "",
        attachmentUrls: [],
      },
      dataRule: {
        billingParty: [{ required: true, message: "please enter", trigger: "submit" }],
        businessNo: [{ required: true, message: "please select", trigger: "submit" }],
        "paymentDetail.currency": [{ required: true, message: "please select", trigger: "submit" }],
        "paymentDetail.exchangeRate": [{ required: true, message: "please enter", trigger: "submit" }],
        "paymentDetail.amount": [{ required: true, message: "please enter", trigger: "submit" }],
        "paymentDetail.thirdPartyFee": [{ required: true, message: "please enter", trigger: "submit" }],
        "paymentDetail.otherFee": [{ required: true, message: "please enter", trigger: "submit" }],
        applyReason: [{ required: true, message: "please enter", trigger: "submit" }],
        noteForThirdParty: [{ required: true, message: "please select", trigger: "submit" }],
        noteForOther: [{ required: true, message: "please select", trigger: "submit" }],
      },
      submitControl: true,
      businessNoLoading: false,
      businessNoList: [],
    };
  },
  computed: {
    ...mapState("constant", ["countryList", "thirdPartyFeeEnOptions", "otherFeeEnOptions", "currencyList"]),
  },
  methods: {
    async init(data) {
      this.visible = true;
      this.submitControl = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      await this.getbusinessNoRemote("");
      this.id = data ? data.id : 0;
      this.dataForm = {
        billingParty: data?.billingParty,
        businessNo: data?.businessNo,
        address: data?.address,
        country: data?.country,
        postalCode: data?.postalCode,
        paymentDetail: {
          currency: data?.paymentDetail?.currency,
          currencyUnit: data?.paymentDetail?.currencyUnit,
          exchangeRate: data?.paymentDetail?.exchangeRate,
          amount: data?.paymentDetail?.amount,
          thirdPartyFee: data?.paymentDetail?.thirdPartyFee,
          otherFee: data?.paymentDetail?.otherFee,
        },
        applyReason: data?.applyReason,
        attachmentUrls: data?.attachmentUrls || [],
        noteForThirdParty: data?.noteForThirdParty,
        noteForOther: data?.noteForOther,
      };
    },
    // businessNo Change
    businessNoChange() {
      const resultData = this.businessNoList.find(item => item.businessNo == this.dataForm.businessNo);
      if (resultData.companyName) {
        this.$set(this.dataForm, "billingParty", resultData.companyName);
      } else {
        this.$set(this.dataForm, "billingParty", "");
      }
    },
    // currency - Change
    currencyChange() {
      const currency = this.dataForm.paymentDetail.currency;
      this.dataForm.paymentDetail.currencyUnit = this.currencyList.find(item => item.value == currency)?.symbol;
      this.dataForm.paymentDetail.exchangeRate = null;
      this.$forceUpdate();
    },
    // 获取Transaction No列表
    async getbusinessNoRemote(query) {
      this.businessNoLoading = true;
      const { code, data } = await this.$http.get("/admin/businessInfo/getList", {
        params: {
          keywords: query,
        },
      });
      this.businessNoLoading = false;
      if (code === 200) {
        this.businessNoList = data;
      } else {
        this.businessNoList = [];
      }
    },
    // 表单提交
    async formSubmitRequest() {
      const dataForm = JSON.parse(JSON.stringify(this.dataForm));
      if (dataForm.paymentDetail.currency == "SGD") {
        dataForm.paymentDetail.exchangeRate = 1;
      }
      if (this.id) {
        return await this.$http.post(`/admin/businessSupplement/${this.id}/update`, dataForm);
      } else {
        return await this.$http.post("/admin/businessSupplement/create", dataForm);
      }
    },
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const { code, msg } = await this.formSubmitRequest();
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                // this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input--prefix {
  .el-input__inner {
    padding-left: 40px;
  }
}
</style>
