var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Confirmation:")]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "applicant"
    }
  }), _vm._v(" Applicant information ")], 1), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant Name")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantName))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Mobile")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantPhone))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Email")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantEmail))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Residential Address")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.residentialAddress))])])]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant role")]), (_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? [_c('el-select', {
    staticClass: "wd320",
    attrs: {
      "value": _vm.applicantRole,
      "placeholder": "Please Select"
    },
    on: {
      "change": _vm.applicantPositionChange
    }
  }, _vm._l(_vm.applicantPositionOptions, function (item) {
    return _c('el-option', {
      key: "applicantPosition".concat(item.value),
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)] : _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.applicantPositionFormat(_vm.detail.applicantPosition)))])], 2)])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "country"
    }
  }), _vm._v(" Location of the proposed company/fund ")], 1), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.country))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "planAndServices"
    }
  }), _vm._v(" Choice of service(s) ")], 1), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Entity type")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.entityTypeFormat(_vm.detail.businessGenre)))])]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Business type")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.businessTypeFormat(_vm.detail.businessType)))])]), _vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Promotion code")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.promotionCode))])])] : _vm._e()], 2), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', {
    staticClass: "mt10"
  }, [_c('quotation-detail', {
    attrs: {
      "quotationList": _vm.detail.quotationList,
      "applymentStatus": _vm.detail.applymentStatus
    }
  })], 1) : _c('div', {
    staticClass: "mt10"
  }, [_c('plan-detail', {
    attrs: {
      "businessType": _vm.detail.businessType,
      "goodsList": _vm.detail.goodsList
    }
  })], 1)])])]), _vm.invoiceObj.amendsList.length != 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Supplemental")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "mt10"
  }, [_c('el-table', {
    staticClass: "amendsTable",
    attrs: {
      "header-cell-style": {
        color: '#011A45',
        background: '#fff5f0 !important',
        height: '62px'
      },
      "row-style": {
        background: '#fff5f0'
      },
      "data": _vm.invoiceObj.amendsList
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.amendsDetail.amount)))])];
      }
    }], null, false, 1072839290)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Third party fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.amendsDetail.thirdPartyFee)))])];
      }
    }], null, false, 3470685181)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "amendsDetail.remark",
      "label": "Description (For External Disclosure)"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Other Fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.amendsDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.amendsDetail.otherFee)))]) : _vm._e(), scope.row.amendsDetail.otherRemark ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.amendsDetail.otherRemark))]), _c('span', {
          staticClass: "ml8 fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 1004123646)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Fee Letter"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.amendsDetail && scope.row.amendsDetail.feeLetter ? _c('a', {
          staticClass: "fs12 blue mr10",
          attrs: {
            "href": scope.row.amendsDetail.feeLetter.url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(scope.row.amendsDetail.feeLetter.name) + " ")]) : _vm._e()];
      }
    }], null, false, 4195926463)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Supplemental Invoice"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "to-link",
          on: {
            "click": function click($event) {
              return _vm.invoiceLink('amends', scope.row.invoiceNumber);
            }
          }
        }, [_vm._v("Invoice")])];
      }
    }], null, false, 1814051382)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "orderStatus",
      "label": "Status",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderStatus == 'UNPAID' && (_vm.detail.applymentStatus == 'ACRA_APPLYING' || _vm.detail.applymentStatus == 'REVISING') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.closeHandler(scope.row.orderId);
            }
          }
        }, [_vm._v(" Close ")]) : _vm._e()];
      }
    }], null, false, 1604146559)
  })], 1)], 1)])])]) : _vm._e(), _vm.detail.businessType == 'COMPANY_INCORPORATION' || _vm.detail.businessType == 'SERVICE_TRANSFER' ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Generic Invoice")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm.detail.applymentStatus != 'DRAFT' && _vm.invoiceObj.unpaId ? _c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('unpaid');
      }
    }
  }, [_vm._v(" Invoice "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1) : _vm._e(), (_vm.detail.applymentStatus == 'REFUND_APPLY_PASS' || _vm.detail.applymentStatus == 'CANCELED') && _vm.invoiceObj.refundId ? _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('refund');
      }
    }
  }, [_vm._v(" Debit Invoice "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1) : _vm._e()])])]) : _vm._e(), _vm.isAuth('company:coordinate') ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Front user website")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('details');
      }
    }
  }, [_vm._v(" View details "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1)])])]) : _vm._e(), _vm.isAuth('company:coordinate') && (_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED') ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Front user Fee Letter")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('feeLetter', 'en');
      }
    }
  }, [_vm._v(" View Fee Letter(EN) "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1), _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('feeLetter', 'zh');
      }
    }
  }, [_vm._v(" View Fee Letter(CN) "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1)])])]) : _vm._e(), _vm.isAuth('company:coordinate') && (_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED') && _vm.detail.feeLetterUrls && _vm.detail.feeLetterUrls.length > 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Service Quotation")]), _c('div', {
    staticClass: "pt10 desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, _vm._l(_vm.detail.feeLetterUrls, function (item) {
    return _c('p', {
      key: item.url,
      staticClass: "m0 lh22 pb10"
    }, [_c('a', {
      staticClass: "fs14 blue",
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)])]) : _vm._e(), _vm.isAuth('company:coordinate') && _vm.detail.hasRenew ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Linked Transaction No. (Renewal)")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.detail.linkedApplymentNo) + " " + _vm._s(_vm.detail.linkedApplyment && _vm.detail.linkedApplyment.applymentStatus ? _vm.applymentStatusFormat(_vm.detail.linkedApplyment.applymentStatus) : "") + " ")])])])]) : _vm._e(), _vm.isAuth('company:coordinate') && _vm.detail.hasLaterService ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Linked Transaction No. (Supplemental)")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, _vm._l(_vm.detail.laterServiceApplymentList, function (item) {
    return _c('span', {
      key: item.id,
      staticClass: "mr50"
    }, [_vm._v(" " + _vm._s(item.applymentNo) + " " + _vm._s(_vm.applymentStatusFormat(item.applymentStatus)) + " ")]);
  }), 0)])]) : _vm._e(), _vm.isAuth('company:coordinate') && _vm.detail.excApplymentNo ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v(" Linked Transaction No. "), _vm.detail.isRenew ? _c('span', [_vm._v("(Be Renewed)")]) : _vm._e(), _vm.detail.isLaterService ? _c('span', [_vm._v("(Have Supplements)")]) : _vm._e()]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', [_vm._v(_vm._s(_vm.detail.excApplymentNo))])])])]) : _vm._e(), _vm.quotationVList.length != 0 ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Quotation (V)")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "mt10"
  }, [_c('el-table', {
    staticClass: "amendsTable",
    attrs: {
      "header-cell-style": {
        color: '#011A45',
        background: '#fff5f0 !important',
        height: '62px'
      },
      "row-style": {
        background: '#fff5f0'
      },
      "data": _vm.quotationVList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "supplement.supplementNo",
      "header-align": "center",
      "align": "center",
      "label": "Quotation (V) No."
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "supplement.billingParty",
      "header-align": "center",
      "align": "center",
      "label": "Billing Party"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Exchange Rate",
      "min-width": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.supplement && scope.row.supplement.paymentDetail ? [scope.row.supplement.paymentDetail.currency != 'SGD' ? _c('p', [_vm._v(_vm._s(scope.row.supplement.paymentDetail.exchangeRate))]) : _vm._e()] : _vm._e()];
      }
    }], null, false, 235262699)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.supplement && scope.row.supplement.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.supplement.paymentDetail.amount, scope.row.supplement.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.supplement.applyReason ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.supplement.applyReason))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 2217247247)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Third party fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.supplement && scope.row.supplement.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.supplement.paymentDetail.thirdPartyFee, scope.row.supplement.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.supplement.noteForThirdParty ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.supplement.noteForThirdParty))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 3627506216)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Other Fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.supplement && scope.row.supplement.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.supplement.paymentDetail.otherFee, scope.row.supplement.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.supplement.noteForOther ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.supplement.noteForOther))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 3089634401)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Attachment",
      "min-width": "96"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.supplement ? _vm._l(scope.row.supplement.attachmentUrls, function (item) {
          return _c('p', {
            key: item.url
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_target"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        }) : _vm._e()];
      }
    }], null, false, 422660062)
  }), _c('el-table-column', {
    attrs: {
      "prop": "supplement.createTime",
      "header-align": "center",
      "align": "center",
      "label": "Create Time"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Generic Invoice"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "to-link",
          on: {
            "click": function click($event) {
              return _vm.invoiceLink('quotationV', scope.row.orderNo);
            }
          }
        }, [_vm._v("Invoice")])];
      }
    }], null, false, 2094040335)
  }), _c('el-table-column', {
    attrs: {
      "prop": "supplement.status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "formatter": _vm.supplementStatusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Order Status",
      "formatter": _vm.statusFormat
    }
  })], 1)], 1)])])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }