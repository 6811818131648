var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Credit Historical Approval Records",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "tableWrap"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "createAt",
      "header-align": "center",
      "align": "center",
      "label": "Handling Time",
      "formatter": _vm.localDateFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "creditStatus",
      "header-align": "center",
      "align": "center",
      "label": "Applyment Status",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "Reason"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "attachmentUrls",
      "header-align": "center",
      "align": "center",
      "label": "Attachment"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.attachmentUrls, function (item) {
          return _c('p', {
            key: item.url
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_target"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "header-align": "center",
      "label": "Handler"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.userInfo && scope.row.userInfo.nickname ? _c('p', [_vm._v(" " + _vm._s(scope.row.userInfo.nickname) + " ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }