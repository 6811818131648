var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "append-to-body": "",
      "width": "870px",
      "title": "Upload Files",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "upload-container"
  }, [_vm.fileList.length === 0 ? [_c('div', {
    staticClass: "upload-button"
  }, [_c('div', [_c('svg-icon', {
    staticClass: "upload-icon",
    attrs: {
      "name": "upload"
    }
  }), _c('div', {
    staticClass: "upload-text"
  }, [_vm._v("click to add files")])], 1)]), _c('input', {
    ref: "fileupload1",
    staticStyle: {
      "opacity": "0",
      "width": "100%",
      "height": "100%",
      "position": "absolute",
      "left": "0",
      "top": "0"
    },
    attrs: {
      "type": "file",
      "multiple": "",
      "accept": _vm.accept
    },
    on: {
      "change": _vm.onFileChange
    }
  })] : [_c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.uploading,
      expression: "uploading"
    }],
    staticClass: "file-preview"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "data": _vm.fileList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "name",
      "width": "300",
      "show-overflow-tooltip": "",
      "label": "File Name"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "size",
      "label": "Size",
      "align": "center",
      "formatter": _vm.fileSizeFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "align": "center",
      "min-width": "200",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteRow(scope.$index);
            }
          }
        }, [_vm._v("Remove")])];
      }
    }])
  })], 1)], 1), _c('div', {
    staticClass: "add-file-btn"
  }, [_c('div', [_c('svg-icon', {
    staticClass: "upload-more-icon",
    attrs: {
      "name": "upload"
    }
  }), _c('div', {
    staticClass: "upload-text"
  }, [_vm._v("add more files")])], 1), _c('input', {
    ref: "fileupload2",
    staticStyle: {
      "opacity": "0",
      "width": "100%",
      "height": "100%",
      "position": "absolute",
      "left": "0",
      "top": "0"
    },
    attrs: {
      "type": "file",
      "multiple": "",
      "accept": _vm.accept
    },
    on: {
      "change": _vm.onFileChange
    }
  })])]], 2), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "type": "primary",
      "disabled": _vm.uploading || !_vm.checked
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Upload")])], 1), _c('div', {
    staticClass: "pb20"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }