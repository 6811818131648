<template>
  <el-dialog width="700px" :title="!dataForm.id ? 'Add Commission' : 'Edit Commission'" :close-on-click-modal="false" :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="150px">
      <el-form-item label="Name" prop="name">
        <el-input v-model="dataForm.name" placeholder="Name"></el-input>
      </el-form-item>
      <el-form-item label="Commission Rate" prop="commissionRate">
        <el-input v-model="dataForm.commissionRate" placeholder="Commission Rate" class="wd250" v-positivePoint2>
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="Contact Info" prop="contact">
        <el-input
          v-model="dataForm.contact"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Contact Info"
        ></el-input>
      </el-form-item>
      <el-form-item label="Remark" prop="remark">
        <el-input
          v-model="dataForm.remark"
          type="textarea"
          :rows="5"
          :autosize="{ minRows: 4 }"
          resize="none"
          placeholder="Remark"
        ></el-input>
      </el-form-item>
      <el-form-item label="Attachments" prop="attachmentUrls">
        <el-multiple-file-uploader v-model="dataForm.attachmentUrls"></el-multiple-file-uploader>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">Submit</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { div, multiply } from "@/utils";
import { mapState } from "vuex";
export default {
  data() {
    const checkRate = (rule, value, callback) => {
      if (value) {
        const num = Number(value);
        if (num < 0 || num > 100) {
          return callback(new Error("The number entered cannot be less than 0 or greater than 100"));
        } else {
          return callback();
        }
      }
    };
    return {
      visible: false,
      dataForm: {
        id: 0,
        name: "",
        commissionRate: null,
        contact: "",
        remark: "",
        attachmentUrls: [],
      },
      dataRule: {
        name: [{ required: true, message: "please enter", trigger: "submit" }],
        commissionRate: [
          { required: true, message: "please enter", trigger: "submit" },
          { validator: checkRate, message: "The number entered cannot be less than 0 or greater than 100", trigger: "submit" },
        ],
      },
      submitControl: true,
    };
  },
  computed: {
    ...mapState("constant", ["prefixOptions"]),
  },
  methods: {
    init(id) {
      this.submitControl = true;
      this.visible = true;
      this.$nextTick().then(() => {
        this.$refs["dataForm"].resetFields();
        if (id) {
          this.getDetail(id);
        } else {
          this.dataForm.id = undefined;
        }
      });
    },
    //详情
    async getDetail(id) {
      const { data } = await this.$http.get(`/admin/stCommission/${id}/detail`);
      const resData = data;
      resData.commissionRate = multiply(resData.commissionRate, 100);
      this.dataForm = resData;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        if (valid && this.submitControl) {
          this.submitControl = false;
          const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
          dataFormCopy.commissionRate = div(dataFormCopy.commissionRate, 100);
          const { code, msg } = await this.$http.post(
            this.dataForm.id ? `/admin/stCommission/${this.dataForm.id}/update` : `/admin/stCommission/create`,
            dataFormCopy,
          );
          if (code === 200) {
            this.$message({
              message: "success",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.visible = false;
                this.$emit("refreshDataList");
                this.submitControl = true;
              },
            });
          } else {
            this.$message.error(msg);
            this.submitControl = true;
          }
        }
      });
    },
  },
};
</script>
