var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Company Details")]), _c('div', {
    staticClass: "opts"
  }, [(_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'ACRA_REJECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    ref: "",
    on: {
      "click": function click($event) {
        return _vm.openEdit();
      }
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Financial Year End (e.g. 31 December)")]), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.financialYearEnd && _vm.detail.financialYearEnd.includes('/') ? _c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "mr20"
  }, [_vm._v("Month " + _vm._s(_vm.detail.financialYearEnd.split("/")[0]))]), _c('span', [_vm._v("Date " + _vm._s(_vm.detail.financialYearEnd.split("/")[1]))])]) : _vm._e()])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Financial Year Period")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.financialYearPeriod))])])]), _vm.editVisible ? _c('edit-modal', {
    ref: "editModal",
    attrs: {
      "businessType": _vm.detail.businessType
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }