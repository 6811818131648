var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step mt40"
  }, [_c('el-card', [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Fee difference between service type and linked transaction")])]), _c('div', {
    staticClass: "pt14"
  }, [_c('el-row', {
    staticClass: "customTh",
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Type")])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Linked Transactions")])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Service Type")])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Difference "), _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "trigger": "hover"
    }
  }, [_c('p', {
    staticClass: "fs12"
  }, [_vm._v("Difference = Related Transaction (Total) (Linked Transactions) - Related Transaction (Total) (Service Type)")]), _c('p', {
    staticClass: "fs12"
  }, [_vm._v("Difference = Related Turnover (Total) (Linked Transactions) - Related Turnover (Total) (Service Type)")]), _c('span', {
    staticClass: "pearl-blue fs16 ml4 el-icon-question",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1)])], 1), _c('el-row', {
    staticClass: "customTbody",
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Related Transaction (Total)")])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.linkedTransactionsRelatedTransactionTotal)))])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.serviceTypeRelatedTransactionTotal)))])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center red fwb"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.relatedTransactionTotalDifference)))])])], 1), _c('el-row', {
    staticClass: "customTbody",
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v("Related Turnover (Total)")])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.linkedTransactionsRelatedTurnoverTotal)))])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.serviceTypeRelatedTurnoverTotal)))])]), _c('el-col', {
    attrs: {
      "span": 6
    }
  }, [_c('p', {
    staticClass: "text-center red fwb"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.setDifferenceData.RelatedTurnoverTotalDifference)))])])], 1)], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }