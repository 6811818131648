var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "700px",
      "title": "Credit Review",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "headline",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(" Credit Review "), _c('span', [_vm._v("(This is to apply approval for paying later instead of in advance.)")])]), _c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "160px"
    }
  }, [_vm.detail && _vm.detail.credit ? _c('el-form-item', {
    attrs: {
      "label": "Apply Reason"
    }
  }, [_vm._v(_vm._s(_vm.detail.credit.applyReason))]) : _vm._e(), _vm.detail && _vm.detail.credit && _vm.detail.credit.attachmentUrls ? _c('el-form-item', {
    attrs: {
      "label": "Apply Attachments"
    }
  }, _vm._l(_vm.detail.credit.attachmentUrls, function (item) {
    return _c('div', {
      key: item.url
    }, [_c('a', {
      attrs: {
        "href": item.url,
        "target": "_target"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Agreed to pay later",
      "prop": "creditStatus"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.creditStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "creditStatus", $$v);
      },
      expression: "dataForm.creditStatus"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": "REJECTED"
    }
  }, [_vm._v("Rejected")]), _c('el-radio', {
    attrs: {
      "label": "PASSED"
    }
  }, [_vm._v("Pass")])], 1)], 1), _vm.dataForm.creditStatus == 'REJECTED' ? _c('el-form-item', {
    attrs: {
      "label": "Reject Reason",
      "prop": "auditRemark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Reason",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.auditRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "auditRemark", $$v);
      },
      expression: "dataForm.auditRemark"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.isAuth('finance:audit') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }