var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pl-module"
  }, [_c('div', {
    staticClass: "mb20",
    on: {
      "click": function click($event) {
        return _vm.openSelector();
      }
    }
  }, [_vm._t("default", function () {
    return [_c('el-button', [_vm._v("Add Service")])];
  })], 2), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    }
  }), _c('ul', _vm._l(_vm.currValue, function (module, idx) {
    return _c('li', {
      key: module.id
    }, [_c('module-item', {
      attrs: {
        "metas": _vm.getAvaiableMetas(module.id)
      },
      on: {
        "input": function input($event) {
          return _vm.moduleChange();
        }
      },
      model: {
        value: _vm.currValue[idx],
        callback: function callback($$v) {
          _vm.$set(_vm.currValue, idx, $$v);
        },
        expression: "currValue[idx]"
      }
    })], 1);
  }), 0), _vm.selectorVisible ? _c('module-panel', {
    ref: "selector",
    attrs: {
      "moduleList": _vm.remaningModules
    },
    on: {
      "selectFinish": _vm.selectFinish
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }