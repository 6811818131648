var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Fund's Officer Details - Shareholder(s)/Director(s)",
      "width": "1100px",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "company-member-list pb60"
  }, [_c('el-form', {
    attrs: {
      "inline": true
    }
  }, [_c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addMember();
      }
    }
  }, [_vm._v("Add")])], 1)], 1), _c('div', [_c('el-table', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "fullName",
      "header-align": "center",
      "align": "center",
      "width": "250",
      "label": "Name"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "memberType",
      "header-align": "center",
      "align": "center",
      "width": "250",
      "label": "Type",
      "formatter": _vm.memberTypeFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "positionHeld",
      "header-align": "center",
      "align": "center",
      "width": "250",
      "label": "Position Held",
      "formatter": _vm.positionHeldFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "min-width": "200",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.positionHeld != 'NOMINEE_DIRECTOR' && _vm.isApplymentAuth('company:coordinate') && (_vm.applymentStatus === 'REVISING' || _vm.applymentStatus === 'CORRECTED' || _vm.applymentStatus === 'ILLEGAL' || _vm.applymentStatus === 'AUDIT_FAIL' || _vm.applymentStatus === 'REFUND_APPLY_DENY' || _vm.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.applymentStatus === 'ACRA_REJECTED') ? [_c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.updateHandle(scope.row);
            }
          }
        }, [_vm._v("Edit")]), _c('el-button', {
          attrs: {
            "type": "text",
            "size": "small"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteMember(scope.row.id);
            }
          }
        }, [_vm._v("Delete")])] : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _vm.updateOfficerVisible ? _c('update-officer', {
    ref: "updateOfficer",
    attrs: {
      "businessType": _vm.businessType,
      "fundInfoList": _vm.fundInfoList
    },
    on: {
      "change": _vm.doUpdateMember
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }