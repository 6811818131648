var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-card', [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 15
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "174px"
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Business Type",
      "prop": "businessType"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "placeholder": "Select BusinessType"
    },
    model: {
      value: _vm.dataForm.businessType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "businessType", $$v);
      },
      expression: "dataForm.businessType"
    }
  }, _vm._l(_vm.businessTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Country",
      "prop": "country"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "placeholder": "Select Country"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Plan Name",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "name", $$v);
      },
      expression: "dataForm.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Name(CN)",
      "prop": "nameCn"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Plan Name(CN)"
    },
    model: {
      value: _vm.dataForm.nameCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "nameCn", $$v);
      },
      expression: "dataForm.nameCn"
    }
  })], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Applicable Person",
      "prop": "brief"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Applicable Person",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.summary,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "summary", $$v);
      },
      expression: "dataForm.summary"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Applicable Person(CN)",
      "prop": "brief"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Applicable Person(CN)"
    },
    model: {
      value: _vm.dataForm.summaryCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "summaryCn", $$v);
      },
      expression: "dataForm.summaryCn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Services",
      "prop": "moduleList"
    }
  }, [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_c('span', [_vm._v(" Services "), _c('el-popover', {
    attrs: {
      "placement": "top-start",
      "width": "220",
      "trigger": "hover",
      "content": "Vary by business type and country"
    }
  }, [_c('i', {
    staticClass: "el-icon-warning-outline",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  })])], 1)]), _c('plan-module-picker', {
    attrs: {
      "modules": _vm.planModules
    },
    model: {
      value: _vm.dataForm.moduleList,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "moduleList", $$v);
      },
      expression: "dataForm.moduleList"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Price",
      "prop": "price"
    }
  }, [_c('el-input', {
    staticClass: "wd150",
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.dataForm.price,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "price", $$v);
      },
      expression: "dataForm.price"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v("S$")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("Disable")]), _c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Enable")])], 1)], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("Submit")]), _c('el-button', {
    on: {
      "click": function click() {
        return _vm.$router.push('/sys/pkg/plan');
      }
    }
  }, [_vm._v("Back")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }