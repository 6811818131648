var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb30 min-hg460 pd26 bg-white border-radius-10"
  }, [_c('div', {
    staticClass: "flex flex-direction-row align-center justify-between"
  }, [_vm.clientType == 'acceptedData' ? _c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Accepted Client")]) : _vm._e(), _vm.clientType == 'pendingData' ? _c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Pending Modified Client")]) : _vm._e(), _vm.clientType == 'potentialData' ? _c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Potential Client")]) : _vm._e(), _vm.clientType == 'lostData' ? _c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Lost Client")]) : _vm._e()]), _c('div', {
    staticClass: "mt14 flex justify-center wd340 capsule-wrap"
  }, [_c('div', {
    staticClass: "wd80 capsule-item",
    class: {
      'capsule-active': _vm.clientType == 'acceptedData'
    },
    on: {
      "click": function click($event) {
        _vm.clientType = 'acceptedData';

        _vm.setClientAmount(_vm.clientAmountObj.acceptedData);
      }
    }
  }, [_vm._v(" Accepted ")]), _c('div', {
    staticClass: "wd130 capsule-item",
    class: {
      'capsule-active': _vm.clientType == 'pendingData'
    },
    on: {
      "click": function click($event) {
        _vm.clientType = 'pendingData';

        _vm.setClientAmount(_vm.clientAmountObj.pendingData);
      }
    }
  }, [_vm._v(" Pending Modified ")]), _c('div', {
    staticClass: "wd70 capsule-item",
    class: {
      'capsule-active': _vm.clientType == 'potentialData'
    },
    on: {
      "click": function click($event) {
        _vm.clientType = 'potentialData';

        _vm.setClientAmount(_vm.clientAmountObj.potentialData);
      }
    }
  }, [_vm._v(" Potential ")]), _c('div', {
    staticClass: "wd60 capsule-item",
    class: {
      'capsule-active': _vm.clientType == 'lostData'
    },
    on: {
      "click": function click($event) {
        _vm.clientType = 'lostData';

        _vm.setClientAmount(_vm.clientAmountObj.lostData);
      }
    }
  }, [_vm._v(" Lost ")])]), _c('div', {
    staticClass: "mt20 hg300",
    attrs: {
      "id": "clientChunk"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }