var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-applyment-module"
  }, [_c('el-affix', {
    class: {
      'applyment-page-header-top': _vm.affixed,
      'applyment-page-header-shrink': _vm.sidebarFold,
      'applyment-page-header-unfold': !_vm.sidebarFold
    },
    attrs: {
      "offset": 104
    },
    on: {
      "change": _vm.handleAffixChange
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "applyment-page-header"
  }, [_c('div', [_c('div', {
    staticClass: "status"
  }, [_vm._v(" " + _vm._s(_vm.applymentStatusFormat(_vm.detail.applymentStatus)) + " "), _vm.detail.applymentStatus == 'REFUND_HANDING' ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v("(" + _vm._s(_vm.refundStatusFormat(_vm.detail.orderInfo.orderRefund.status)) + ")")]) : _vm._e(), _vm.detail.amends ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v("(Supplemental: " + _vm._s(_vm.amendsStatusFormat(_vm.detail.amends)) + ")")]) : _vm._e()]), _c('div', {
    staticClass: "page-header-heading-left"
  }, [_c('div', {
    staticClass: "heading-title"
  }, [_vm._v("Transaction No.: " + _vm._s(_vm.detail.applymentNo))])]), _c('div', {
    staticClass: "page-header-heading-content"
  }, [_c('el-descriptions', {
    attrs: {
      "column": 4,
      "direction": "vertical"
    }
  }, [_c('el-descriptions-item', {
    attrs: {
      "label": "Transaction No."
    }
  }, [_vm._v(_vm._s(_vm.detail.applymentNo))]), _c('el-descriptions-item', {
    attrs: {
      "label": "Reviewer"
    }
  }, [_c('user-toggle', {
    attrs: {
      "user": _vm.detail.auditor,
      "ownershipRole": "auditor"
    }
  })], 1), _c('el-descriptions-item', {
    attrs: {
      "label": "Coordinator"
    }
  }, [_c('user-toggle', {
    attrs: {
      "user": _vm.detail.coordinator,
      "ownershipRole": "coordinator"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "page-header-heading-right"
  }, [_c('div', {
    staticClass: "opts"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openOwnershipHistoryModal
    }
  }, [_vm._v("Ownership History")]), _c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openStatusHistoryModal
    }
  }, [_vm._v("Status Track")]), _vm.detail.applymentStatus === 'QUOTING' && _vm.isApplymentAuth('quotation:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleReviseFinish
    }
  }, [_vm._v(" Revision Submit ")]) : _vm._e(), _vm.detail.applymentStatus === 'QUOTATION_AUDITING' && _vm.isApplymentAuth('quotation:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleAuditFinish
    }
  }, [_vm._v(" Finish Review ")]) : _vm._e()], 1)])])])], 1), _c('div', {
    staticClass: "step mt50"
  }, [_c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('base-info', {
    ref: "detailDom",
    attrs: {
      "detail": _vm.detail
    }
  })], 1)], 1), _c('div', {
    staticClass: "step mt40",
    attrs: {
      "id": "Step2"
    }
  }, [_c('el-card', [_c('fee-letter', {
    ref: "feeLetterDom",
    attrs: {
      "detail": _vm.detail,
      "moduleServiceList": _vm.moduleServiceList,
      "feeLetterDetail": _vm.feeLetterDetail,
      "companyList": _vm.companyList
    },
    on: {
      "reviseFinishConfirm": _vm.reviseFinishConfirm
    }
  })], 1)], 1), _vm.stepModalVisible ? _c('step-modal', {
    ref: "stepModal"
  }) : _vm._e(), _c('ownership-history', {
    ref: "ownershipModal",
    attrs: {
      "applymentId": _vm.detail.id
    }
  }), _c('status-history', {
    ref: "statusHistoryModal",
    attrs: {
      "applymentId": _vm.detail.id
    }
  }), _c('audit-modal', {
    ref: "auditModal"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }