<template>
  <el-dialog title="Edit Quotation" :close-on-click-modal="false" :visible.sync="visible" width="1600px" append-to-body>
    <el-form :model="dataForm" ref="dataForm" label-width="0">
      <div class="fluid-width quotationWrap">
        <div class="mb20 shadeWrap" v-for="(parent, index) in dataForm.contentList" :key="`parent${index}`">
          <!-- Billing to -->
          <el-row :gutter="10" class="pt20 pb20 fxRow borderTop">
            <el-col :span="1" class="text-center">
              <span
                class="fs18 cursor-pointer blue el-icon-circle-plus-outline"
                @click="dataForm.contentList.splice(index + 1, 0, JSON.parse(JSON.stringify(contentObj)))"
              ></span>
              <span class="fs18 ml8 cursor-pointer red el-icon-remove-outline" @click="dataForm.contentList.splice(index, 1)" v-if="index != 0"></span>
            </el-col>
            <el-col :span="7" class="flex align-center">
              <span class="billingTo">Billing to:</span>
              <el-form-item
                :prop="`contentList.${index}.companyTitle`"
                :ref="`contentList.${index}.companyTitle`"
                :rules="{
                  required: true,
                  message: 'please enter',
                  trigger: 'submit',
                }"
                class="fluid-width"
              >
                <el-input v-model="parent.companyTitle" placeholder="Billing to" class="text-left"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7" class="flex align-center">
              <span class="billingTo">Address:</span>
              <el-form-item :prop="`contentList.${index}.companyAddress`" class="fluid-width">
                <el-input v-model="parent.companyAddress" placeholder="Address" class="text-left"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5" class="flex align-center">
              <span class="billingTo">Country:</span>
              <el-form-item :prop="`contentList.${index}.companyCountry`" class="fluid-width">
                <el-select v-model="parent.companyCountry" placeholder="Select" filterable class="fluid-width text-left">
                  <el-option v-for="item in countryList" :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="flex align-center">
              <span class="billingTo">Code:</span>
              <el-form-item :prop="`contentList.${index}.companyPostalCode`" class="fluid-width">
                <el-input v-model="parent.companyPostalCode" placeholder="Code" class="text-left"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <div class="billingTitle">
            <el-row :gutter="10" class="pt20 pb20 fxRow borderTop">
              <el-col :span="1"></el-col>
              <el-col :span="4">
                <p class="fs12 fwb text-center">Fee Schedule</p>
                <!-- <p class="fs12 fwb text-center">收费安排</p> -->
              </el-col>
              <el-col :span="4">
                <p class="fs12 fwb text-center">Covered Period</p>
                <!-- <p class="fs12 fwb text-center">涵盖的服务期间</p> -->
              </el-col>
              <el-col :span="2">
                <p class="fs12 fwb text-center">Amount</p>
                <!-- <p class="fs12 fwb text-center">金额</p> -->
              </el-col>
              <el-col :span="2">
                <p class="fs12 fwb wbbw text-center">Inclusive of third party fee</p>
                <!-- <p class="fs12 fwb text-center">第三方金额</p> -->
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Note For Third Party</p>
                <!-- <p class="fs12 fwb text-center">第三方费用备注</p> -->
              </el-col>
              <el-col :span="2">
                <p class="fs12 fwb wbbw text-center">Inclusive of other fee</p>
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Note For Other Fee</p>
              </el-col>
              <el-col :span="3">
                <p class="fs12 fwb text-center">Payment Due Date</p>
                <!-- <p class="fs12 fwb text-center">支付到期日</p> -->
              </el-col>
            </el-row>
          </div>

          <div v-for="(sub, idx) in parent.partList" :key="`sub${idx}`">
            <!-- 内容 -->
            <div class="billingContent" v-for="(grand, ids) in sub.itemList" :key="`grand${ids}`">
              <el-row :gutter="10" class="pt20 pb20 fxRow borderTop">
                <el-col :span="1" class="text-center">
                  <span
                    class="fs18 cursor-pointer blue el-icon-circle-plus-outline"
                    @click="sub.itemList.splice(ids + 1, 0, JSON.parse(JSON.stringify(itemObj)))"
                  ></span>
                  <span class="fs18 ml8 cursor-pointer red el-icon-remove-outline" @click="sub.itemList.splice(ids, 1)" v-if="ids != 0"></span>
                </el-col>
                <el-col :span="4">
                  <!-- <p class="fs12 text-center lh16">{{ grand.title }}</p> -->
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.title`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.title`"
                    :rules="{
                      required: true,
                      message: 'please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-autocomplete
                      v-model="grand.title"
                      :fetch-suggestions="querySearch"
                      placeholder="Title Of Charges"
                      class="fluid-width"
                    ></el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="4" class="flex align-center justify-center">
                  <!-- <p class="fs12 text-center lh16">{{ grand.startDate }} ~ {{ grand.endDate }}</p> -->
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.startDate`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.startDate`"
                    :rules="{
                      required: true,
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-date-picker
                      v-model="grand.startDate"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="Start Date"
                      class="wd110"
                    ></el-date-picker>
                  </el-form-item>
                  <span class="ml2 mr2">~</span>
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.endDate`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.endDate`"
                    :rules="{
                      required: true,
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-date-picker v-model="grand.endDate" type="date" value-format="yyyy-MM-dd" placeholder="End Date" class="wd110"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.amount`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.amount`"
                    :rules="{
                      required: true,
                      message: 'please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="grand.amount" v-minusPoint2 placeholder="0.00" class="amountInput">
                      <template slot="prefix">S$</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.thirdPartyFee`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.thirdPartyFee`"
                    :rules="{
                      required: true,
                      message: 'please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="grand.thirdPartyFee" v-minusPoint2 placeholder="0.00" class="amountInput">
                      <template slot="prefix">S$</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.noteForThirdParty`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.noteForThirdParty`"
                    :rules="{
                      required: true,
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-select
                      v-model="grand.noteForThirdParty"
                      @change="grand.noteForThirdPartyCn = thirdPartyFeeZhOptions[thirdPartyFeeEnOptions.indexOf(grand.noteForThirdParty)]"
                      class="fluid-width"
                    >
                      <el-option v-for="alone in thirdPartyFeeEnOptions" :key="alone" :label="alone" :value="alone"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="2">
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.otherFee`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.otherFee`"
                  >
                    <el-input v-model="grand.otherFee" v-minusPoint2 placeholder="0.00" class="amountInput">
                      <template slot="prefix">S$</template>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.itemList.${ids}.noteForOther`"
                    :ref="`contentList.${index}.partList.${idx}.itemList.${ids}.noteForOther`"
                  >
                    <el-select
                      v-model="grand.noteForOther"
                      @change="grand.noteForOtherCn = otherFeeZhOptions[otherFeeEnOptions.indexOf(grand.noteForOther)]"
                      clearable
                      class="fluid-width"
                    >
                      <el-option v-for="alone in otherFeeEnOptions" :key="alone" :label="alone" :value="alone"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row>
            </div>
            <!-- 小结 -->
            <div class="billingBrief">
              <el-row :gutter="10" class="pt20 pb20 fxRow borderTop borderBottom">
                <el-col :span="1" class="text-center">
                  <span
                    class="fs18 cursor-pointer blue el-icon-circle-plus-outline"
                    @click="parent.partList.splice(idx + 1, 0, JSON.parse(JSON.stringify(partObj)))"
                  ></span>
                  <span class="fs18 ml8 cursor-pointer red el-icon-remove-outline" @click="parent.partList.splice(idx, 1)" v-if="idx != 0"></span>
                </el-col>
                <el-col :span="4">
                  <!-- <p class="fs12 text-center lh16">{{ sub.subTotalDesp }}</p> -->
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.subTotalDesp`"
                    :ref="`contentList.${index}.partList.${idx}.subTotalDesp`"
                    :rules="{
                      required: true,
                      message: 'please enter',
                      trigger: 'submit',
                    }"
                  >
                    <el-input v-model="sub.subTotalDesp" placeholder="Text description of subtotals"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4"></el-col>
                <el-col :span="2">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">S${{ subtotalAmountSum(sub) }}</p>
                </el-col>
                <el-col :span="2">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">S${{ subTotalThirdPartyFeeSum(sub) }}</p>
                </el-col>
                <el-col :span="3"></el-col>
                <el-col :span="2">
                  <p class="fs12 text-center lh16 pb2">Subtotal</p>
                  <p class="fs12 text-center lh16 orange newline">S${{ subTotalOtherFeeSum(sub) }}</p>
                </el-col>
                <el-col :span="3" class="fs12 text-center">
                  <!-- 是否首期付款 -->
                  <p>Is it a down payment?</p>
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.isDownPayment`"
                    :ref="`contentList.${index}.partList.${idx}.isDownPayment`"
                    :rules="{
                      required: true,
                      message: 'please select',
                      trigger: 'submit',
                    }"
                  >
                    <el-radio-group v-model="sub.isDownPayment" @change="sub.paymentDueTime = null">
                      <el-radio :label="1">Yes</el-radio>
                      <el-radio :label="0">No</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <!-- 订单提交时即时支付 -->
                  <p class="fs12 text-center lh16" v-if="sub.isDownPayment == 1">Payable upon submission</p>
                  <el-form-item
                    :prop="`contentList.${index}.partList.${idx}.paymentDueTime`"
                    :ref="`contentList.${index}.partList.${idx}.paymentDueTime`"
                    :rules="{
                      required: true,
                      message: 'please select',
                      trigger: 'submit',
                    }"
                    v-if="sub.isDownPayment == 0"
                  >
                    <el-date-picker
                      v-model="sub.paymentDueTime"
                      type="date"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      placeholder="Payment due date"
                      class="fluid-width"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- 空格 -->
              <el-row :gutter="10" class="pt25 pb25 fxRow"></el-row>
            </div>
          </div>

          <!-- 总结 -->
          <div class="billingBottom">
            <el-row :gutter="10" class="pt20 pb20 fxRow borderTop borderBottom">
              <el-col :span="1"></el-col>
              <el-col :span="4">
                <!-- <p class="fs12 text-center lh16">{{ parent.sumTotalDesp }}</p> -->
                <el-form-item
                  :prop="`contentList.${index}.sumTotalDesp`"
                  :ref="`contentList.${index}.sumTotalDesp`"
                  :rules="{
                    required: true,
                    message: 'please enter',
                    trigger: 'submit',
                  }"
                >
                  <el-input v-model="parent.sumTotalDesp" placeholder="The literal description of the total"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4"></el-col>
              <el-col :span="2">
                <p class="fs12 text-center lh16 pb2">Total</p>
                <p class="fs12 text-center lh16 orange newline">S${{ sumTotalPriceSum(parent) }}</p>
              </el-col>
              <el-col :span="2">
                <p class="fs12 text-center lh16 pb2">Total</p>
                <p class="fs12 text-center lh16 orange newline">S${{ sumTotalThirdPartyFeeSum(parent) }}</p>
              </el-col>
              <el-col :span="3"></el-col>
              <el-col :span="2">
                <p class="fs12 text-center lh16 pb2">Total</p>
                <p class="fs12 text-center lh16 orange newline">S${{ sumTotalOtherFeeSum(parent) }}</p>
              </el-col>
              <el-col :span="3"></el-col>
              <el-col :span="3"></el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="dataFormSubmit()">OK</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { validateLocation, accountAdd, toFixedDecimal } from "@/utils";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {
          id: null,
          applymentStatus: "",
          quotationList: [],
        };
      },
    },
    moduleServiceList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      visible: false,
      type: null,
      serialIndex: null,
      roleList: [],
      dataForm: {
        applymentId: null,
        contentList: [
          {
            companyTitle: null,
            companyAddress: null,
            companyCountry: null,
            companyPostalCode: null,
            sumTotalDesp: null,
            sumTotalPrice: null,
            sumTotalThirdPartyFee: null,
            sumTotalOtherFee: null,
            partList: [
              {
                isDownPayment: null,
                subTotalDesp: null,
                subTotalPrice: null,
                subTotalThirdPartyFee: null,
                subTotalOtherFee: null,
                paymentDueTime: null,
                itemList: [
                  {
                    title: null,
                    startDate: null,
                    endDate: null,
                    amount: null,
                    thirdPartyFee: null,
                    noteForThirdParty: null,
                    noteForThirdPartyCn: null,

                    otherFee: null,
                    noteForOther: null,
                    noteForOtherCn: null,
                  },
                ],
              },
            ],
          },
        ],
      },
      submitSwitch: true,
      contentObj: {},
      partObj: {},
      itemObj: {},
    };
  },
  created() {
    const dataFormCopy = JSON.parse(JSON.stringify(this.dataForm));
    this.contentObj = dataFormCopy.contentList[0];
    this.partObj = this.contentObj.partList[0];
    this.itemObj = this.partObj.itemList[0];
  },
  mounted() {
    this.initialDataForm = JSON.parse(JSON.stringify(this.dataForm));
  },
  computed: {
    ...mapState("constant", ["countryList", "thirdPartyFeeEnOptions", "thirdPartyFeeZhOptions", "otherFeeEnOptions", "otherFeeZhOptions"]),
  },
  methods: {
    ...mapActions("applyment", ["createQuotation", "updateQuotation", "initApplyment"]),
    // 合计总额
    sumTotalPriceSum(data) {
      let partSum = 0;
      for (let i in data.partList) {
        partSum = accountAdd(partSum, parseFloat(data.partList[i].subTotalPrice || 0));
      }
      data.sumTotalPrice = partSum;
      return data.sumTotalPrice;
    },
    // 第三方金额合计数
    sumTotalThirdPartyFeeSum(data) {
      let tpfSum = 0;
      for (let i in data.partList) {
        tpfSum = accountAdd(tpfSum, parseFloat(data.partList[i].subTotalThirdPartyFee || 0));
      }
      data.sumTotalThirdPartyFee = tpfSum;
      return data.sumTotalThirdPartyFee;
    },
    // 其他费用金额合计数
    sumTotalOtherFeeSum(data) {
      let tpfSum = 0;
      for (let i in data.partList) {
        tpfSum = accountAdd(tpfSum, parseFloat(data.partList[i].subTotalOtherFee || 0));
      }
      data.sumTotalOtherFee = tpfSum;
      return data.sumTotalOtherFee;
    },
    // 小计总额 - sum
    subtotalAmountSum(item) {
      let subAmoCount = 0;
      for (let i in item.itemList) {
        subAmoCount = accountAdd(subAmoCount, parseFloat(item.itemList[i].amount || 0));
      }
      item.subTotalPrice = toFixedDecimal(subAmoCount);
      return item.subTotalPrice;
    },
    // 第三方金额 - sum
    subTotalThirdPartyFeeSum(item) {
      let subTpfCount = 0;
      for (let i in item.itemList) {
        subTpfCount = accountAdd(subTpfCount, parseFloat(item.itemList[i].thirdPartyFee || 0));
      }
      item.subTotalThirdPartyFee = toFixedDecimal(subTpfCount);
      return item.subTotalThirdPartyFee;
    },
    // 其他费用 - 小计
    subTotalOtherFeeSum(item) {
      let subTpfCount = 0;
      for (let i in item.itemList) {
        subTpfCount = accountAdd(subTpfCount, parseFloat(item.itemList[i].otherFee || 0));
      }
      item.subTotalOtherFee = toFixedDecimal(subTpfCount);
      return item.subTotalOtherFee;
    },
    querySearch(queryString, cb) {
      const moduleServiceList = this.moduleServiceList;
      let results = queryString ? moduleServiceList.filter(this.createFilter(queryString)) : moduleServiceList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.indexOf(queryString) === 0;
      };
    },
    async init() {
      this.visible = true;
      this.submitSwitch = true;
      await this.$nextTick();
      this.$refs["dataForm"].resetFields();
      const detailCopy = JSON.parse(JSON.stringify(this.detail));
      const contentObjCopy = JSON.parse(JSON.stringify(this.contentObj));
      this.dataForm = { applymentId: detailCopy.id, contentList: detailCopy.quotationList || [contentObjCopy] };
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async (valid, object) => {
        if (valid) {
          if (!this.detail.quotationList || this.detail.quotationList.length == 0) {
            // 创建
            if (this.submitSwitch) {
              this.submitSwitch = false;
              try {
                await this.createQuotation(this.dataForm);
                await this.initApplyment(this.dataForm.applymentId);
                this.$message({
                  message: "success",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.submitSwitch = true;
                  },
                });
              } catch (msg) {
                this.$message.error(msg);
                this.submitSwitch = true;
              }
            }
          } else {
            // 更新
            if (this.submitSwitch) {
              this.submitSwitch = false;
              try {
                await this.updateQuotation(this.dataForm);
                await this.initApplyment(this.dataForm.applymentId);
                this.$message({
                  message: "success",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.submitSwitch = true;
                  },
                });
              } catch (msg) {
                this.$message.error(msg);
                this.submitSwitch = true;
              }
            }
          }
        } else {
          validateLocation(object, this);
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variables.scss";

.billingTo {
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
}

.quotationWrap {
  ::v-deep .el-form-item {
    margin-bottom: 0px;
  }

  ::v-deep .el-form-item__content {
    line-height: 30px;
  }

  ::v-deep .el-form-item__error {
    padding-top: 1px !important;
    left: 50%;
    margin-left: -40px;
  }

  ::v-deep .el-input__inner {
    font-size: 12px;
    padding-left: 10px;
    padding-right: 20px;
    border: none;
    height: 30px;
    line-height: 30px;
    background: #fff5f0;
    text-align: center;
  }

  .amountInput {
    ::v-deep .el-input__inner {
      padding-right: 10px;
    }
  }

  .text-left {
    ::v-deep .el-input__inner {
      text-align: left;
    }
  }

  ::v-deep .el-input__prefix {
    display: none;
  }

  ::v-deep .el-input__suffix {
    right: 0px;
  }

  ::v-deep .el-input__icon {
    line-height: 30px;
  }

  ::v-deep .el-radio__label {
    font-size: 12px;
  }
}

.blackD8 {
  color: $navbar--color-dark;
}

.orange {
  color: $--color-warning;
  font-size: 20px;
  font-weight: bolder;
}

.blue {
  color: $--color-primary;
}

.red {
  color: $--color-danger;
}

p {
  line-height: 16px;
  margin: 0;
  color: #011a45;
}

.shadeWrap {
  width: 100%;
  padding: 0 5px;
  border-left: 1px solid #e4e7ed;
  border-right: 1px solid #e4e7ed;
  background-color: #fff5f0;
  margin-top: 6px;
}

.comboLeft {
  padding-right: 60px;
}

.comboRight {
  padding-left: 60px;
  border-left: 1px solid #e4e7ed;
}

.messageTitle {
  border-radius: 2px;
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
}

.mustTitle {
  border-radius: 2px;
  font-size: 16px;
  line-height: 1em;
  font-weight: 600;
}

.mustTitle:before {
  content: "*";
  margin-right: 4px;
  color: #f56c6c;
}

.singleTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-bottom: 10px;
}

.singleText {
  font-size: 12px;
  line-height: 22px;
  margin-right: 30px;
  word-wrap: break-word;
  word-break: break-all;
}

.borderTop {
  border-top: 1px solid #e4e7ed;
}

.borderBottom {
  border-bottom: 1px solid #e4e7ed;
}
</style>
