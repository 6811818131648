var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Edit Quotation",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "1600px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-width": "0"
    }
  }, [_c('div', {
    staticClass: "fluid-width quotationWrap"
  }, _vm._l(_vm.dataForm.contentList, function (parent, index) {
    return _c('div', {
      key: "parent".concat(index),
      staticClass: "mb20 shadeWrap"
    }, [_c('el-row', {
      staticClass: "pt20 pb20 fxRow borderTop",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      staticClass: "text-center",
      attrs: {
        "span": 1
      }
    }, [_c('span', {
      staticClass: "fs18 cursor-pointer blue el-icon-circle-plus-outline",
      on: {
        "click": function click($event) {
          _vm.dataForm.contentList.splice(index + 1, 0, JSON.parse(JSON.stringify(_vm.contentObj)));
        }
      }
    }), index != 0 ? _c('span', {
      staticClass: "fs18 ml8 cursor-pointer red el-icon-remove-outline",
      on: {
        "click": function click($event) {
          return _vm.dataForm.contentList.splice(index, 1);
        }
      }
    }) : _vm._e()]), _c('el-col', {
      staticClass: "flex align-center",
      attrs: {
        "span": 7
      }
    }, [_c('span', {
      staticClass: "billingTo"
    }, [_vm._v("Billing to:")]), _c('el-form-item', {
      ref: "contentList.".concat(index, ".companyTitle"),
      refInFor: true,
      staticClass: "fluid-width",
      attrs: {
        "prop": "contentList.".concat(index, ".companyTitle"),
        "rules": {
          required: true,
          message: 'please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      staticClass: "text-left",
      attrs: {
        "placeholder": "Billing to"
      },
      model: {
        value: parent.companyTitle,
        callback: function callback($$v) {
          _vm.$set(parent, "companyTitle", $$v);
        },
        expression: "parent.companyTitle"
      }
    })], 1)], 1), _c('el-col', {
      staticClass: "flex align-center",
      attrs: {
        "span": 7
      }
    }, [_c('span', {
      staticClass: "billingTo"
    }, [_vm._v("Address:")]), _c('el-form-item', {
      staticClass: "fluid-width",
      attrs: {
        "prop": "contentList.".concat(index, ".companyAddress")
      }
    }, [_c('el-input', {
      staticClass: "text-left",
      attrs: {
        "placeholder": "Address"
      },
      model: {
        value: parent.companyAddress,
        callback: function callback($$v) {
          _vm.$set(parent, "companyAddress", $$v);
        },
        expression: "parent.companyAddress"
      }
    })], 1)], 1), _c('el-col', {
      staticClass: "flex align-center",
      attrs: {
        "span": 5
      }
    }, [_c('span', {
      staticClass: "billingTo"
    }, [_vm._v("Country:")]), _c('el-form-item', {
      staticClass: "fluid-width",
      attrs: {
        "prop": "contentList.".concat(index, ".companyCountry")
      }
    }, [_c('el-select', {
      staticClass: "fluid-width text-left",
      attrs: {
        "placeholder": "Select",
        "filterable": ""
      },
      model: {
        value: parent.companyCountry,
        callback: function callback($$v) {
          _vm.$set(parent, "companyCountry", $$v);
        },
        expression: "parent.companyCountry"
      }
    }, _vm._l(_vm.countryList, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      });
    }), 1)], 1)], 1), _c('el-col', {
      staticClass: "flex align-center",
      attrs: {
        "span": 4
      }
    }, [_c('span', {
      staticClass: "billingTo"
    }, [_vm._v("Code:")]), _c('el-form-item', {
      staticClass: "fluid-width",
      attrs: {
        "prop": "contentList.".concat(index, ".companyPostalCode")
      }
    }, [_c('el-input', {
      staticClass: "text-left",
      attrs: {
        "placeholder": "Code"
      },
      model: {
        value: parent.companyPostalCode,
        callback: function callback($$v) {
          _vm.$set(parent, "companyPostalCode", $$v);
        },
        expression: "parent.companyPostalCode"
      }
    })], 1)], 1)], 1), _c('div', {
      staticClass: "billingTitle"
    }, [_c('el-row', {
      staticClass: "pt20 pb20 fxRow borderTop",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "span": 1
      }
    }), _c('el-col', {
      attrs: {
        "span": 4
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Fee Schedule")])]), _c('el-col', {
      attrs: {
        "span": 4
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Covered Period")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Amount")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 fwb wbbw text-center"
    }, [_vm._v("Inclusive of third party fee")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Note For Third Party")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 fwb wbbw text-center"
    }, [_vm._v("Inclusive of other fee")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Note For Other Fee")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Payment Due Date")])])], 1)], 1), _vm._l(parent.partList, function (sub, idx) {
      return _c('div', {
        key: "sub".concat(idx)
      }, [_vm._l(sub.itemList, function (grand, ids) {
        return _c('div', {
          key: "grand".concat(ids),
          staticClass: "billingContent"
        }, [_c('el-row', {
          staticClass: "pt20 pb20 fxRow borderTop",
          attrs: {
            "gutter": 10
          }
        }, [_c('el-col', {
          staticClass: "text-center",
          attrs: {
            "span": 1
          }
        }, [_c('span', {
          staticClass: "fs18 cursor-pointer blue el-icon-circle-plus-outline",
          on: {
            "click": function click($event) {
              sub.itemList.splice(ids + 1, 0, JSON.parse(JSON.stringify(_vm.itemObj)));
            }
          }
        }), ids != 0 ? _c('span', {
          staticClass: "fs18 ml8 cursor-pointer red el-icon-remove-outline",
          on: {
            "click": function click($event) {
              return sub.itemList.splice(ids, 1);
            }
          }
        }) : _vm._e()]), _c('el-col', {
          attrs: {
            "span": 4
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".title"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".title"),
            "rules": {
              required: true,
              message: 'please enter',
              trigger: 'submit'
            }
          }
        }, [_c('el-autocomplete', {
          staticClass: "fluid-width",
          attrs: {
            "fetch-suggestions": _vm.querySearch,
            "placeholder": "Title Of Charges"
          },
          model: {
            value: grand.title,
            callback: function callback($$v) {
              _vm.$set(grand, "title", $$v);
            },
            expression: "grand.title"
          }
        })], 1)], 1), _c('el-col', {
          staticClass: "flex align-center justify-center",
          attrs: {
            "span": 4
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".startDate"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".startDate"),
            "rules": {
              required: true,
              message: 'please select',
              trigger: 'submit'
            }
          }
        }, [_c('el-date-picker', {
          staticClass: "wd110",
          attrs: {
            "type": "date",
            "value-format": "yyyy-MM-dd",
            "placeholder": "Start Date"
          },
          model: {
            value: grand.startDate,
            callback: function callback($$v) {
              _vm.$set(grand, "startDate", $$v);
            },
            expression: "grand.startDate"
          }
        })], 1), _c('span', {
          staticClass: "ml2 mr2"
        }, [_vm._v("~")]), _c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".endDate"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".endDate"),
            "rules": {
              required: true,
              message: 'please select',
              trigger: 'submit'
            }
          }
        }, [_c('el-date-picker', {
          staticClass: "wd110",
          attrs: {
            "type": "date",
            "value-format": "yyyy-MM-dd",
            "placeholder": "End Date"
          },
          model: {
            value: grand.endDate,
            callback: function callback($$v) {
              _vm.$set(grand, "endDate", $$v);
            },
            expression: "grand.endDate"
          }
        })], 1)], 1), _c('el-col', {
          attrs: {
            "span": 2
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".amount"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".amount"),
            "rules": {
              required: true,
              message: 'please enter',
              trigger: 'submit'
            }
          }
        }, [_c('el-input', {
          directives: [{
            name: "minusPoint2",
            rawName: "v-minusPoint2"
          }],
          staticClass: "amountInput",
          attrs: {
            "placeholder": "0.00"
          },
          model: {
            value: grand.amount,
            callback: function callback($$v) {
              _vm.$set(grand, "amount", $$v);
            },
            expression: "grand.amount"
          }
        }, [_c('template', {
          slot: "prefix"
        }, [_vm._v("S$")])], 2)], 1)], 1), _c('el-col', {
          attrs: {
            "span": 2
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".thirdPartyFee"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".thirdPartyFee"),
            "rules": {
              required: true,
              message: 'please enter',
              trigger: 'submit'
            }
          }
        }, [_c('el-input', {
          directives: [{
            name: "minusPoint2",
            rawName: "v-minusPoint2"
          }],
          staticClass: "amountInput",
          attrs: {
            "placeholder": "0.00"
          },
          model: {
            value: grand.thirdPartyFee,
            callback: function callback($$v) {
              _vm.$set(grand, "thirdPartyFee", $$v);
            },
            expression: "grand.thirdPartyFee"
          }
        }, [_c('template', {
          slot: "prefix"
        }, [_vm._v("S$")])], 2)], 1)], 1), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".noteForThirdParty"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".noteForThirdParty"),
            "rules": {
              required: true,
              message: 'please select',
              trigger: 'submit'
            }
          }
        }, [_c('el-select', {
          staticClass: "fluid-width",
          on: {
            "change": function change($event) {
              grand.noteForThirdPartyCn = _vm.thirdPartyFeeZhOptions[_vm.thirdPartyFeeEnOptions.indexOf(grand.noteForThirdParty)];
            }
          },
          model: {
            value: grand.noteForThirdParty,
            callback: function callback($$v) {
              _vm.$set(grand, "noteForThirdParty", $$v);
            },
            expression: "grand.noteForThirdParty"
          }
        }, _vm._l(_vm.thirdPartyFeeEnOptions, function (alone) {
          return _c('el-option', {
            key: alone,
            attrs: {
              "label": alone,
              "value": alone
            }
          });
        }), 1)], 1)], 1), _c('el-col', {
          attrs: {
            "span": 2
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".otherFee"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".otherFee")
          }
        }, [_c('el-input', {
          directives: [{
            name: "minusPoint2",
            rawName: "v-minusPoint2"
          }],
          staticClass: "amountInput",
          attrs: {
            "placeholder": "0.00"
          },
          model: {
            value: grand.otherFee,
            callback: function callback($$v) {
              _vm.$set(grand, "otherFee", $$v);
            },
            expression: "grand.otherFee"
          }
        }, [_c('template', {
          slot: "prefix"
        }, [_vm._v("S$")])], 2)], 1)], 1), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('el-form-item', {
          ref: "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".noteForOther"),
          refInFor: true,
          attrs: {
            "prop": "contentList.".concat(index, ".partList.").concat(idx, ".itemList.").concat(ids, ".noteForOther")
          }
        }, [_c('el-select', {
          staticClass: "fluid-width",
          attrs: {
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              grand.noteForOtherCn = _vm.otherFeeZhOptions[_vm.otherFeeEnOptions.indexOf(grand.noteForOther)];
            }
          },
          model: {
            value: grand.noteForOther,
            callback: function callback($$v) {
              _vm.$set(grand, "noteForOther", $$v);
            },
            expression: "grand.noteForOther"
          }
        }, _vm._l(_vm.otherFeeEnOptions, function (alone) {
          return _c('el-option', {
            key: alone,
            attrs: {
              "label": alone,
              "value": alone
            }
          });
        }), 1)], 1)], 1), _c('el-col', {
          attrs: {
            "span": 3
          }
        })], 1)], 1);
      }), _c('div', {
        staticClass: "billingBrief"
      }, [_c('el-row', {
        staticClass: "pt20 pb20 fxRow borderTop borderBottom",
        attrs: {
          "gutter": 10
        }
      }, [_c('el-col', {
        staticClass: "text-center",
        attrs: {
          "span": 1
        }
      }, [_c('span', {
        staticClass: "fs18 cursor-pointer blue el-icon-circle-plus-outline",
        on: {
          "click": function click($event) {
            parent.partList.splice(idx + 1, 0, JSON.parse(JSON.stringify(_vm.partObj)));
          }
        }
      }), idx != 0 ? _c('span', {
        staticClass: "fs18 ml8 cursor-pointer red el-icon-remove-outline",
        on: {
          "click": function click($event) {
            return parent.partList.splice(idx, 1);
          }
        }
      }) : _vm._e()]), _c('el-col', {
        attrs: {
          "span": 4
        }
      }, [_c('el-form-item', {
        ref: "contentList.".concat(index, ".partList.").concat(idx, ".subTotalDesp"),
        refInFor: true,
        attrs: {
          "prop": "contentList.".concat(index, ".partList.").concat(idx, ".subTotalDesp"),
          "rules": {
            required: true,
            message: 'please enter',
            trigger: 'submit'
          }
        }
      }, [_c('el-input', {
        attrs: {
          "placeholder": "Text description of subtotals"
        },
        model: {
          value: sub.subTotalDesp,
          callback: function callback($$v) {
            _vm.$set(sub, "subTotalDesp", $$v);
          },
          expression: "sub.subTotalDesp"
        }
      })], 1)], 1), _c('el-col', {
        attrs: {
          "span": 4
        }
      }), _c('el-col', {
        attrs: {
          "span": 2
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v("S$" + _vm._s(_vm.subtotalAmountSum(sub)))])]), _c('el-col', {
        attrs: {
          "span": 2
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v("S$" + _vm._s(_vm.subTotalThirdPartyFeeSum(sub)))])]), _c('el-col', {
        attrs: {
          "span": 3
        }
      }), _c('el-col', {
        attrs: {
          "span": 2
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v("S$" + _vm._s(_vm.subTotalOtherFeeSum(sub)))])]), _c('el-col', {
        staticClass: "fs12 text-center",
        attrs: {
          "span": 3
        }
      }, [_c('p', [_vm._v("Is it a down payment?")]), _c('el-form-item', {
        ref: "contentList.".concat(index, ".partList.").concat(idx, ".isDownPayment"),
        refInFor: true,
        attrs: {
          "prop": "contentList.".concat(index, ".partList.").concat(idx, ".isDownPayment"),
          "rules": {
            required: true,
            message: 'please select',
            trigger: 'submit'
          }
        }
      }, [_c('el-radio-group', {
        on: {
          "change": function change($event) {
            sub.paymentDueTime = null;
          }
        },
        model: {
          value: sub.isDownPayment,
          callback: function callback($$v) {
            _vm.$set(sub, "isDownPayment", $$v);
          },
          expression: "sub.isDownPayment"
        }
      }, [_c('el-radio', {
        attrs: {
          "label": 1
        }
      }, [_vm._v("Yes")]), _c('el-radio', {
        attrs: {
          "label": 0
        }
      }, [_vm._v("No")])], 1)], 1)], 1), _c('el-col', {
        attrs: {
          "span": 3
        }
      }, [sub.isDownPayment == 1 ? _c('p', {
        staticClass: "fs12 text-center lh16"
      }, [_vm._v("Payable upon submission")]) : _vm._e(), sub.isDownPayment == 0 ? _c('el-form-item', {
        ref: "contentList.".concat(index, ".partList.").concat(idx, ".paymentDueTime"),
        refInFor: true,
        attrs: {
          "prop": "contentList.".concat(index, ".partList.").concat(idx, ".paymentDueTime"),
          "rules": {
            required: true,
            message: 'please select',
            trigger: 'submit'
          }
        }
      }, [_c('el-date-picker', {
        staticClass: "fluid-width",
        attrs: {
          "type": "date",
          "value-format": "yyyy-MM-dd HH:mm:ss",
          "placeholder": "Payment due date"
        },
        model: {
          value: sub.paymentDueTime,
          callback: function callback($$v) {
            _vm.$set(sub, "paymentDueTime", $$v);
          },
          expression: "sub.paymentDueTime"
        }
      })], 1) : _vm._e()], 1)], 1), _c('el-row', {
        staticClass: "pt25 pb25 fxRow",
        attrs: {
          "gutter": 10
        }
      })], 1)], 2);
    }), _c('div', {
      staticClass: "billingBottom"
    }, [_c('el-row', {
      staticClass: "pt20 pb20 fxRow borderTop borderBottom",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "span": 1
      }
    }), _c('el-col', {
      attrs: {
        "span": 4
      }
    }, [_c('el-form-item', {
      ref: "contentList.".concat(index, ".sumTotalDesp"),
      refInFor: true,
      attrs: {
        "prop": "contentList.".concat(index, ".sumTotalDesp"),
        "rules": {
          required: true,
          message: 'please enter',
          trigger: 'submit'
        }
      }
    }, [_c('el-input', {
      attrs: {
        "placeholder": "The literal description of the total"
      },
      model: {
        value: parent.sumTotalDesp,
        callback: function callback($$v) {
          _vm.$set(parent, "sumTotalDesp", $$v);
        },
        expression: "parent.sumTotalDesp"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 4
      }
    }), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v("S$" + _vm._s(_vm.sumTotalPriceSum(parent)))])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v("S$" + _vm._s(_vm.sumTotalThirdPartyFeeSum(parent)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v("S$" + _vm._s(_vm.sumTotalOtherFeeSum(parent)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }), _c('el-col', {
      attrs: {
        "span": 3
      }
    })], 1)], 1)], 2);
  }), 0)]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }