var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.goodsListType ? _c('div', {
    staticClass: "plan-detail-module"
  }, [_c('div', {
    staticClass: "plan-base-wrapper"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.detail.planName))]), _c('div', {
    staticClass: "price"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("thousands")(_vm.detail.price)))]), _vm.detail.price != _vm.detail.originalPrice ? _c('span', {
    staticClass: "ml6 tdlt"
  }, [_vm._v("(" + _vm._s(_vm._f("thousands")(_vm.detail.originalPrice)) + ")")]) : _vm._e()]), _c('div', {
    staticClass: "pt10 apply"
  }, [_c('p', {
    staticClass: "fs12 for-people"
  }, [_vm._v("Exclusive for:")]), _c('p', {
    staticClass: "fs12 summary"
  }, [_vm._v(_vm._s(_vm.detail.summary))])])]), _c('div', {
    staticClass: "pl20 mr30 plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Services Include:")]), _c('div', {
    staticClass: "detail"
  }, [_c('ul', _vm._l(_vm.detail.modules, function (module) {
    return _c('li', {
      key: module.id
    }, [_c('span', {
      staticClass: "el-icon-check item-icon"
    }), _c('span', {
      staticClass: "item-name"
    }, [_vm._v(_vm._s(module.name))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(module.price)) + ")")]), module.summary ? _c('el-popover', {
      attrs: {
        "placement": "bottom",
        "trigger": "hover"
      }
    }, [_c('span', {
      staticClass: "fs12"
    }, [_vm._v(_vm._s(module.summary))]), _c('span', {
      staticClass: "fs13 question-icon el-icon-question",
      attrs: {
        "slot": "reference"
      },
      slot: "reference"
    })]) : _vm._e()], 1);
  }), 0)])]), _c('div', {
    staticClass: "mr30 plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "pt40 detail"
  }, [_c('ul', [_vm._m(0), _vm._l(_vm.detail.thirdpartys, function (module) {
    return _c('li', {
      key: module.id
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v("- " + _vm._s(module.noteForThirdParty))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(module.thirdPartyFee)) + ")")])]);
  })], 2)])]), _c('div', {
    staticClass: "plan-detail-wrapper"
  }, [_c('div', {
    staticClass: "pt40 detail"
  }, [_c('ul', [_vm._m(1), _vm._l(_vm.detail.otherFees, function (module) {
    return _c('li', {
      key: module.id
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v("- " + _vm._s(module.noteForOther))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v("(" + _vm._s(_vm._f("thousands")(module.otherFee)) + ")")])]);
  })], 2)])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('span', {
    staticClass: "el-icon-check item-icon"
  }), _c('span', {
    staticClass: "item-name"
  }, [_vm._v("Third party fee:")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('span', {
    staticClass: "el-icon-check item-icon"
  }), _c('span', {
    staticClass: "item-name"
  }, [_vm._v("Other Fee:")])]);
}]

export { render, staticRenderFns }