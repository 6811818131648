var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "assets-image-picker"
  }, [_c('div', {
    staticClass: "image-preview",
    on: {
      "click": function click($event) {
        return _vm.openUpload();
      }
    }
  }, [_vm.value && _vm.value.url ? _c('div', {
    staticClass: "left mr20"
  }, [_c('el-image', {
    staticClass: "preview-image",
    style: {
      width: _vm.width,
      height: _vm.height
    },
    attrs: {
      "src": _vm.value.url,
      "alt": _vm.value.name,
      "fit": "fill"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "right"
  }, [_vm._t("default", function () {
    return [_c('el-button', [_vm._v("Upload")])];
  })], 2)]), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    }
  }), _vm.uploadVisible ? _c('upload', {
    ref: "Upload",
    on: {
      "uploadFinish": _vm.handleFinish
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }