var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": !_vm.dataForm.id ? 'Add Proposed Funds' : 'Edit Proposed Funds',
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "600px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "176px"
    }
  }, [_c('el-form-item', {
    ref: "fundName",
    attrs: {
      "label": "Proposed Fund Name",
      "prop": "fundName"
    }
  }, [_c('el-input', {
    staticClass: "full-width",
    attrs: {
      "placeholder": "Please enter"
    },
    model: {
      value: _vm.dataForm.fundName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fundName", $$v);
      },
      expression: "dataForm.fundName"
    }
  })], 1), _c('el-form-item', {
    ref: "fundType",
    attrs: {
      "label": "Type",
      "prop": "fundType"
    }
  }, [_c('el-select', {
    staticClass: "wd360",
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.fundType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fundType", $$v);
      },
      expression: "dataForm.fundType"
    }
  }, _vm._l(_vm.fundTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    ref: "fundRegistrationCountry",
    attrs: {
      "label": "Registration Country",
      "prop": "fundRegistrationCountry"
    }
  }, [_c('el-select', {
    staticClass: "wd360",
    attrs: {
      "placeholder": "Please select",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.fundRegistrationCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fundRegistrationCountry", $$v);
      },
      expression: "dataForm.fundRegistrationCountry"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    ref: "fundRegistration",
    attrs: {
      "label": "Registration address",
      "prop": "fundRegistration"
    }
  }, [_c('el-input', {
    staticClass: "full-width",
    attrs: {
      "placeholder": "Please enter"
    },
    model: {
      value: _vm.dataForm.fundRegistration,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fundRegistration", $$v);
      },
      expression: "dataForm.fundRegistration"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }