var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "700px",
      "title": "Finish Review",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "130px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.statusChange
    },
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, _vm._l(_vm.clientCodeStatusList, function (item) {
    return _c('span', {
      key: "review".concat(item.title)
    }, [item.value == 1 || item.value == 2 ? _c('el-radio', {
      staticClass: "mr40",
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]) : _vm._e()], 1);
  }), 0)], 1), _vm.dataForm.status == 1 ? _c('el-form-item', {
    attrs: {
      "label": "Introduced By",
      "prop": "introducedBy"
    }
  }, [_c('el-radio-group', {
    on: {
      "change": _vm.introducedByChange
    },
    model: {
      value: _vm.dataForm.introducedBy,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introducedBy", $$v);
      },
      expression: "dataForm.introducedBy"
    }
  }, _vm._l(_vm.introducedByList, function (item) {
    return _c('el-radio', {
      key: "introducedBy".concat(item.title),
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1)], 1) : _vm._e(), _vm.dataForm.status == 1 && _vm.dataForm.introducedBy == 1 ? _c('el-form-item', {
    attrs: {
      "label": "commission",
      "prop": "commissionList"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-key": "id",
      "filterable": "",
      "clearable": "",
      "multiple": "",
      "placeholder": "Please Select"
    },
    model: {
      value: _vm.dataForm.commissionList,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "commissionList", $$v);
      },
      expression: "dataForm.commissionList"
    }
  }, _vm._l(_vm.commissionRequiredList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": "".concat(item.name, " - ").concat(_vm.multiply(item.commissionRate)),
        "value": item
      }
    });
  }), 1)], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "auditRemark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark"
    },
    model: {
      value: _vm.dataForm.auditRemark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "auditRemark", $$v);
      },
      expression: "dataForm.auditRemark"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.isAuth('sys:client:audit') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }