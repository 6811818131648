var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Reopen",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "138px"
    }
  }, [_vm.engagementDetail.reopenStatus == 2 && _vm.engagementDetail.reopenAuditRemark ? _c('el-form-item', {
    attrs: {
      "label": "Audit Remark"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.engagementDetail.reopenAuditRemark))])]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Reopen Reason",
      "prop": "reopenReason"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Reopen Reason",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.reopenReason,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "reopenReason", $$v);
      },
      expression: "dataForm.reopenReason"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }