var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "900px",
      "title": !_vm.dataForm.id ? 'Add Nominee Director' : 'Edit Nominee Director',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "143px"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name",
      "prop": "fullName"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fullName", $$v);
      },
      expression: "dataForm.fullName"
    }
  })], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 13,
      "span": 9
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Nationality",
      "prop": "nationality"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Nationality"
    },
    model: {
      value: _vm.dataForm.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "nationality", $$v);
      },
      expression: "dataForm.nationality"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 13,
      "span": 9
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "IdentificationType",
      "prop": "identificationType"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select identificationType"
    },
    model: {
      value: _vm.dataForm.identificationType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "identificationType", $$v);
      },
      expression: "dataForm.identificationType"
    }
  }, _vm._l(_vm.identificationTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "ID Number",
      "prop": "identificationNo"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "identificationNo",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.identificationNo,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "identificationNo", $$v);
      },
      expression: "dataForm.identificationNo"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Address",
      "prop": "residentialAddress"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Residential Address",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.residentialAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "residentialAddress", $$v);
      },
      expression: "dataForm.residentialAddress"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "email"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "email",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Phone",
      "prop": "phone"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Phone"
    },
    model: {
      value: _vm.dataForm.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "phone", $$v);
      },
      expression: "dataForm.phone"
    }
  }, [_c('el-select', {
    staticClass: "wd90",
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "prefix", $$v);
      },
      expression: "dataForm.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: single.title,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Birth Date",
      "prop": "birthDate"
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Birth Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "birthDate", $$v);
      },
      expression: "dataForm.birthDate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("Disable")]), _c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Enable")])], 1)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }