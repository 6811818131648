var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": _vm.dataForm.step,
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "140px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_vm.isApplymentAuth('quotation:check') || _vm.isApplymentAuth('quotation:audit') ? _vm._l(_vm.stepStatusList, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }) : [_c('el-radio', {
    attrs: {
      "label": _vm.unselectableStatus.value
    }
  }, [_vm._v(" " + _vm._s(_vm.unselectableStatus.title) + " ")])]], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }