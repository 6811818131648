var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "append-to-body": "",
      "width": "670px",
      "title": "Upload File",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "upload-container",
    class: {
      preview: _vm.preview
    }
  }, [_c('div', {
    staticClass: "upload-button"
  }, [_c('div', [_c('svg-icon', {
    staticClass: "upload-icon",
    attrs: {
      "name": "upload"
    }
  }), _c('div', {
    staticClass: "upload-text"
  }, [_vm._v("click to add file")])], 1)]), _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.uploading,
      expression: "uploading"
    }],
    staticClass: "file-preview"
  }, [_vm._v(" " + _vm._s(_vm.file && _vm.file.name) + " ")]), _c('input', {
    ref: "fileupload",
    staticStyle: {
      "opacity": "0",
      "width": "100%",
      "height": "100%",
      "position": "absolute",
      "left": "0",
      "top": "0"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onFileChange
    }
  })]), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      "type": "primary",
      "disabled": _vm.uploading || !_vm.checked
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Upload")])], 1), _c('div', {
    staticClass: "pb20"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }