var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Initiate Termination Application",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Terminate Time",
      "prop": "terminateInfo.terminateTime"
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      "placeholder": "Payment due date"
    },
    model: {
      value: _vm.dataForm.terminateInfo.terminateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.terminateInfo, "terminateTime", $$v);
      },
      expression: "dataForm.terminateInfo.terminateTime"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }