import { render, staticRenderFns } from "./[id].vue?vue&type=template&id=1cb56d3f&scoped=true&"
import script from "./[id].vue?vue&type=script&lang=js&"
export * from "./[id].vue?vue&type=script&lang=js&"
import style0 from "./[id].vue?vue&type=style&index=0&id=1cb56d3f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cb56d3f",
  null
  
)

export default component.exports