var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "affix-placeholder",
    style: _vm.wrapStyle
  }, [_c('div', {
    class: {
      affix: _vm.affixed
    },
    style: _vm.styles
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }