var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Batch Confirm Receipt",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "140px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [[_c('el-radio', {
    attrs: {
      "label": "COMPLETE"
    }
  }, [_vm._v("Fully received")]), _c('el-radio', {
    attrs: {
      "label": "PARTLY_PAID"
    }
  }, [_vm._v("Partially received")])]], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Pay Type",
      "prop": "payType"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.payType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "payType", $$v);
      },
      expression: "dataForm.payType"
    }
  }, [[_c('el-radio', {
    attrs: {
      "label": "ALIPAY"
    }
  }, [_vm._v("Alipay")]), _c('el-radio', {
    attrs: {
      "label": "BANK_TRANSFER"
    }
  }, [_vm._v("Bank Transfer")])]], 2)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }