var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": !_vm.dataForm.id ? 'Add Role' : 'Edit Role',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "100px"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Role Code",
      "prop": "code"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Role Code",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.code,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "code", $$v);
      },
      expression: "dataForm.code"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Role Name",
      "prop": "name"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Role Name",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "name", $$v);
      },
      expression: "dataForm.name"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "size": "mini",
      "label": "Permissons"
    }
  }, [_c('el-tree', {
    ref: "menuListTree",
    attrs: {
      "data": _vm.menuList,
      "props": _vm.menuListTreeProps,
      "node-key": "id",
      "default-expand-all": true,
      "show-checkbox": ""
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }