var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Initiate Refund Application",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Choice of service(s) "
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.planObj.planName))])]), _c('el-form-item', {
    attrs: {
      "label": "Country"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.country))])]), _c('el-form-item', {
    attrs: {
      "label": "Application No"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.applymentNo))])]), _c('el-form-item', {
    attrs: {
      "label": "Applicant"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.applicantName))])]), _c('el-form-item', {
    attrs: {
      "label": "Order Amount"
    }
  }, [_c('span', {
    staticClass: "item-text price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.planObj.originalPrice)))])]), _c('el-form-item', {
    attrs: {
      "label": "Acra Fee",
      "prop": "refundDetail.acraFee"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": "Acra Fee"
    },
    model: {
      value: _vm.dataForm.refundDetail.acraFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.refundDetail, "acraFee", $$v);
      },
      expression: "dataForm.refundDetail.acraFee"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Handling Fee",
      "prop": "refundDetail.handlingFee"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": "Handling Fee"
    },
    model: {
      value: _vm.dataForm.refundDetail.handlingFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.refundDetail, "handlingFee", $$v);
      },
      expression: "dataForm.refundDetail.handlingFee"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }