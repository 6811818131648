var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "risk-assessment-wrap"
  }, [_c('p', {
    staticClass: "risk-assessment-title"
  }, [_vm._v("Risk Assessment of Customer/Client")]), _c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-position": "top"
    }
  }, _vm._l(_vm.riskList, function (item, index) {
    return _c('div', {
      key: 'rating' + index,
      staticClass: "mb80"
    }, [_c('p', {
      staticClass: "ratingTitle",
      domProps: {
        "innerHTML": _vm._s(item.title)
      }
    }), _c('p', {
      staticClass: "ratingBrief",
      domProps: {
        "innerHTML": _vm._s(item.brief)
      }
    }), item.type == 'table' ? _c('el-table', {
      staticClass: "risk-table",
      attrs: {
        "border": "",
        "data": item.list,
        "header-cell-class-name": "table-header"
      }
    }, [_c('el-table-column', {
      attrs: {
        "label": "Question",
        "header-align": "center",
        "align": "left"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c('p', {
            domProps: {
              "innerHTML": _vm._s(scope.row.title)
            }
          })];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "label": "Operation",
        "align": "center",
        "width": "334px"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c('el-form-item', {
            ref: scope.row.model,
            refInFor: true,
            attrs: {
              "prop": scope.row.model,
              "rules": {
                required: true,
                message: 'Please select',
                trigger: 'submit'
              }
            }
          }, [scope.row.type == 'radio' ? _c('el-radio-group', {
            model: {
              value: _vm.dataForm[scope.row.model],
              callback: function callback($$v) {
                _vm.$set(_vm.dataForm, scope.row.model, $$v);
              },
              expression: "dataForm[scope.row.model]"
            }
          }, _vm._l(scope.row.options, function (son, idx) {
            return _c('el-radio', {
              key: item.title + idx,
              attrs: {
                "label": son.value
              }
            }, [_vm._v(_vm._s(son.title))]);
          }), 1) : _vm._e()], 1)];
        }
      }], null, true)
    })], 1) : _vm._e(), item.type == 'result' ? _c('div', _vm._l(item.list, function (alone) {
      return _c('div', {
        key: alone.title
      }, [_c('p', {
        staticClass: "resultTitle",
        domProps: {
          "innerHTML": _vm._s(alone.title)
        }
      }), alone.type == 'radio' ? _c('el-form-item', {
        ref: alone.model,
        refInFor: true,
        attrs: {
          "prop": alone.model,
          "rules": {
            required: true,
            message: 'Please select',
            trigger: 'submit'
          }
        }
      }, [_c('el-radio-group', {
        model: {
          value: _vm.dataForm[alone.model],
          callback: function callback($$v) {
            _vm.$set(_vm.dataForm, alone.model, $$v);
          },
          expression: "dataForm[alone.model]"
        }
      }, _vm._l(alone.options, function (son, idx) {
        return _c('el-radio', {
          key: item.title + idx,
          attrs: {
            "label": son.value
          }
        }, [_vm._v(_vm._s(son.title))]);
      }), 1)], 1) : _vm._e(), alone.type == 'textarea' ? _c('el-form-item', {
        ref: alone.model,
        refInFor: true,
        attrs: {
          "prop": alone.model,
          "rules": {
            required: true,
            message: 'Please enter',
            trigger: 'submit'
          }
        }
      }, [_c('el-input', {
        attrs: {
          "type": "textarea",
          "rows": 4,
          "oninput": _vm.isEn
        },
        model: {
          value: _vm.dataForm[alone.model],
          callback: function callback($$v) {
            _vm.$set(_vm.dataForm, alone.model, $$v);
          },
          expression: "dataForm[alone.model]"
        }
      })], 1) : _vm._e(), alone.model === 'cause' ? _c('el-form-item', [_c('p', {
        staticClass: "resultTitle"
      }, [_vm._v("attachments 附件")]), _c('el-multiple-file-uploader', {
        model: {
          value: _vm.attachmentUrls,
          callback: function callback($$v) {
            _vm.attachmentUrls = $$v;
          },
          expression: "attachmentUrls"
        }
      }, [_c('el-button', {
        attrs: {
          "size": "medium"
        }
      }, [_vm._v("upload 上传")])], 1)], 1) : _vm._e()], 1);
    }), 0) : _vm._e(), _c('p', {
      staticClass: "ratingHint",
      domProps: {
        "innerHTML": _vm._s(item.hint)
      }
    }), _vm._l(item.replenish, function (subset) {
      return _c('p', {
        key: subset,
        staticClass: "ratingReplenish",
        domProps: {
          "innerHTML": _vm._s(subset)
        }
      });
    })], 2);
  }), 0), _c('span', {
    staticClass: "flex align-center justify-center dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    staticClass: "mr12",
    on: {
      "click": _vm.dataFormSave
    }
  }, [_vm._v("Save")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }