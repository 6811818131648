var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1100px",
      "title": "Edit Extra Info",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-position": "top"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Registered Office",
      "prop": "registeredOfficeType"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.registeredOfficeType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "registeredOfficeType", $$v);
      },
      expression: "dataForm.registeredOfficeType"
    }
  }, _vm._l(_vm.registerOfficeTypeList, function (item) {
    return _c('div', {
      key: item.value
    }, [_c('el-radio', {
      staticClass: "pt15 pb15",
      attrs: {
        "label": item.value
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.value === 1 && _vm.dataForm.registeredOfficeType === 1 ? _c('div', {
      staticClass: "mt15 ml24"
    }, [_c('el-radio-group', {
      model: {
        value: _vm.dataForm.registeredOfficeId,
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, "registeredOfficeId", $$v);
        },
        expression: "dataForm.registeredOfficeId"
      }
    }, _vm._l(_vm.nativeRegisteredAddress, function (office) {
      return _c('el-radio', {
        key: office.id,
        attrs: {
          "label": office.id
        }
      }, [_vm._v(_vm._s(_vm.formatNativeAddress(office)))]);
    }), 1)], 1) : _vm._e(), item.value === 2 && _vm.dataForm.registeredOfficeType === 2 ? _c('div', {
      staticClass: "mt10 ml24"
    }, [_c('el-row', {
      attrs: {
        "gutter": 20
      }
    }, [_c('el-col', {
      attrs: {
        "span": 9
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Address"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.dataForm.registeredOfficeAddress,
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, "registeredOfficeAddress", $$v);
        },
        expression: "dataForm.registeredOfficeAddress"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "City"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.dataForm.registeredOfficeCity,
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, "registeredOfficeCity", $$v);
        },
        expression: "dataForm.registeredOfficeCity"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Country"
      }
    }, [_c('el-select', {
      attrs: {
        "placeholder": "Select Country",
        "filterable": ""
      },
      model: {
        value: _vm.dataForm.registeredOfficeCountry,
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, "registeredOfficeCountry", $$v);
        },
        expression: "dataForm.registeredOfficeCountry"
      }
    }, _vm._l(_vm.countryList, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      });
    }), 1)], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Code"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.dataForm.registeredOfficePostalCode,
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, "registeredOfficePostalCode", $$v);
        },
        expression: "dataForm.registeredOfficePostalCode"
      }
    })], 1)], 1)], 1)], 1) : _vm._e()])], 1);
  }), 0)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Working hours",
      "prop": "workingHours"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "placeholder": "working hours"
    },
    model: {
      value: _vm.dataForm.workingHours,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "workingHours", $$v);
      },
      expression: "dataForm.workingHours"
    }
  }, _vm._l(_vm.workingHoursOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "span": 24
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Constitution",
      "prop": "isCustomizedConstitution"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "clearable": "",
      "placeholder": "Constitution"
    },
    model: {
      value: _vm.dataForm.isCustomizedConstitution,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "isCustomizedConstitution", $$v);
      },
      expression: "dataForm.isCustomizedConstitution"
    }
  }, _vm._l(_vm.customizedConstitutionOptions, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Is the company's business special and needs to obtain approval(s) from specific government authorities?",
      "prop": "hasObtainedApproval"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.hasObtainedApproval,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "hasObtainedApproval", $$v);
      },
      expression: "dataForm.hasObtainedApproval"
    }
  }, _vm._l(_vm.checkboxOptions, function (item) {
    return _c('el-radio', {
      key: item.value,
      attrs: {
        "label": item.value
      }
    }, [_vm._v(_vm._s(item.title))]);
  }), 1), _vm.dataForm.hasObtainedApproval === 1 ? _c('el-checkbox-group', {
    model: {
      value: _vm.dataForm.approvalAuthorities,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "approvalAuthorities", $$v);
      },
      expression: "dataForm.approvalAuthorities"
    }
  }, _vm._l(_vm.authoritiesOptions, function (item) {
    return _c('el-checkbox', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1) : _vm._e()], 1), _vm.isHasEPApplicant ? _c('el-form-item', {
    attrs: {
      "label": "EP Info",
      "prop": "epInfo"
    }
  }, [!_vm.dataForm.epInfo || _vm.dataForm.epInfo.length == 0 ? _c('el-button', {
    staticClass: "mb20",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addOrUpdateHandle(null);
      }
    }
  }, [_vm._v(" Add EP Applicant ")]) : _vm._e(), _c('el-table', {
    key: Math.random(),
    attrs: {
      "data": _vm.dataForm.epInfo,
      "border": ""
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "fullName",
      "label": "Name",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "nationality",
      "label": "Nationality",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "phone",
      "label": "Mobile",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "email",
      "label": "Email",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Operation",
      "align": "center"
    }
  }, [[_c('el-button', {
    attrs: {
      "type": "text",
      "size": "small"
    },
    on: {
      "click": function click($event) {
        return _vm.addOrUpdateHandle(_vm.dataForm.epInfo[0]);
      }
    }
  }, [_vm._v("Edit")]), _c('el-button', {
    attrs: {
      "type": "text",
      "size": "small"
    },
    on: {
      "click": _vm.deleteMember
    }
  }, [_vm._v("Delete")])]], 2)], 1)], 1) : _vm._e()], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "change": _vm.doUpdateMember
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }