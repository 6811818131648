var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step-header"
  }, [_c('div', {
    staticClass: "t"
  }, [_vm._v(_vm._s(_vm.label))]), _c('div', {
    staticClass: "r"
  }, [_vm._t("default")], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }