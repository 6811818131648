var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.quotationList.length > 0 ? _c('div', {
    staticClass: "fluid-width plan-detail-module"
  }, [_c('div', {
    staticClass: "fluid-width pb0 singleWrap"
  }, [_c('div', {
    staticClass: "fluid-width"
  }, _vm._l(_vm.quotationList, function (parent, index) {
    return _c('div', {
      key: "parent".concat(index),
      staticClass: "mb20 shadeWrap"
    }, [_c('el-row', {
      staticClass: "pt10 pb10 fxRow borderTop",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "span": 8
      }
    }, [_c('p', {
      staticClass: "fs14 fwb billingTo"
    }, [_vm._v(" Billing to: "), _c('span', {
      staticClass: "fw400 fs12"
    }, [_vm._v(_vm._s(parent.companyTitle))])])]), _c('el-col', {
      attrs: {
        "span": 8
      }
    }, [_c('p', {
      staticClass: "fs14 fwb billingTo"
    }, [_vm._v(" Address: "), _c('span', {
      staticClass: "fw400 fs12"
    }, [_vm._v(_vm._s(parent.companyAddress))])])]), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('p', {
      staticClass: "fs14 fwb billingTo"
    }, [_vm._v(" Country: "), _c('span', {
      staticClass: "fw400 fs12"
    }, [_vm._v(_vm._s(parent.companyCountry))])])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs14 fwb billingTo"
    }, [_vm._v(" Code: "), _c('span', {
      staticClass: "fw400 fs12"
    }, [_vm._v(_vm._s(parent.companyPostalCode))])])])], 1), _c('div', {
      staticClass: "billingTitle"
    }, [_c('el-row', {
      staticClass: "pt10 pb10 fxRow borderTop",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Fee Schedule")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Covered Period")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Amount")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Inclusive of third party fee")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Inclusive of other fee")])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Payment Due Date")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Generic Invoice")])]), _c('el-col', {
      attrs: {
        "span": 2
      }
    }, [_c('p', {
      staticClass: "fs12 fwb text-center"
    }, [_vm._v("Status")])])], 1)], 1), _vm._l(parent.partList, function (sub, idx) {
      return _c('div', {
        key: "sub".concat(idx)
      }, [_vm._l(sub.itemList, function (grand, ids) {
        return _c('div', {
          key: "grand".concat(ids),
          staticClass: "billingContent"
        }, [_c('el-row', {
          staticClass: "pt10 pb10 fxRow borderTop",
          attrs: {
            "gutter": 10
          }
        }, [_c('el-col', {
          attrs: {
            "span": 5
          }
        }, [_c('p', {
          staticClass: "fs12 text-center lh16"
        }, [_vm._v(_vm._s(grand.title))])]), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('p', {
          staticClass: "fs12 text-center lh16"
        }, [_vm._v(_vm._s(grand.startDate) + " ~ " + _vm._s(grand.endDate))])]), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('p', {
          staticClass: "fs12 text-center lh16 orange newline"
        }, [_vm._v(_vm._s(_vm._f("thousands")(grand.amount)))])]), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('p', {
          staticClass: "fs12 text-center lh16 orange newline"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(grand.thirdPartyFee)) + " "), grand.noteForThirdParty ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('p', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(grand.noteForThirdParty))]), _c('span', {
          staticClass: "fs13 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()], 1)]), _c('el-col', {
          attrs: {
            "span": 3
          }
        }, [_c('p', {
          staticClass: "fs12 text-center lh16 orange newline"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(grand.otherFee)) + " "), grand.noteForOther ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('p', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(grand.noteForOther))]), _c('span', {
          staticClass: "fs13 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()], 1)]), _c('el-col', {
          attrs: {
            "span": 3
          }
        }), _c('el-col', {
          attrs: {
            "span": 2
          }
        }), _c('el-col', {
          attrs: {
            "span": 2
          }
        })], 1)], 1);
      }), _c('div', {
        staticClass: "billingBrief"
      }, [_c('el-row', {
        staticClass: "pt10 pb10 fxRow borderTop borderBottom",
        attrs: {
          "gutter": 10
        }
      }, [_c('el-col', {
        attrs: {
          "span": 5
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16"
      }, [_vm._v(_vm._s(sub.subTotalDesp))])]), _c('el-col', {
        attrs: {
          "span": 3
        }
      }), _c('el-col', {
        attrs: {
          "span": 3
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v(_vm._s(_vm._f("thousands")(sub.subTotalPrice)))])]), _c('el-col', {
        attrs: {
          "span": 3
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v(_vm._s(_vm._f("thousands")(sub.subTotalThirdPartyFee)))])]), _c('el-col', {
        attrs: {
          "span": 3
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16 pb2"
      }, [_vm._v("Subtotal")]), _c('p', {
        staticClass: "fs12 text-center lh16 orange newline"
      }, [_vm._v(_vm._s(_vm._f("thousands")(sub.subTotalOtherFee)))])]), _c('el-col', {
        attrs: {
          "span": 3
        }
      }, [sub.isDownPayment == 1 ? _c('p', {
        staticClass: "fs12 text-center lh16"
      }, [_vm._v("Payable upon submission")]) : _vm._e(), sub.isDownPayment == 0 ? _c('p', {
        staticClass: "fs12 text-center lh16"
      }, [_vm._v(_vm._s(sub.paymentDueTime))]) : _vm._e()]), _c('el-col', {
        attrs: {
          "span": 2
        }
      }, [_vm.applymentStatus != 'DRAFT' && _vm.applymentStatus != 'INQUIRING' && _vm.applymentStatus != 'QUOTING' && _vm.applymentStatus != 'QUOTATION_AUDITING' && _vm.applymentStatus != 'QUOTATION_AUDIT_PASS' && _vm.applymentStatus != 'QUOTATION_AUDIT_FAIL' && _vm.hasInvoice(sub.invoiceList, 1) ? _c('p', {
        staticClass: "fs12 text-center lh16 cursor-pointer blue",
        on: {
          "click": function click($event) {
            _vm.invoiceLink('unpaid', _vm.hasInvoice(sub.invoiceList, 1));
          }
        }
      }, [_vm._v(" Invoice ")]) : _vm._e(), (_vm.applymentStatus == 'REFUND_APPLY_PASS' || _vm.applymentStatus == 'CANCELED') && _vm.hasInvoice(sub.invoiceList, 2) ? _c('p', {
        staticClass: "pt6 fs12 text-center lh16 cursor-pointer blue",
        on: {
          "click": function click($event) {
            _vm.invoiceLink('refund', _vm.hasInvoice(sub.invoiceList, 2));
          }
        }
      }, [_vm._v(" Debit Invoice ")]) : _vm._e()]), _c('el-col', {
        attrs: {
          "span": 2
        }
      }, [_c('p', {
        staticClass: "fs12 text-center lh16"
      }, [_vm._v(_vm._s(_vm.statusFormat(sub.orderStatus)))])])], 1), _c('el-row', {
        staticClass: "pt20 pb20 fxRow",
        attrs: {
          "gutter": 10
        }
      })], 1)], 2);
    }), _c('div', {
      staticClass: "billingBottom"
    }, [_c('el-row', {
      staticClass: "pt10 pb10 fxRow borderTop borderBottom",
      attrs: {
        "gutter": 10
      }
    }, [_c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16"
    }, [_vm._v(_vm._s(parent.sumTotalDesp))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v(_vm._s(_vm._f("thousands")(parent.sumTotalPrice)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v(_vm._s(_vm._f("thousands")(parent.sumTotalThirdPartyFee)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }, [_c('p', {
      staticClass: "fs12 text-center lh16 pb2"
    }, [_vm._v("Total")]), _c('p', {
      staticClass: "fs12 text-center lh16 orange newline"
    }, [_vm._v(_vm._s(_vm._f("thousands")(parent.sumTotalOtherFee)))])]), _c('el-col', {
      attrs: {
        "span": 3
      }
    }), _c('el-col', {
      attrs: {
        "span": 2
      }
    }), _c('el-col', {
      attrs: {
        "span": 2
      }
    })], 1)], 1)], 2);
  }), 0)])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }