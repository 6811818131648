var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-popover', {
    attrs: {
      "title": "Feedback",
      "popper-class": "popover-modal",
      "width": "400",
      "trigger": "click"
    },
    on: {
      "show": _vm.init
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm
    }
  }, [_c('el-form-item', {
    attrs: {
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "resize": "none",
      "type": "textarea",
      "rows": "4",
      "placeholder": "If there is no problem, please leave it blank",
      "disabled": (_vm.detail.applymentStatus === 'CHECKING' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'REJECTED' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'REVISING') && (_vm.isApplymentAuth('company:coordinate') || _vm.isApplymentAuth('company:check')) ? false : true
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1), (_vm.detail.applymentStatus === 'CHECKING' || _vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'REJECTED' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'REVISING') && (_vm.isApplymentAuth('company:coordinate') || _vm.isApplymentAuth('company:check')) ? _c('div', {
    staticClass: "footer"
  }, [_c('el-button', {
    attrs: {
      "size": "mini",
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")]), _c('el-button', {
    attrs: {
      "size": "mini"
    },
    on: {
      "click": _vm.closePopover
    }
  }, [_vm._v("Cancel")])], 1) : _vm._e()], 1), _c('template', {
    slot: "reference"
  }, [_vm._t("default")], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }