var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Select Nominee Director",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('el-table', {
    attrs: {
      "data": _vm.nomineeDirectorList,
      "header-cell-class-name": "table-header"
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "60px",
      "align": "center",
      "header-align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-radio', {
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            "label": scope.row
          },
          model: {
            value: _vm.currRow,
            callback: function callback($$v) {
              _vm.currRow = $$v;
            },
            expression: "currRow"
          }
        }, [_vm._v(_vm._s(""))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Name",
      "prop": "fullName",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Nationality",
      "prop": "nationality",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Phone",
      "prop": "phone",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Email",
      "prop": "email",
      "align": "center"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }