var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "200px"
    }
  }, [_c('p', {
    staticClass: "fs18 fwb pt0 mt0 mb30"
  }, [_vm._v("Custom Page")]), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Beginner Guide Link",
      "prop": "beginnerGuideLink"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Beginner Guide Link"
    },
    model: {
      value: _vm.dataForm.beginnerGuideLink,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "beginnerGuideLink", $$v);
      },
      expression: "dataForm.beginnerGuideLink"
    }
  })], 1), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Beginner Guide Link(CN)",
      "prop": "beginnerGuideLinkCn"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Beginner Guide Link(CN)"
    },
    model: {
      value: _vm.dataForm.beginnerGuideLinkCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "beginnerGuideLinkCn", $$v);
      },
      expression: "dataForm.beginnerGuideLinkCn"
    }
  })], 1), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "Wechat Qr Code",
      "prop": "kfWechatQrcodeUrl"
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.kfWechatQrcodeUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "kfWechatQrcodeUrl", $$v);
      },
      expression: "dataForm.kfWechatQrcodeUrl"
    }
  })], 1), _c('el-form-item', {
    staticClass: "wd550",
    attrs: {
      "label": "WhatsApp Qr Code",
      "prop": "kfWhatsappQrcodeUrl"
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.kfWhatsappQrcodeUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "kfWhatsappQrcodeUrl", $$v);
      },
      expression: "dataForm.kfWhatsappQrcodeUrl"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Guide Banners",
      "prop": "guideBanners"
    }
  }, [_c('span', {
    staticClass: "lightGray"
  }, [_vm._v("recommend: 750*532(w*h), png(format)")]), _c('el-multiple-file-uploader', {
    attrs: {
      "accept": "image/*"
    },
    model: {
      value: _vm.dataForm.guideBanners,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "guideBanners", $$v);
      },
      expression: "dataForm.guideBanners"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Guide Banners(CN)",
      "prop": "guideBannersCn"
    }
  }, [_c('span', {
    staticClass: "lightGray"
  }, [_vm._v("recommend: 750*532(w*h), png(format)")]), _c('el-multiple-file-uploader', {
    attrs: {
      "accept": "image/*"
    },
    model: {
      value: _vm.dataForm.guideBannersCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "guideBannersCn", $$v);
      },
      expression: "dataForm.guideBannersCn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": ""
    }
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("Submit")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }