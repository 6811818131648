var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-applyment-module"
  }, [_c('el-affix', {
    class: {
      'applyment-page-header-top': _vm.affixed,
      'applyment-page-header-shrink': _vm.sidebarFold,
      'applyment-page-header-unfold': !_vm.sidebarFold
    },
    attrs: {
      "offset": 104
    },
    on: {
      "change": _vm.handleAffixChange
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "applyment-page-header"
  }, [_c('div', {
    staticClass: "status"
  }, [_vm.detail.refundDetail && _vm.detail.refundDetail.status ? [_vm._v(" " + _vm._s(_vm.refundStatusFormat(_vm.detail.refundDetail.status)) + " ")] : [_vm._v(" " + _vm._s(_vm.applymentStatusFormat(_vm.detail.status)) + " ")], _vm.detail.creditStatus ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v("(Credit: " + _vm._s(_vm.creditStatusFormat(_vm.detail.creditStatus)) + ")")]) : _vm._e()], 2), _c('div', {
    staticClass: "page-header-heading-right"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openCreditHistoryModal
    }
  }, [_vm._v("Credit History")]), _c('div', {
    staticClass: "ml10 opts"
  }, [(_vm.detail.status === 'UNPAID' || _vm.detail.status === 'PARTLY_PAID') && _vm.isAuth('finance:financial') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleConfirmReceipt
    }
  }, [_vm._v(" Confirm Receipt ")]) : _vm._e(), _vm.detail.refundDetail && _vm.detail.refundDetail.status && (_vm.detail.refundDetail.status === 'REFUNDING' || _vm.detail.refundDetail.status === 'REFUND_INFO_UPDATED' || _vm.detail.refundDetail.status === 'REFUND_PENDING') && _vm.isAuth('finance:financial') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleConfirmRefund
    }
  }, [_vm._v(" Confirm Refund ")]) : _vm._e()], 1), _vm.detail.status === 'UNPAID' || _vm.detail.status === 'PARTLY_PAID' ? _c('div', {
    staticClass: "ml10 opts"
  }, [(!_vm.detail.creditStatus || _vm.detail.creditStatus == 'REJECTED') && _vm.isAuth('finance:financial') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleCreditApply
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.creditStatus == "REJECTED" ? "Credit Re-apply" : "Credit Apply") + " ")]) : _vm._e(), _vm.detail.creditStatus == 'PENDING' && _vm.isAuth('finance:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleCreditReview
    }
  }, [_vm._v(" Credit Review ")]) : _vm._e()], 1) : _vm._e()], 1)])])], 1), _vm.detail.orderType == 'generic' || _vm.detail.orderType == 'amends' ? [_vm.detail.refundDetail ? _c('div', {
    staticClass: "step mt50"
  }, [_c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('refund-info', {
    attrs: {
      "detail": _vm.detail
    }
  })], 1)], 1) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "step mt40"
  }, [_c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('base-info', {
    attrs: {
      "detail": _vm.detail,
      "amendsList": _vm.amendsList
    }
  })], 1)], 1), _c('confirm-receipt', {
    ref: "confirmReceiptModal"
  }), _c('confirm-refund', {
    ref: "confirmRefundModal",
    attrs: {
      "refundDetail": _vm.detail.refundDetail
    }
  }), _c('credit-apply', {
    ref: "creditApplyModal"
  }), _c('credit-review', {
    ref: "creditReviewModal"
  }), _c('credit-history', {
    ref: "creditHistoryModal",
    attrs: {
      "orderId": _vm.detail.id
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }