var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Confirmation:")]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "applicant"
    }
  }), _vm._v(" Applicant information ")], 1), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant Name")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.applicantName))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Mobile")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.applicantPhone))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Email")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.applicantEmail))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Residential Address")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.residentialAddress))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant role")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.applicantPositionFormat(_vm.detail.companyApplyment.applicantPosition)) + " ")]) : _vm._e()])])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "country"
    }
  }), _vm._v(" Location of the proposed company ")], 1), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.country))]) : _vm._e()])]), _vm.detail.orderType == 'generic' ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "planAndServices"
    }
  }), _vm._v(" Choice of service(s) ")], 1), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "mt10"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('customized-detail', {
    attrs: {
      "businessType": _vm.detail.businessType,
      "quotationPart": _vm.detail.quotationPart
    }
  }) : _c('plan-detail', {
    attrs: {
      "businessType": _vm.detail.businessType,
      "goodsList": _vm.detail.goodsList
    }
  })], 1)])])]) : _vm._e(), _vm.detail.orderType == 'amends' ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Supplemental")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "mt10"
  }, [_c('el-table', {
    staticClass: "amendsTable",
    attrs: {
      "header-cell-style": {
        color: '#011A45',
        background: '#fff5f0 !important'
      },
      "row-style": {
        background: '#fff5f0'
      },
      "data": _vm.amendsList
    }
  }, [_c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.priceSg)))])];
      }
    }], null, false, 2866338016)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Third party fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.thirdPartyFee)))])];
      }
    }], null, false, 1045084306)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "description",
      "label": "Description (For External Disclosure)"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Other Fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', {
          staticClass: "price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.otherFee)))]), scope.row.otherRemark ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.otherRemark))]), _c('span', {
          staticClass: "ml8 fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 363265103)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Fee Letter"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.feeLetter ? _c('a', {
          staticClass: "fs12 blue mr10",
          attrs: {
            "href": scope.row.feeLetter.url,
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(scope.row.feeLetter.name) + " ")]) : _vm._e()];
      }
    }], null, false, 1491747377)
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Supplemental Invoice"
    }
  }, [[_vm.detail.orderNo ? _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('amends');
      }
    }
  }, [_vm._v("Invoice")]) : _vm._e()]], 2), _c('el-table-column', {
    attrs: {
      "align": "center",
      "prop": "status",
      "label": "Status",
      "formatter": _vm.statusFormat
    }
  })], 1)], 1)])])]) : _vm._e(), _vm.detail.orderType == 'supplement' ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Quotation (V)")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "mt10"
  }, [_c('el-table', {
    staticClass: "amendsTable",
    attrs: {
      "header-cell-style": {
        color: '#011A45',
        background: '#fff5f0 !important',
        height: '62px'
      },
      "row-style": {
        background: '#fff5f0'
      },
      "data": _vm.quotationVList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "supplementNo",
      "header-align": "center",
      "align": "center",
      "label": "Quotation (V) No."
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "businessNo",
      "header-align": "center",
      "align": "center",
      "label": "Linked Transaction No.",
      "min-width": "90"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "billingParty",
      "header-align": "center",
      "align": "center",
      "label": "Billing Party"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Exchange Rate",
      "min-width": "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.paymentDetail ? [scope.row.paymentDetail.currency != 'SGD' ? _c('p', [_vm._v(_vm._s(scope.row.paymentDetail.exchangeRate))]) : _vm._e()] : _vm._e()];
      }
    }], null, false, 2415234405)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Amount"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.paymentDetail.amount, scope.row.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.applyReason ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.applyReason))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 1923005409)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Third party fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.paymentDetail.thirdPartyFee, scope.row.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.noteForThirdParty ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.noteForThirdParty))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 1977562982)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Other Fee"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.paymentDetail ? _c('span', {
          staticClass: "price"
        }, [_vm._v(" " + _vm._s(_vm._f("thousands")(scope.row.paymentDetail.otherFee, scope.row.paymentDetail.currencyUnit)) + " ")]) : _vm._e(), scope.row.noteForOther ? _c('el-popover', {
          attrs: {
            "placement": "bottom",
            "trigger": "hover"
          }
        }, [_c('span', {
          staticClass: "fs12"
        }, [_vm._v(_vm._s(scope.row.noteForOther))]), _c('span', {
          staticClass: "fs14 blackD8 el-icon-question",
          attrs: {
            "slot": "reference"
          },
          slot: "reference"
        })]) : _vm._e()];
      }
    }], null, false, 3713706575)
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Attachments",
      "min-width": "96"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.attachmentUrls, function (item) {
          return _c('p', {
            key: item.url
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_target"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        });
      }
    }], null, false, 3052647337)
  }), _c('el-table-column', {
    attrs: {
      "prop": "createTime",
      "header-align": "center",
      "align": "center",
      "label": "Create Time"
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "label": "Generic Invoice"
    }
  }, [[_vm.detail.orderNo ? _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('quotationV');
      }
    }
  }, [_vm._v("Invoice")]) : _vm._e()]], 2), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "formatter": _vm.supplementStatusFormat
    }
  })], 1)], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Order")]), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Transaction No.")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.applymentNo))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Invoice No.")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.orderNo))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Create Time")]), _vm.detail.companyApplyment ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.companyApplyment.createTime))]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Coordinator")]), _vm.detail.companyApplyment && _vm.detail.companyApplyment.coordinator ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.companyApplyment.coordinator.nickname) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Checker")]), _vm.detail.companyApplyment && _vm.detail.companyApplyment.checker ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.companyApplyment.checker.nickname) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Reviewer")]), _vm.detail.companyApplyment && _vm.detail.companyApplyment.auditor ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.companyApplyment.auditor.nickname) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Pay Type")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.payTypesFormat(_vm.detail.payType)))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Transfer Remark")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.transferVerifyRemark))])])])], 1)], 1)]), _vm.detail.orderType == 'generic' ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Generic Invoice")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm.detail.companyApplyment && _vm.detail.companyApplyment.applymentStatus != 'DRAFT' && _vm.detail.companyApplyment.applymentStatus != 'INQUIRING' && _vm.detail.companyApplyment.applymentStatus != 'QUOTING' && _vm.detail.companyApplyment.applymentStatus != 'QUOTATION_AUDITING' && _vm.detail.companyApplyment.applymentStatus != 'QUOTATION_AUDIT_PASS' && _vm.detail.companyApplyment.applymentStatus != 'QUOTATION_AUDIT_FAIL' && _vm.detail.orderNo ? _c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('unpaid');
      }
    }
  }, [_vm._v(" Invoice "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1) : _vm._e(), _vm.detail.refundDetail && _vm.detail.refundDetail.refundNo && _vm.detail.companyApplyment && (_vm.detail.companyApplyment.applymentStatus == 'REFUND_APPLY_PASS' || _vm.detail.companyApplyment.applymentStatus == 'CANCELED') ? _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.invoiceLink('refund');
      }
    }
  }, [_vm._v(" Debit Invoice "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1) : _vm._e()])])]) : _vm._e(), _vm.detail.status == 'COMPLETE' && _vm.detail.isInvoiceRevised ? _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Revised Invoice")]), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.revisedInvoiceFile ? _c('div', {
    staticClass: "desp-item-self"
  }, [_c('a', {
    staticClass: "to-link",
    attrs: {
      "href": _vm.detail.revisedInvoiceFile.url,
      "target": "_target"
    }
  }, [_vm._v(" " + _vm._s(_vm.detail.revisedInvoiceFile.name) + " "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1)]) : _vm._e()])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }