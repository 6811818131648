var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-materials-module"
  }, [_c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Upload")]), _c('div', [_vm.detail.businessType == 'COMPANY_INCORPORATION' ? _c('Incorporation', {
    attrs: {
      "detail": _vm.detail
    }
  }) : _vm._e(), _vm.detail.businessType == 'SERVICE_TRANSFER' ? _c('Transfer', {
    attrs: {
      "detail": _vm.detail
    }
  }) : _vm._e(), _vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('Quotation', {
    attrs: {
      "detail": _vm.detail
    }
  }) : _vm._e(), _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('FundAdmin', {
    attrs: {
      "detail": _vm.detail
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }