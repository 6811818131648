var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-user"
  }, [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.getDataList(1);
      }
    }
  }, [_c('div', {
    staticClass: "flex-row jcsb"
  }, [_c('div', [_c('el-form-item', [_c('el-select', {
    staticClass: "wd150",
    attrs: {
      "clearable": "",
      "placeholder": "Role Name"
    },
    model: {
      value: _vm.dataForm.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "roleId", $$v);
      },
      expression: "dataForm.roleId"
    }
  }, _vm._l(_vm.roleList, function (item) {
    return _c('el-option', {
      key: item.id,
      attrs: {
        "label": item.name,
        "value": item.id
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-input', {
    staticClass: "wd150",
    attrs: {
      "placeholder": "Nick Name",
      "clearable": "",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.nickname,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "nickname", $$v);
      },
      expression: "dataForm.nickname"
    }
  })], 1), _c('el-form-item', [_c('el-input', {
    attrs: {
      "placeholder": "Staff No.",
      "clearable": "",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.staffNo,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "staffNo", $$v);
      },
      expression: "dataForm.staffNo"
    }
  })], 1), _c('el-form-item', [_c('el-input', {
    attrs: {
      "placeholder": "Position",
      "clearable": "",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.position,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "position", $$v);
      },
      expression: "dataForm.position"
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1), _c('el-form-item', {
    staticClass: "sync"
  }, [_c('el-button', {
    staticClass: "fs14",
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.synchronous
    }
  }, [_vm._v("Sync")])], 1)], 1)])]), _c('div', {
    staticClass: "mb26"
  }), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "600",
      "data": _vm.dataList
    },
    on: {
      "selection-change": _vm.selectionChangeHandle
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "selection",
      "header-align": "center",
      "align": "center",
      "width": "50",
      "fixed": "left"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "email",
      "header-align": "center",
      "align": "center",
      "width": "200",
      "label": "Email",
      "fixed": "left"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "nickname",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Nick Name",
      "fixed": "left"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "staffNo",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Staff No.",
      "fixed": "left"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "position",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "label": "Position"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "employers",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "label": "Employer"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.employers ? _c('p', [_vm._v(_vm._s(scope.row.employers.join(", ")))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "phone",
      "header-align": "center",
      "align": "center",
      "width": "150",
      "label": "Phone"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "roleList",
      "header-align": "center",
      "align": "center",
      "width": "180",
      "label": "Role",
      "formatter": _vm.roleListFormatter
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "createTime",
      "header-align": "center",
      "align": "center",
      "width": "180",
      "label": "Register Date"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "width": "100",
      "label": "Status",
      "formatter": _vm.stateFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "min-width": "150",
      "label": "Remark",
      "show-overflow-tooltip": ""
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.isAuth('sys:user:update') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.addOrUpdateHandle(scope.row.id);
            }
          }
        }, [_vm._v("Roles")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.pageSize,
      "total": _vm.totalCount,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  }) : _vm._e(), _c('config-modal', {
    ref: "configModal",
    on: {
      "getEmployerList": _vm.getEmployerList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }