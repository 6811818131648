var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    ref: "wEditor",
    staticClass: "wEditor"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }