var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-row', {
    staticClass: "company-files-wrapper",
    attrs: {
      "gutter": 40
    }
  }, _vm._l(_vm.fileUrls, function (file, idx) {
    return _c('el-col', {
      key: idx,
      attrs: {
        "span": 6
      }
    }, [_c('div', {
      staticClass: "list-item"
    }, [_c('div', {
      staticClass: "item-wrapper"
    }, [_c('div', {
      staticClass: "body"
    }, [_vm.isImage(file) ? _c('div', {
      staticClass: "image-wrapper"
    }, [_c('el-image', {
      staticClass: "img",
      attrs: {
        "src": file.url,
        "preview-src-list": [file.url],
        "fit": "contain"
      }
    })], 1) : _c('div', {
      staticClass: "file"
    }, [_c('div', {
      staticClass: "file-type"
    }, [file.url.toLowerCase().includes('.txt') ? _c('img', {
      attrs: {
        "src": require("@/assets/img/file/txt.png"),
        "alt": "",
        "width": "40"
      }
    }) : _vm._e(), file.url.toLowerCase().includes('.pdf') ? _c('img', {
      attrs: {
        "src": require("@/assets/img/file/pdf.png"),
        "alt": ""
      }
    }) : _vm._e(), file.url.toLowerCase().includes('.ppt') || file.url.toLowerCase().includes('.pptx') ? _c('img', {
      attrs: {
        "src": require("@/assets/img/file/ppt.png"),
        "alt": ""
      }
    }) : _vm._e(), file.url.toLowerCase().includes('.doc') || file.url.toLowerCase().includes('.docx') ? _c('img', {
      attrs: {
        "src": require("@/assets/img/file/doc.png"),
        "alt": ""
      }
    }) : _vm._e()]), _c('a', {
      staticClass: "file-name",
      attrs: {
        "href": file.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(file.name))])])]), _c('ul', {
      staticClass: "actions"
    }, [file.status && file.status > 0 ? _c('li', [_c('span', {
      staticClass: "el-icon-error t"
    }), _c('span', {
      staticClass: "red-status ml10"
    }, [_vm._v(_vm._s(_vm.statusFormat(file.status)))])]) : _vm._e(), _c('li', [_c('remark-modal', {
      ref: "remarkModal",
      refInFor: true,
      attrs: {
        "data": file,
        "detail": _vm.detail
      },
      on: {
        "submit": function submit(file) {
          return _vm.handleSubmit(idx, file);
        }
      }
    }, [_c('div', [_c('svg-icon', {
      staticClass: "opt-icon",
      attrs: {
        "name": "feedback"
      }
    }), _c('svg-icon', {
      staticClass: "opt-icon-active",
      attrs: {
        "name": "feedbackActive"
      }
    }), _c('span', {
      staticClass: "feedback-color ml10"
    }, [_vm._v("Feedback")])], 1)])], 1)])])])]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }