var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Confirmation:")]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "applicant"
    }
  }), _vm._v(" Applicant information ")], 1), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant Name")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantName))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Mobile")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantPhone))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Email")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.applicantEmail))])])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Residential Address")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.residentialAddress))])])]), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Applicant role")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.applicantPositionFormat(_vm.detail.applicantPosition)))])])])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "country"
    }
  }), _vm._v(" Location of the proposed company ")], 1), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.country))])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title-icon"
  }, [_c('svg-icon', {
    staticClass: "jump-icon",
    attrs: {
      "name": "planAndServices"
    }
  }), _vm._v(" Choice of service(s) ")], 1), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Promotion code")]), _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(_vm._s(_vm.detail.promotionCode))])])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Front user website")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('details');
      }
    }
  }, [_vm._v(" View details "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1)])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Front user Fee Letter")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('span', {
    staticClass: "mr40 to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('feeLetter', 'en');
      }
    }
  }, [_vm._v(" View Fee Letter(EN) "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1), _c('span', {
    staticClass: "to-link",
    on: {
      "click": function click($event) {
        return _vm.userDetailLink('feeLetter', 'zh');
      }
    }
  }, [_vm._v(" View Fee Letter(CN) "), _c('svg-icon', {
    staticClass: "opt-icon",
    attrs: {
      "name": "jump"
    }
  })], 1)])])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Service Quotation")]), _c('div', {
    staticClass: "pt10 desp-item"
  }, [(_vm.detail.applymentStatus === 'INQUIRING' || _vm.detail.applymentStatus === 'QUOTING' || _vm.detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') && _vm.isApplymentAuth('quotation:coordinate') ? _c('div', {
    staticClass: "desp-item-self"
  }, [_c('el-multiple-file-uploader', {
    on: {
      "input": _vm.moduleChange
    },
    model: {
      value: _vm.feeLetterUrls,
      callback: function callback($$v) {
        _vm.feeLetterUrls = $$v;
      },
      expression: "feeLetterUrls"
    }
  })], 1) : _c('div', {
    staticClass: "desp-item-self"
  }, _vm._l(_vm.feeLetterUrls, function (item) {
    return _c('p', {
      key: item.url,
      staticClass: "m0 lh22 pb10"
    }, [_c('a', {
      staticClass: "fs14 blue",
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Linked Transaction No. (Copy from)")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm._v(_vm._s(_vm.detail.excApplymentNo))])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }