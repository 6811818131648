var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isAuth('finance:financial') ? _c('div', {
    staticClass: "pd26 mb30 min-hg460 bg-white border-radius-10"
  }, [_vm._m(0), _c('div', {
    staticClass: "mt10"
  }, [_c('div', {
    staticClass: "flex flex-direction-row align-center justify-between home-title"
  }, [_c('p', {
    staticClass: "text-left aurantia symbol-price"
  }, [_vm._v(" S$ "), _vm.atAmountType == 1 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.monthObj.transactionSum)))]) : _vm._e(), _vm.atAmountType == 2 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.quarterlyObj.transactionSum)))]) : _vm._e(), _vm.atAmountType == 0 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.yearObj.transactionSum)))]) : _vm._e()]), _c('p', {
    staticClass: "text-right cyan symbol-price"
  }, [_vm._v(" S$ "), _vm.atAmountType == 1 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.monthObj.turnoverSum)))]) : _vm._e(), _vm.atAmountType == 2 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.quarterlyObj.turnoverSum)))]) : _vm._e(), _vm.atAmountType == 0 ? _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.yearObj.turnoverSum)))]) : _vm._e()])]), _c('div', {
    staticClass: "pt14 pb20 flex justify-between align-center"
  }, [_c('div', {
    staticClass: "flex justify-center capsule-wrap"
  }, [_c('div', {
    staticClass: "capsule-item",
    class: _vm.atAmountType == 1 ? 'capsule-active' : '',
    on: {
      "click": function click($event) {
        _vm.atAmountType = 1, _vm.getMonthSum();
      }
    }
  }, [_vm._v("Month")]), _c('div', {
    staticClass: "capsule-item",
    class: _vm.atAmountType == 2 ? 'capsule-active' : '',
    on: {
      "click": function click($event) {
        _vm.atAmountType = 2, _vm.getQuarterlySum();
      }
    }
  }, [_vm._v("Quarterly")]), _c('div', {
    staticClass: "capsule-item",
    class: _vm.atAmountType == 0 ? 'capsule-active' : '',
    on: {
      "click": function click($event) {
        _vm.atAmountType = 0, _vm.getYearSum();
      }
    }
  }, [_vm._v("Year")])]), _c('div', [_c('div', [_vm.atAmountType == 1 ? _c('el-date-picker', {
    staticClass: "wd120",
    attrs: {
      "type": "month",
      "value-format": "yyyy-MM-dd",
      "size": "small"
    },
    on: {
      "change": _vm.accordingMonthChange
    },
    model: {
      value: _vm.accordingMonth,
      callback: function callback($$v) {
        _vm.accordingMonth = $$v;
      },
      expression: "accordingMonth"
    }
  }) : _vm._e()], 1), _c('div', [_vm.atAmountType == 0 || _vm.atAmountType == 2 ? _c('el-date-picker', {
    staticClass: "wd120",
    attrs: {
      "type": "year",
      "value-format": "yyyy-MM-dd",
      "size": "small"
    },
    on: {
      "change": _vm.accordingYearChange
    },
    model: {
      value: _vm.accordingYear,
      callback: function callback($$v) {
        _vm.accordingYear = $$v;
      },
      expression: "accordingYear"
    }
  }) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "fluid-width hg284",
    attrs: {
      "id": "transaction-amount"
    }
  })]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "flex flex-direction-row align-center justify-between home-title"
  }, [_c('span', [_vm._v("Total Transaction")]), _c('span', [_vm._v("Total Turnover")])]);
}]

export { render, staticRenderFns }