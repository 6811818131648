var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": _vm.isAdd ? 'Add EP Applicant' : 'Edit EP Applicant',
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "1100px",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "top"
    }
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Name (same as IC)",
      "prop": "fullName",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Name"
    },
    model: {
      value: _vm.dataForm.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fullName", $$v);
      },
      expression: "dataForm.fullName"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Nationality",
      "prop": "nationality",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Nationality"
    },
    model: {
      value: _vm.dataForm.nationality,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "nationality", $$v);
      },
      expression: "dataForm.nationality"
    }
  }, _vm._l(_vm.nationalityList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification Type",
      "prop": "identificationType",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select identificationType"
    },
    model: {
      value: _vm.dataForm.identificationType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "identificationType", $$v);
      },
      expression: "dataForm.identificationType"
    }
  }, _vm._l(_vm.identificationTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Identification No.",
      "prop": "identificationNo",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Identification No.",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.identificationNo,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "identificationNo", $$v);
      },
      expression: "dataForm.identificationNo"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Mobile",
      "prop": "phone.code",
      "rules": [{
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        validator: _vm.validatePhone,
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "input-with-select-xs",
    attrs: {
      "oninput": "value=value.replace(/[^\\d.]/g, '')"
    },
    model: {
      value: _vm.dataForm.phone.code,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.phone, "code", $$v);
      },
      expression: "dataForm.phone.code"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.phone.prefix,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.phone, "prefix", $$v);
      },
      expression: "dataForm.phone.prefix"
    }
  }, _vm._l(_vm.prefixOptions, function (single) {
    return _c('el-option', {
      key: single.title,
      attrs: {
        "value": single.value
      }
    }, [_vm._v(" " + _vm._s(single.value + " " + single.title) + " ")]);
  }), 1)], 1)], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Email",
      "prop": "email",
      "rules": [{
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Email"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Residential address",
      "prop": "residentialAddress",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Residential address"
    },
    model: {
      value: _vm.dataForm.residentialAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "residentialAddress", $$v);
      },
      expression: "dataForm.residentialAddress"
    }
  })], 1)], 1), _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Birth Date",
      "prop": "birthDate",
      "rules": [{
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }, {
        validator: _vm.checkAge,
        trigger: 'submit'
      }]
    }
  }, [_c('el-date-picker', {
    staticClass: "fluid-width",
    attrs: {
      "type": "date",
      "placeholder": "Birth Date",
      "value-format": "yyyy-MM-dd"
    },
    model: {
      value: _vm.dataForm.birthDate,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "birthDate", $$v);
      },
      expression: "dataForm.birthDate"
    }
  })], 1)], 1)], 1), _c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Does the individual hold share interest of any entity in PRC inland?",
      "prop": "isHoldShareOfPrcInland",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.isHoldShareOfPrcInland,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "isHoldShareOfPrcInland", $$v);
      },
      expression: "dataForm.isHoldShareOfPrcInland"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Yes",
      "value": 1
    }
  }), _c('el-option', {
    attrs: {
      "label": "No",
      "value": 0
    }
  })], 1)], 1)], 1), _vm.dataForm.isHoldShareOfPrcInland ? _c('el-col', {
    attrs: {
      "span": 12
    }
  }, [_c('el-form-item', {
    staticClass: "fluid-width",
    attrs: {
      "label": "Please provide the name of RPC entity (1 entity is required)",
      "prop": "holdShareOfPrcEntity",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Please enter"
    },
    model: {
      value: _vm.dataForm.holdShareOfPrcEntity,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "holdShareOfPrcEntity", $$v);
      },
      expression: "dataForm.holdShareOfPrcEntity"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }