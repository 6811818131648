var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1200px",
      "title": "Ownership Role History",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "createAt",
      "header-align": "center",
      "align": "center",
      "label": "Handling Time",
      "formatter": _vm.localDateFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "align": "center",
      "header-align": "center",
      "label": "From"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.from ? _c('p', [_vm._v(_vm._s(scope.row.from.nickname || scope.row.from.username))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "To"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.to ? _c('p', [_vm._v(_vm._s(scope.row.to.nickname || scope.row.to.username))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "type",
      "header-align": "center",
      "align": "center",
      "label": "Type",
      "formatter": _vm.ownershipTypeFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "ownershipRole",
      "header-align": "center",
      "align": "center",
      "label": "Ownership Role",
      "formatter": _vm.ownershipRoleFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Operator"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', [_vm._v(_vm._s(scope.row.operator.nickname || scope.row.operator.username))])];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }