var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('el-dialog', {
    attrs: {
      "title": "Initiate Refund Application",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "1200px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Choice of service(s) "
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v("Customized")])]), _c('el-form-item', {
    attrs: {
      "label": "Country"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.country))])]), _c('el-form-item', {
    attrs: {
      "label": "Application No"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.applymentNo))])]), _c('el-form-item', {
    attrs: {
      "label": "Applicant"
    }
  }, [_c('span', {
    staticClass: "item-text"
  }, [_vm._v(_vm._s(_vm.detail.applicantName))])]), _c('el-form-item', {
    attrs: {
      "label": "Select refund order"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "value-key": "orderId",
      "multiple": "",
      "placeholder": "Please select"
    },
    model: {
      value: _vm.dataForm.refundInfoList,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "refundInfoList", $$v);
      },
      expression: "dataForm.refundInfoList"
    }
  }, _vm._l(_vm.refundOrderList, function (item, index) {
    return _c('el-option', {
      key: "refundOrderList".concat(index),
      attrs: {
        "label": item.subTotalDesp,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": ""
    }
  }, [_vm.dataForm.refundInfoList.length > 0 ? _c('el-table', {
    key: Math.random(),
    ref: "multipleTable",
    attrs: {
      "data": _vm.dataForm.refundInfoList
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "Description",
      "prop": "subTotalDesp",
      "header-align": "center",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Order Amount",
      "prop": "subTotalPrice",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          staticClass: "fs14"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.subTotalPrice)))])];
      }
    }], null, false, 1147758803)
  }), _c('el-table-column', {
    attrs: {
      "label": "Acra Fee",
      "prop": "refundDetail.acraFee",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          staticClass: "fs14 price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.refundDetail.acraFee)))])];
      }
    }], null, false, 1441750035)
  }), _c('el-table-column', {
    attrs: {
      "label": "Handling Fee",
      "prop": "refundDetail.handlingFee",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('p', {
          staticClass: "fs14 price"
        }, [_vm._v(_vm._s(_vm._f("thousands")(scope.row.refundDetail.handlingFee)))])];
      }
    }], null, false, 2687379245)
  }), _c('el-table-column', {
    attrs: {
      "label": "Remark",
      "prop": "remark",
      "header-align": "center",
      "align": "center"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "attachments",
      "header-align": "center",
      "align": "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.attachmentUrls, function (item, idx) {
          return _c('p', {
            key: "attachmentUrls".concat(idx),
            staticClass: "fs12 m0"
          }, [_c('a', {
            attrs: {
              "href": item.url,
              "target": "_blank"
            }
          }, [_vm._v(_vm._s(item.name))])]);
        });
      }
    }], null, false, 999088070)
  }), _c('el-table-column', {
    attrs: {
      "label": "Action",
      "header-align": "center",
      "align": "center",
      "width": "100px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.editBrief(scope.row, scope.$index);
            }
          }
        }, [_vm._v("Edit")])];
      }
    }], null, false, 1799683865)
  })], 1) : _vm._e()], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1), _c('el-dialog', {
    attrs: {
      "title": "Fill In The Refund Amount",
      "close-on-click-modal": false,
      "visible": _vm.visibleEdit
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visibleEdit = $event;
      }
    }
  }, [_c('el-form', {
    ref: "editForm",
    attrs: {
      "model": _vm.editForm,
      "autocomplete": "off",
      "label-width": "150px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Acra Fee",
      "prop": "refundDetail.acraFee"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": "Acra Fee"
    },
    model: {
      value: _vm.editForm.refundDetail.acraFee,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm.refundDetail, "acraFee", $$v);
      },
      expression: "editForm.refundDetail.acraFee"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Handling Fee",
      "prop": "refundDetail.handlingFee"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "number",
      "placeholder": "Handling Fee"
    },
    model: {
      value: _vm.editForm.refundDetail.handlingFee,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm.refundDetail, "handlingFee", $$v);
      },
      expression: "editForm.refundDetail.handlingFee"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.editForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "remark", $$v);
      },
      expression: "editForm.remark"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.editForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.editForm, "attachmentUrls", $$v);
      },
      expression: "editForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.editFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }