var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "assets-file-picker"
  }, [_c('div', {
    staticClass: "file-preview"
  }, [_c('div', {
    staticClass: "right",
    on: {
      "click": function click($event) {
        return _vm.openUpload();
      }
    }
  }, [_vm._t("default", function () {
    return [_c('el-button', [_vm._v("Upload")])];
  })], 2), _vm.value && _vm.value.length > 0 ? _c('div', {
    staticClass: "left mr20"
  }, [_c('div', {
    staticClass: "preview-file"
  }, _vm._l(_vm.value, function (item, idx) {
    return _c('div', {
      key: item.url,
      staticClass: "preview-file-item"
    }, [_c('div', {
      staticClass: "name"
    }, [_c('a', {
      attrs: {
        "href": item.url,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "btn",
      on: {
        "click": function click($event) {
          return _vm.handleDelete(idx);
        }
      }
    }, [_c('i', {
      staticClass: "el-icon-close"
    })])]);
  }), 0)]) : _vm._e()]), _c('input', {
    staticClass: "hidden",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.value
    }
  }), _vm.uploadVisible ? _c('upload', {
    ref: "Upload",
    attrs: {
      "accept": _vm.accept
    },
    on: {
      "uploadFinish": _vm.handleFinish
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }