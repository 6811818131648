var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Fee Letter")]), (_vm.detail.applymentStatus === 'INQUIRING' || _vm.detail.applymentStatus === 'QUOTING' || _vm.detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') && _vm.isApplymentAuth('quotation:coordinate') ? _c('el-button', {
    on: {
      "click": _vm.dataFormSave
    }
  }, [_vm._v(" Save ")]) : _vm._e()], 1), _c('el-form', {
    ref: "dataForm",
    staticClass: "feeLetterForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "top"
    }
  }, [_c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("1. About This Fee Letter 关于本收费协议")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.1 We thank you for giving us the opportunity to provide our services. This letter explains our service scope and fee information that apply when you use the comprehensive corporate services provided by us. 感谢您选择我们的服务。本协议解释了您使用我们提供的综合企业服务时适用的服务范围和费用信息。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.1 We thank you for giving us the opportunity to provide our services. This letter explains our service scope and fee information that apply when you use the fund admin services and other add-on services provided by us. 感谢您选择我们的服务。本协议解释了您使用我们提供的基金托管及其他附加服务时适用的服务范围和费用信息。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.2 Unless otherwise mentioned in this letter, all terms and conditions are also subject to the Service Agreement that will be acknowledged by you when you use our comprehensive corporate services. 除本协议另有说明外，所有条款和条件均受您在使用我们的综合企业服务时确认的《服务协议》的约束。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.2 Unless otherwise mentioned in this letter, all terms and conditions are also subject to the Service Agreement that will be acknowledged by you when you use our fund admin services and other add-on services. 除本协议另有说明外，所有条款和条件均受您在使用我们的基金托管及其他附加服务时确认的《服务协议》的约束。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "newTransactionNumber",
    attrs: {
      "label": "1.3 This letter is subject to engagement letter with transaction number of [".concat(_vm.dataForm.newTransactionNumber, "], and will replace all previous terms and conditions relating to the transaction(s) with the transaction number of [").concat(_vm.dataForm.transactionNumber, "].\n              \u672C\u534F\u8BAE\u57FA\u4E8E\u4EA4\u6613\u4EE3\u7801\u4E3A[").concat(_vm.dataForm.newTransactionNumber, "]\u7684\u4E1A\u52A1\u7EA6\u5B9A\u4E66\uFF0C\u672C\u534F\u8BAE\u5C06\u53D6\u4EE3\u6240\u6709\u5148\u524D\u4E0E\u4EA4\u6613\u4EE3\u7801\u4E3A[").concat(_vm.dataForm.transactionNumber, "]\u7684\u4EA4\u6613\u76F8\u5173\u7684\u6761\u6B3E\u548C\u6761\u4EF6\u3002"),
      "prop": "newTransactionNumber",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "基于交易代码"
    },
    model: {
      value: _vm.dataForm.newTransactionNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "newTransactionNumber", $$v);
      },
      expression: "dataForm.newTransactionNumber"
    }
  })], 1), _c('el-form-item', {
    ref: "transactionNumber",
    attrs: {
      "prop": "transactionNumber",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "先前交易代码"
    },
    model: {
      value: _vm.dataForm.transactionNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "transactionNumber", $$v);
      },
      expression: "dataForm.transactionNumber"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "companyName",
    attrs: {
      "label": "1.4 By the \"we\", \"us\", \u201Cour\u201D, \"ours\" we mean ".concat(_vm.dataForm.companyName, ", including its affiliates and its assigned\n              service provider(s). \u534F\u8BAE\u4E2D\u63D0\u53CA\u7684\u201C\u6211\u65B9\u201D\u3001\u201C\u6211\u4EEC\u201D\u3001\u201C\u6211\u65B9\u7684\u201D\u3001\u201C\u6211\u4EEC\u7684\u201D\u662F\u6307 ").concat(_vm.dataForm.companyName, "\n              (").concat(_vm.dataForm.companyNameCn, ")\uFF0C\u5E76\u5305\u62EC\u5176\u9644\u5C5E\u516C\u53F8\u53CA\u5176\u6307\u5B9A\u7684\u670D\u52A1\u63D0\u4F9B\u5546\u3002"),
      "prop": "companyName",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "选择公司"
    },
    on: {
      "change": function change($event) {
        _vm.dataForm.companyNameCn = _vm.companyList.find(function (item) {
          return item.title == _vm.dataForm.companyName;
        }).titleCn || '';
      }
    },
    model: {
      value: _vm.dataForm.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "companyName", $$v);
      },
      expression: "dataForm.companyName"
    }
  }, _vm._l(_vm.companyList, function (com) {
    return _c('el-option', {
      key: com.id,
      attrs: {
        "label": com.title,
        "value": com.title
      }
    });
  }), 1)], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.5 This letter is by and between the Company to be incorporated/transferred/customized per the request(s) through our Electronic Services (hereinafter called “the Company”), and us. Each shall be referred to as a “Party” and collectively referred to as the “Parties”. 本协议的签署方为: 通过我们的电子服务系统申请成立/转让/定制化的公司（以下简称“贵公司”）和我方签署。以下的单指某方简称“一方”，以下的统称简称“各方”。 ")]) : _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', [_c('el-form-item', {
    ref: "fundNameList",
    attrs: {
      "label": "1.5 This letter is by and between the fund(s) named [".concat(_vm.dataForm.fundNameList, "] per the request(s) through our Electronic Services\n              (hereinafter called \u201Cthe Fund(s)\u201D), and us. Each shall be referred to as a \u201CParty\u201D and collectively referred to as the \u201CParties\u201D.\n              \u672C\u534F\u8BAE\u7684\u7B7E\u7F72\u65B9\u4E3A:\n              \u901A\u8FC7\u6211\u4EEC\u7684\u7535\u5B50\u670D\u52A1\u7CFB\u7EDF\u63D0\u4EA4\u7533\u8BF7\u7684\u57FA\u91D1[").concat(_vm.dataForm.fundNameList, "](\u4EE5\u4E0B\u7B80\u79F0\u201C\u8D35\u57FA\u91D1\u201D)\u548C\u6211\u65B9\u7B7E\u7F72\u3002\u4EE5\u4E0B\u7684\u5355\u6307\u67D0\u65B9\u7B80\u79F0\u201C\u4E00\u65B9\u201D\uFF0C\u4EE5\u4E0B\u7684\u7EDF\u79F0\u7B80\u79F0\u201C\u5404\u65B9\u201D\u3002"),
      "prop": "fundNameList",
      "rules": {
        required: true,
        message: 'Please select',
        trigger: 'submit'
      }
    }
  }, [_c('el-select', {
    staticClass: "wd500",
    attrs: {
      "multiple": "",
      "placeholder": "选择基金"
    },
    model: {
      value: _vm.dataForm.fundNameList,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "fundNameList", $$v);
      },
      expression: "dataForm.fundNameList"
    }
  }, _vm._l(_vm.detail.fundInfoList, function (fun) {
    return _c('el-option', {
      key: fun.id,
      attrs: {
        "label": fun.fundName,
        "value": fun.fundName
      }
    });
  }), 1)], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 1.6 This letter shall be governed by and construed in accordance with the laws of the Republic of Singapore. The parties shall submit to the exclusive jurisdiction of the courts of Singapore. 本协议的司法管辖以及司法解释适用新加坡共和国法律。各方应服从新加坡法院的仲裁及管辖。 ")])])])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('div', [_vm.detail.businessGenre == 'COMPANY' ? _c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("2. Introduction 引言")]) : _vm.detail.businessGenre == 'FUND' ? _c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("2. General Terms and Conditions 一般条款和条件")]) : _vm._e(), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_vm.detail.businessGenre == 'COMPANY' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "introduction",
    attrs: {
      "label": "2.1 Based on our previous communication with you, we understand that: ",
      "prop": "introduction",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introduction,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introduction", $$v);
      },
      expression: "dataForm.introduction"
    }
  })], 1), _c('el-form-item', {
    ref: "introductionCn",
    attrs: {
      "label": "2.1 根据之前的沟通，我们理解：",
      "prop": "introductionCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introductionCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introductionCn", $$v);
      },
      expression: "dataForm.introductionCn"
    }
  })], 1)], 1) : _vm.detail.businessGenre == 'FUND' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "introduction",
    attrs: {
      "label": "WHEREAS, the Fund(s) to be managed by ".concat(_vm.detail.questionnaire.proposedCompanyName, " (the \u201CFund Manager\u201D), and the Fund(s) desires to appoint us  Administrator of the Fund(s). NOW, THEREFORE, in consideration of the mutual promises and agreements contained herein, the parties hereby agree as follows:"),
      "prop": "introduction",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introduction,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introduction", $$v);
      },
      expression: "dataForm.introduction"
    }
  })], 1), _c('el-form-item', {
    ref: "introductionCn",
    attrs: {
      "label": "\u9274\u4E8E\u8D35\u57FA\u91D1\u7531".concat(_vm.detail.questionnaire.proposedCompanyName, "(\u4EE5\u4E0B\u7B80\u79F0\u201C\u8D35\u57FA\u91D1\u7BA1\u7406\u516C\u53F8\u201D)\u7BA1\u7406\uFF0C\u4EE5\u53CA\u8D35\u57FA\u91D1\u5E0C\u671B\u4EFB\u547D\u6211\u65B9\u4E3A\u57FA\u91D1\u6258\u7BA1\u4EBA\u3002 \u56E0\u6B64\uFF0C\u73B0\u4F9D\u636E\u4EE5\u4E0B\u5305\u542B\u7684\u76F8\u4E92\u627F\u8BFA\u548C\u534F\u8BAE\uFF0C\u5404\u65B9\u7279\u6B64\u540C\u610F\u5982\u4E0B\uFF1A"),
      "prop": "introductionCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.introductionCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introductionCn", $$v);
      },
      expression: "dataForm.introductionCn"
    }
  })], 1)], 1) : _vm._e()])])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("3. Service Scope and Fees 服务范围以及报价")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "principal",
    attrs: {
      "label": "3.1 Based on our understanding of the ".concat(_vm.detail.businessGenre == 'COMPANY' ? 'Company' : 'Fund(s)', " as set out in ").concat(_vm.detail.businessGenre == 'COMPANY' ? 'section 2' : 'Clause 1, 2 and 3.1.1', " of this letter, we will assign [").concat(_vm.dataForm.principal, "] as the person-in-charge for the provision of services specified as below:"),
      "prop": "principal",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(EN)"
    },
    model: {
      value: _vm.dataForm.principal,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "principal", $$v);
      },
      expression: "dataForm.principal"
    }
  })], 1), _c('el-form-item', {
    ref: "serviceScopeAndFeesUrl.url",
    attrs: {
      "prop": "serviceScopeAndFeesUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.serviceScopeAndFeesUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "serviceScopeAndFeesUrl", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.serviceScopeAndFeesUrl.url,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.serviceScopeAndFeesUrl, "url", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesUrl.url"
    }
  })], 1), _c('el-form-item', {
    ref: "principalCn",
    attrs: {
      "label": "3.1 \u9274\u4E8E\u672C\u534F\u8BAE".concat(_vm.detail.businessGenre == 'COMPANY' ? '第2条' : '第1条、第2条和第3.1.1条', "\u63D0\u53CA\u7684\u6211\u4EEC\u5BF9\u8D35").concat(_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金', "\u7684\u7406\u89E3\uFF0C\u6211\u4EEC\u5C06\u5B89\u6392[").concat(_vm.dataForm.principalCn, "]\u4E3A\u9879\u76EE\u7684\u8D1F\u8D23\u4EBA\uFF0C\u6211\u4EEC\u5BF9\u8D35").concat(_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金', "\u7684\u6536\u8D39\u62A5\u4EF7\u5982\u4E0B:"),
      "prop": "principalCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(CN)"
    },
    model: {
      value: _vm.dataForm.principalCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "principalCn", $$v);
      },
      expression: "dataForm.principalCn"
    }
  })], 1), _c('el-form-item', {
    ref: "serviceScopeAndFeesCnUrl.url",
    attrs: {
      "prop": "serviceScopeAndFeesCnUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.serviceScopeAndFeesCnUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "serviceScopeAndFeesCnUrl", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesCnUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.serviceScopeAndFeesCnUrl.url,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.serviceScopeAndFeesCnUrl, "url", $$v);
      },
      expression: "dataForm.serviceScopeAndFeesCnUrl.url"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pb30"
  }, [_vm.detail.businessGenre == 'COMPANY' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" Our above quotation is based on our standard fees, our understanding of the Company's business and long-term needs, and the discretionary discounts given to future long-term relationship. 我们的上述报价是基于我们的标准费用1 、我们了解的公司情况及长期需求、以及未来长期合作关系给予的酌情折扣得出。 ")]) : _vm._e(), _vm.detail.businessGenre == 'FUND' ? _c('p', {
    staticClass: "lh22"
  }, [_vm._v(" Our above quotation is based on our standard fees, our understanding of the Fund(s)'s business and long-term needs, and the discretionary discounts given to future long-term relationship. 我们的上述报价是基于我们的标准费用1 、我们了解的贵基金的运营情况及长期需求、以及未来长期合作关系给予的酌情折扣得出。 ")]) : _vm._e()]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "notes",
    attrs: {
      "label": "Notes:",
      "prop": "notes",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "notes", $$v);
      },
      expression: "dataForm.notes"
    }
  })], 1), _c('el-form-item', {
    ref: "notesCn",
    attrs: {
      "label": "说明:",
      "prop": "notesCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-editor', {
    staticClass: "editor",
    attrs: {
      "height": 400
    },
    model: {
      value: _vm.dataForm.notesCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "notesCn", $$v);
      },
      expression: "dataForm.notesCn"
    }
  })], 1)], 1)])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("4. Fee Schedule 收费安排")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "feeDate",
    attrs: {
      "label": "4.1 Our fee set out in section 3 shall be billed on [".concat(_vm.dataForm.feeDate, "] basis and is payable in advance. \n              The ").concat(_vm.detail.businessGenre == 'COMPANY' ? 'Company' : 'Fund(s)', " shall make fully payment within 14 days from the date of our invoice. If you agree to our above-mentioned fee quotation, \n              our fee schedule is as follows: \n              \u6211\u4EEC\u7684\u6536\u8D39\u5B89\u6392\u4E3A\u9884\u6536\u6536\u8D39\u3002\u5176\u4E2D\uFF0C\u5E74\u5EA6\u670D\u52A1\u8D39\u7528\u6309\u5E74\u9884\u6536\uFF1B\u5B63\u5EA6\u670D\u52A1\u8D39\u7528\u6309\u5B63\u5EA6\u9884\u6536\uFF1B\u6309\u9700\u6536\u8D39\u7684\u670D\u52A1\u4E8B\u9879\u6309\u5F00\u5177\u7684\u53D1\u7968\u91D1\u989D\u9884\u6536\u3002\n              \u8D35").concat(_vm.detail.businessGenre == 'COMPANY' ? '公司' : '基金', "\u5E94\u81EA\u6536\u5230\u53D1\u7968\u540E\u768414\u65E5\u5185\u5B8C\u6210\u4ED8\u6B3E\u3002\u82E5\u60A8\u540C\u610F\u6211\u4EEC\u7684\u4E0A\u8FF0\u6536\u8D39\u62A5\u4EF7\uFF0C\u76F8\u5173\u7684\u6536\u8D39\uFF08\u672A\u5305\u62EC\u6309\u9700\u6536\u8D39\u7684\u670D\u52A1\u4E8B\u9879\uFF09\u5177\u4F53\u5B89\u6392\u5982\u4E0B:"),
      "prop": "feeDate",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "Monthly/Quaterly/Yearly"
    },
    model: {
      value: _vm.dataForm.feeDate,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "feeDate", $$v);
      },
      expression: "dataForm.feeDate"
    }
  })], 1), _c('el-form-item', {
    ref: "feeScheduleList",
    staticClass: "pt10",
    attrs: {
      "prop": "feeScheduleList",
      "rules": {
        type: 'array',
        required: true,
        message: 'Please add',
        trigger: 'submit'
      }
    }
  }, [(_vm.detail.applymentStatus === 'INQUIRING' || _vm.detail.applymentStatus === 'QUOTING' || _vm.detail.applymentStatus === 'QUOTATION_AUDIT_FAIL') && _vm.isApplymentAuth('quotation:coordinate') ? _c('el-button', {
    on: {
      "click": _vm.editOfferIndexModal
    }
  }, [_vm._v(" Edit Quotation ")]) : _vm._e(), _c('div', {
    staticClass: "pt10"
  }, [_c('quotation-detail', {
    attrs: {
      "quotationList": _vm.detail.quotationList,
      "applymentStatus": _vm.detail.applymentStatus
    }
  })], 1), _c('el-checkbox-group', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.feeScheduleList,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "feeScheduleList", $$v);
      },
      expression: "dataForm.feeScheduleList"
    }
  }, [_vm._v(" " + _vm._s(_vm.dataForm.feeScheduleList = _vm.detail.quotationList) + " ")])], 1)], 1)])])]), _c('div', {
    staticClass: "pb50 desp-items-group"
  }, [_c('p', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("5. Ending 结语")]), _c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-self"
  }, [_c('div', {
    staticClass: "pb30"
  }, [_c('p', {
    staticClass: "lh22"
  }, [_vm._v(" 5.1 We adhere to the work philosophy of \"professional, concentrate, convenience\", and is committed to providing professional services with quality and efficiency. We target to form a good reputation through our professional services provided, and we welcome more entrepreneurs who are interested in overseas development to join our platform to “Develop Together, and Grow Together”. 我们秉承“专业、专注、专心”的工作理念，将持续为企业提供便捷高效的专业服务。我们希望通过我们专业的服务形成良好的口碑、并结交更多有志于海外发展的企业家，共同发展、共同进步。 ")])]), _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "functionary",
    attrs: {
      "label": "5.2 If you have further questions, please contact: [".concat(_vm.dataForm.functionary, "] [").concat(_vm.dataForm.email, "]"),
      "prop": "functionary",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(EN)"
    },
    model: {
      value: _vm.dataForm.functionary,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "functionary", $$v);
      },
      expression: "dataForm.functionary"
    }
  })], 1), _c('el-form-item', {
    ref: "email",
    attrs: {
      "prop": "email",
      "rules": [{
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }, {
        type: 'email',
        message: 'Please provide a valid email address',
        trigger: 'submit'
      }]
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人电邮地址"
    },
    model: {
      value: _vm.dataForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "email", $$v);
      },
      expression: "dataForm.email"
    }
  })], 1), _c('el-form-item', {
    ref: "functionaryCn",
    attrs: {
      "label": "5.2 \u82E5\u60A8\u6709\u8FDB\u4E00\u6B65\u7684\u7591\u95EE\uFF0C\u8BF7\u8054\u7CFB: [".concat(_vm.dataForm.functionaryCn, "] [").concat(_vm.dataForm.email, "]"),
      "prop": "functionaryCn",
      "rules": {
        required: true,
        message: 'Please enter',
        trigger: 'submit'
      }
    }
  }, [_c('el-input', {
    staticClass: "wd500",
    attrs: {
      "placeholder": "负责人(CN)"
    },
    model: {
      value: _vm.dataForm.functionaryCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "functionaryCn", $$v);
      },
      expression: "dataForm.functionaryCn"
    }
  })], 1)], 1), _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED' ? _c('div', {
    staticClass: "pb30"
  }, [_c('el-form-item', {
    ref: "appendixUrl.url",
    attrs: {
      "label": "Appendix 1 Authorized Persons 附录1 授权名单",
      "prop": "appendixUrl.url",
      "rules": {
        required: true,
        message: 'Please upload',
        trigger: 'submit'
      }
    }
  }, [_c('el-image-uploader', {
    model: {
      value: _vm.dataForm.appendixUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "appendixUrl", $$v);
      },
      expression: "dataForm.appendixUrl"
    }
  }), _c('el-input', {
    staticStyle: {
      "display": "none"
    },
    model: {
      value: _vm.dataForm.appendixUrl.url,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.appendixUrl, "url", $$v);
      },
      expression: "dataForm.appendixUrl.url"
    }
  })], 1)], 1) : _vm._e()])])])]), _c('offer-index', {
    ref: "offerIndexModal",
    attrs: {
      "detail": _vm.detail,
      "moduleServiceList": _vm.moduleServiceList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }