var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "new-plan"
  }, [_c('add-or-update')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }