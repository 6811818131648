var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', {
    staticClass: "mod-user"
  }, [_c('div', {
    staticClass: "mb20"
  }, [_vm.isAuth('engagement:save') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addOrUpdateHandle();
      }
    }
  }, [_vm._v("Add Client")]) : _vm._e()], 1), _c('div', [_c('el-form', {
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.getDataList(1);
      }
    }
  }, [_c('el-form-item', [_c('el-input', {
    staticClass: "wd416 input-with-select-lg",
    attrs: {
      "placeholder": _vm.kwPlaceHolder,
      "oninput": _vm.isEn,
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "keyword", $$v);
      },
      expression: "dataForm.keyword"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend",
      "placeholder": "please select"
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.kwType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "kwType", $$v);
      },
      expression: "dataForm.kwType"
    }
  }, _vm._l(_vm.kwTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "In-charge",
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getInChargeRemote,
      "loading": _vm.inChargeLoading
    },
    on: {
      "clear": function clear($event) {
        return _vm.getInChargeRemote('');
      },
      "focus": function focus($event) {
        return _vm.getInChargeRemote('');
      }
    },
    model: {
      value: _vm.dataForm.inChargeUserName,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "inChargeUserName", $$v);
      },
      expression: "dataForm.inChargeUserName"
    }
  }, _vm._l(_vm.inChargeList, function (item, pm) {
    return _c('el-option', {
      key: "".concat(item.id).concat(pm),
      attrs: {
        "label": "".concat(item.nickname, "-").concat(item.staffNo, "-").concat(item.position),
        "value": item.username
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Status"
    },
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, _vm._l(_vm.engagementStatusList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-date-picker-range', {
    attrs: {
      "startDate": _vm.dataForm.coveredPeriodStart,
      "endDate": _vm.dataForm.coveredPeriodEnd,
      "startPlaceholder": "Covered (Start Date)",
      "endPlaceholder": "Covered (End Date)"
    },
    on: {
      "startSelectChange": function startSelectChange(val) {
        _vm.dataForm.coveredPeriodStart = val;
      },
      "endSelectChange": function endSelectChange(val) {
        _vm.dataForm.coveredPeriodEnd = val;
      }
    }
  })], 1), _c('el-form-item', [_c('el-select-all', {
    attrs: {
      "width": 200,
      "optionList": _vm.monthOptions,
      "placeholder": "FY end"
    },
    on: {
      "elSelectAllChange": function elSelectAllChange(list) {
        _vm.dataForm.fyEndMonth = list;
      }
    }
  })], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "Entity Type",
      "filterable": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.companyType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "companyType", $$v);
      },
      expression: "dataForm.companyType"
    }
  }, _vm._l(_vm.engagementCompanyTypeList, function (item) {
    return _c('el-option', {
      key: item.title,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "Entity Country",
      "filterable": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.companyCountry,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "companyCountry", $$v);
      },
      expression: "dataForm.companyCountry"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getUenRemote,
      "loading": _vm.uenLoading,
      "placeholder": "UEN"
    },
    on: {
      "clear": function clear($event) {
        return _vm.getUenRemote('');
      },
      "focus": function focus($event) {
        return _vm.getUenRemote('');
      }
    },
    model: {
      value: _vm.dataForm.uen,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "uen", $$v);
      },
      expression: "dataForm.uen"
    }
  }, _vm._l(_vm.uenList, function (item, index) {
    return _c('el-option', {
      key: "".concat(item.uen).concat(index),
      attrs: {
        "label": item.uen,
        "value": item.uen
      }
    });
  }), 1)], 1), _c('el-form-item', {
    staticClass: "pt1"
  }, [_c('el-select-all', {
    staticClass: "controlLeft",
    attrs: {
      "width": 202,
      "optionList": _vm.controlTypeObjList,
      "placeholder": "Service Type"
    },
    on: {
      "elSelectAllChange": function elSelectAllChange(list) {
        _vm.dataForm.controlType = list;
      }
    }
  }), _c('el-select', {
    staticClass: "wd180 controlCentre",
    attrs: {
      "placeholder": "Select Status Or Blank"
    },
    on: {
      "change": _vm.isBlankChange
    },
    model: {
      value: _vm.dataForm.isBlank,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "isBlank", $$v);
      },
      expression: "dataForm.isBlank"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Select Status",
      "value": 0
    }
  }), _c('el-option', {
    attrs: {
      "label": "Select Blank",
      "value": 1
    }
  })], 1), _vm.dataForm.isBlank == 0 ? [_c('el-select-all', {
    staticClass: "controlCentre",
    attrs: {
      "width": 200,
      "optionList": _vm.engagementControlStatusList,
      "placeholder": "Detailed Status"
    },
    on: {
      "elSelectAllChange": function elSelectAllChange(list) {
        _vm.dataForm.controlStatus = list;
      }
    }
  }), _c('el-date-picker-range', {
    staticClass: "controlRight",
    attrs: {
      "startDate": _vm.dataForm.controlDeadlineStart,
      "endDate": _vm.dataForm.controlDeadlineEnd,
      "width": 150
    },
    on: {
      "startSelectChange": function startSelectChange(val) {
        _vm.dataForm.controlDeadlineStart = val;
      },
      "endSelectChange": function endSelectChange(val) {
        _vm.dataForm.controlDeadlineEnd = val;
      }
    }
  })] : _vm._e()], 2), _c('el-form-item', [_c('el-date-picker-range', {
    attrs: {
      "startDate": _vm.dataForm.incorpDateStart,
      "endDate": _vm.dataForm.incorpDateEnd,
      "startPlaceholder": "Incorp. (Start Date)",
      "endPlaceholder": "Incorp. (End Date)"
    },
    on: {
      "startSelectChange": function startSelectChange(val) {
        _vm.dataForm.incorpDateStart = val;
      },
      "endSelectChange": function endSelectChange(val) {
        _vm.dataForm.incorpDateEnd = val;
      }
    }
  })], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1)], 1)], 1), _c('div', {
    staticClass: "mb20"
  }, [_vm.isAuth('engagement:export') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "small",
      "icon": "el-icon-download",
      "disabled": _vm.totalCount == 0
    },
    on: {
      "click": _vm.handleExportExcel
    }
  }, [_vm._v(" Export Excel ")]) : _vm._e()], 1), _c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "640",
      "data": _vm.dataList,
      "default-sort": {
        prop: 'clientCode',
        order: 'descending'
      }
    },
    on: {
      "sort-change": _vm.tableSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "clientCode",
      "header-align": "center",
      "align": "center",
      "label": "Client Code",
      "fixed": "left",
      "width": "126",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "clientType",
      "header-align": "center",
      "align": "center",
      "label": "Type",
      "fixed": "left",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "relatedUserTransactions",
      "header-align": "center",
      "align": "center",
      "label": "Transaction No.",
      "fixed": "left",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.relatedUserTransactions ? _vm._l(scope.row.relatedUserTransactions, function (item, idx) {
          return _c('span', {
            key: "transactionNo".concat(idx).concat(item.transactionNo)
          }, [_vm._v(" " + _vm._s(idx == 0 ? "" : ", ") + " " + _vm._s(item.transactionNo) + " ")]);
        }) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "inChargeUser",
      "header-align": "center",
      "align": "center",
      "label": "In-charge",
      "fixed": "left",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.relatedUserTransactions ? _vm._l(scope.row.relatedUserTransactions, function (item, idx) {
          return _c('p', {
            key: "username".concat(idx).concat(item.transactionNo)
          }, _vm._l(item.inChargeUsers, function (child, xv) {
            return _c('span', {
              key: "inChargeUsers".concat(idx).concat(xv).concat(child.id)
            }, [_vm._v(" " + _vm._s(xv == 0 ? "" : ", ") + " " + _vm._s(child.nickname) + " ")]);
          }), 0);
        }) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "uen",
      "header-align": "center",
      "align": "center",
      "label": "UEN",
      "fixed": "left",
      "width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "companyName",
      "header-align": "center",
      "align": "center",
      "label": "Entity Name",
      "fixed": "left",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "coveredPeriodStart",
      "header-align": "center",
      "align": "center",
      "label": "Covered Period",
      "fixed": "left",
      "width": "154",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.coveredPeriodStart ? _c('p', [_vm._v(_vm._s(scope.row.coveredPeriodStart))]) : _vm._e(), scope.row.coveredPeriodEnd ? _c('p', [_vm._v(_vm._s(scope.row.coveredPeriodEnd))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "formatter": _vm.statusFormatter,
      "fixed": "left",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "previousCompanyName",
      "header-align": "center",
      "align": "center",
      "label": "Previous Entity Name",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "incorpDate",
      "header-align": "center",
      "align": "center",
      "label": "Incorp. date",
      "width": "150",
      "sortable": "custom"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.incorpDate ? _c('el-date-picker', {
          staticClass: "fluid-width datePicker",
          attrs: {
            "type": "date",
            "format": "dd [".concat(_vm.monthList[parseInt(scope.row.incorpDate.split('-')[1]) - 1], "] yyyy"),
            "value-format": "yyyy-MM-dd",
            "clearable": false,
            "readonly": true,
            "disabled": ""
          },
          model: {
            value: scope.row.incorpDate,
            callback: function callback($$v) {
              _vm.$set(scope.row, "incorpDate", $$v);
            },
            expression: "scope.row.incorpDate"
          }
        }) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "fyPeriod",
      "header-align": "center",
      "align": "center",
      "label": "FY Period",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "fyEnd",
      "header-align": "center",
      "align": "center",
      "label": "FY End",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.fyEnd ? _c('el-date-picker', {
          staticClass: "fluid-width datePicker",
          attrs: {
            "type": "date",
            "format": "dd [".concat(_vm.monthList[parseInt(scope.row.fyEnd.split('-')[0]) - 1], "]"),
            "value-format": "MM-dd",
            "clearable": false,
            "readonly": true,
            "disabled": ""
          },
          model: {
            value: scope.row.fyEnd,
            callback: function callback($$v) {
              _vm.$set(scope.row, "fyEnd", $$v);
            },
            expression: "scope.row.fyEnd"
          }
        }) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "companyCountry",
      "header-align": "center",
      "align": "center",
      "label": "Entity Country",
      "width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "companyType",
      "header-align": "center",
      "align": "center",
      "label": "Entity Type",
      "width": "150",
      "formatter": _vm.companyTypeFormatter
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "controlDataList",
      "header-align": "center",
      "align": "center",
      "label": "Service Type",
      "formatter": _vm.serviceTypeFormat,
      "min-width": "150"
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "AGM/EGM",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'AGM/EGM' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Report Filing",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Report Filing' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "CIT filing",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'CIT filing' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "GST filing",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'GST filing' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Other tax filing",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Other tax filing' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Secretary",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Secretary' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Account",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Account' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Advisory",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Advisory' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "HR",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'HR' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Others",
      "width": "210"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.controlDataList, function (dead) {
          return _c('div', {
            key: "dead".concat(scope.row.id).concat(dead.id)
          }, [dead.controlType && dead.controlType == 'Others' ? _c('p', [_c('span', [_vm._v(_vm._s(dead.deadline))]), _c('span', {
            class: _vm.statusDeadColor(dead.status)
          }, [_vm._v(", " + _vm._s(_vm.statusDeadFormatter(dead.status)))])]) : _vm._e()]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "120",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.isAuth('engagement:update') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.addOrUpdateHandle(scope.row);
            }
          }
        }, [_vm._v("Edit")]) : _vm._e(), _vm.isAuth('engagement:delete') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteHandle(scope.row);
            }
          }
        }, [_vm._v("Delete")]) : _vm._e(), _vm.isAuth('engagement:charge') || _vm.isAuth('engagement:linked') ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.detailHandler(scope.row.id);
            }
          }
        }, [_vm._v(" Detail ")]) : _vm._e(), _vm.isAuth('engagement:copy') && scope.row.status == 'CLOSE' ? _c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.copyFromHandle(scope.row);
            }
          }
        }, [_vm._v(" Copy from ")]) : _vm._e()];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.pageSize,
      "total": _vm.totalCount,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  }), _c('add-or-update', {
    ref: "addOrUpdate",
    on: {
      "refreshDataList": _vm.getDataList
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }