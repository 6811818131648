var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pd26 bg-white border-radius-10"
  }, [_c('div', {
    staticClass: "flex flex-direction-row align-center justify-between"
  }, [_c('h3', {
    staticClass: "home-title"
  }, [_vm._v("Statistics of Transaction Status at Each Stage")]), _c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-download"
    },
    on: {
      "click": _vm.handleExportExcel
    }
  }, [_vm._v("Export Excel")])], 1), _c('p', {
    staticClass: "home-introduce"
  }, [_vm._v("Average over preceding 3 months")]), _c('el-row', {
    staticClass: "mt40"
  }, [_c('el-col', {
    staticClass: "flex flex-direction align-center",
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "wd125 hg125"
  }, [_c('el-progress', {
    staticClass: "ratio-bold",
    attrs: {
      "type": "circle",
      "percentage": _vm.majorStatusRatioFormat('SUBMITTED'),
      "color": "#FF7E69",
      "stroke-width": 12,
      "width": 125
    }
  })], 1), _c('h3', {
    staticClass: "mt26 major-status"
  }, [_vm._v("Submitted")]), _c('p', {
    staticClass: "dot-aurantia"
  }, [_vm._v(_vm._s(_vm.majorStatusFormat("SUBMITTED")))])]), _c('el-col', {
    staticClass: "flex flex-direction align-center",
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "wd125 hg125"
  }, [_c('el-progress', {
    staticClass: "ratio-bold",
    attrs: {
      "type": "circle",
      "percentage": _vm.majorStatusRatioFormat('REVISING'),
      "color": "#0072FF",
      "stroke-width": 12,
      "width": 125
    }
  })], 1), _c('h3', {
    staticClass: "mt26 major-status"
  }, [_vm._v("Revising")]), _c('p', {
    staticClass: "dot-blue"
  }, [_vm._v(_vm._s(_vm.majorStatusFormat("REVISING")))])]), _c('el-col', {
    staticClass: "flex flex-direction align-center",
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "wd125 hg125"
  }, [_c('el-progress', {
    staticClass: "ratio-bold",
    attrs: {
      "type": "circle",
      "percentage": _vm.majorStatusRatioFormat('FINISH'),
      "color": "#52D4FF",
      "stroke-width": 12,
      "width": 125
    }
  })], 1), _c('h3', {
    staticClass: "mt26 major-status"
  }, [_vm._v("Order Completed")]), _c('p', {
    staticClass: "dot-indigo"
  }, [_vm._v(_vm._s(_vm.majorStatusFormat("FINISH")))])])], 1), _c('el-row', {
    staticClass: "mt43 pl46 pr46",
    attrs: {
      "gutter": 70
    }
  }, _vm._l(_vm.statusRatioList, function (item) {
    return _c('el-col', {
      key: 'statusRatioList' + item.applymentStatus,
      staticClass: "mb40",
      attrs: {
        "span": 12
      }
    }, [_c('div', {
      staticClass: "hg70 flex flex-direction-row align-center justify-between"
    }, [_c('h3', {
      staticClass: "fs16 home-title"
    }, [_vm._v(_vm._s(_vm.applymentStatusFormat(item.applymentStatus)))]), _c('p', {
      staticClass: "fs16 home-proportion"
    }, [_vm._v(" " + _vm._s(item.cnt) + " "), _c('span', [_vm._v("/" + _vm._s(_vm.statusRatioSum))])])]), _c('el-progress', {
      attrs: {
        "percentage": parseFloat(item.cnt) && _vm.statusRatioSum ? _vm.div(item.cnt, _vm.statusRatioSum) * 100 : 0,
        "show-text": false,
        "stroke-width": 5,
        "color": "#0072FF"
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }