var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "desp"
  }, [_c('div', {
    staticClass: "desp-header"
  }, [_vm._v("Refund Information")]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Apply Reason")]), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-self highlight"
  }, [_vm._v(_vm._s(_vm.detail.refundDetail.applyReason))]) : _vm._e()])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Amount")]), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Amount")]), _vm.detail.refundDetail && _vm.detail.refundDetail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm.detail.refundDetail.refundDetail.amountSg ? _c('span', {
    staticClass: "price"
  }, [_vm._v(" " + _vm._s(_vm._f("thousands")(_vm.detail.refundDetail.refundDetail.amountSg)) + " ")]) : _vm._e(), _vm.detail.refundDetail.refundDetail.amountCn ? _c('span', {
    staticClass: "price"
  }, [_vm._v(" " + _vm._s(_vm._f("thousandsRMB")(_vm.detail.refundDetail.refundDetail.amountCn)) + " ")]) : _vm._e()]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Acra Fee")]), _vm.detail.refundDetail && _vm.detail.refundDetail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.detail.refundDetail.refundDetail.acraFee)))])]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Handling Fee")]), _vm.detail.refundDetail && _vm.detail.refundDetail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_c('span', {
    staticClass: "price"
  }, [_vm._v(_vm._s(_vm._f("thousands")(_vm.detail.refundDetail.refundDetail.handlingFee)))])]) : _vm._e()])])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Information")]), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Refund Status")]), _vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.refundStatusFormat(_vm.detail.refundDetail.status)) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Refund No")]), _vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.refundDetail.refundNo) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Apply Time")]), _vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.refundDetail.applyTime) + " ")]) : _vm._e()])])], 1)], 1)]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Fail Reason")]), _c('div', {
    staticClass: "desp-item"
  }, [_vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-self highlight"
  }, [_vm._v(_vm._s(_vm.detail.refundDetail.failReason))]) : _vm._e()])]), _c('div', {
    staticClass: "desp-items-group"
  }, [_c('div', {
    staticClass: "desp-items-group-title"
  }, [_vm._v("Operator")]), _c('div', {
    staticClass: "desp-items-wrapper"
  }, [_c('el-row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Refund Teller")]), _vm.detail.refundDetail && _vm.detail.refundDetail.refundingTeller ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.refundDetail.refundingTeller.nickname || _vm.detail.refundDetail.refundingTeller.username) + " ")]) : _vm._e()])]), _c('el-col', {
    attrs: {
      "span": 8
    }
  }, [_c('div', {
    staticClass: "desp-item"
  }, [_c('div', {
    staticClass: "desp-item-label"
  }, [_vm._v("Finish Time")]), _vm.detail.refundDetail ? _c('div', {
    staticClass: "desp-item-content"
  }, [_vm._v(" " + _vm._s(_vm.detail.refundDetail.finishTime) + " ")]) : _vm._e()])])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }