var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.isRouterAlive ? _c('router-view') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }