var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1400px",
      "title": "Status Track",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('div', [_c('el-form', {
    staticClass: "el-form-filter",
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.getDataList(1);
      }
    }
  }, [_c('el-form-item', [_c('el-date-picker', {
    attrs: {
      "value-format": "timestamp",
      "type": "daterange",
      "range-separator": "~",
      "start-placeholder": "Start Date",
      "end-placeholder": "Ending Date"
    },
    on: {
      "change": function change($event) {
        return _vm.getDataList(1);
      }
    },
    model: {
      value: _vm.tradeTimeRange,
      callback: function callback($$v) {
        _vm.tradeTimeRange = $$v;
      },
      expression: "tradeTimeRange"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt20"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "border": "",
      "data": _vm.dataList
    }
  }, [_c('el-table-column', {
    attrs: {
      "prop": "clientCode",
      "header-align": "center",
      "align": "center",
      "label": "Client code",
      "width": "100"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country",
      "header-align": "center",
      "align": "center",
      "label": "Country"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "createAt",
      "header-align": "center",
      "align": "center",
      "label": "Handling Time",
      "width": "130",
      "formatter": _vm.localDateFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Handler"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('span', [_vm._v(_vm._s(scope.row.userInfo.nickname))])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "introducedBy",
      "header-align": "center",
      "align": "center",
      "label": "Introduced By",
      "width": "126",
      "formatter": _vm.introducedByFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Commission",
      "min-width": "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.commissionList, function (item) {
          return _c('p', {
            key: "commission".concat(item.id),
            staticClass: "mt0 mb0"
          }, [_vm._v(" " + _vm._s(item.name) + " - " + _vm._s(_vm.multiply(item.commissionRate)) + " ")]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "contact",
      "header-align": "center",
      "align": "center",
      "label": "Contact Info",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "introducer",
      "header-align": "center",
      "align": "center",
      "label": "Introducer",
      "min-width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "remark",
      "header-align": "center",
      "align": "center",
      "label": "Description / Remark",
      "min-width": "120"
    }
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }