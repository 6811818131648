var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "title": "Add Promotion Code",
      "close-on-click-modal": false,
      "visible": _vm.visible,
      "width": "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "140px"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Promotion Code",
      "prop": "code"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Promotion Code",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.code,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "code", $$v);
      },
      expression: "dataForm.code"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "enabled"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.enabled,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "enabled", $$v);
      },
      expression: "dataForm.enabled"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("Disable")]), _c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Enable")])], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Remark",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Remark"
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }