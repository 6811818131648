var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company-applyment-module"
  }, [_c('el-affix', {
    class: {
      'applyment-page-header-top': _vm.affixed,
      'applyment-page-header-shrink': _vm.sidebarFold,
      'applyment-page-header-unfold': !_vm.sidebarFold
    },
    attrs: {
      "offset": 104
    },
    on: {
      "change": _vm.handleAffixChange
    }
  }, [_c('el-card', [_c('div', {
    staticClass: "applyment-page-header"
  }, [_c('div', [_c('div', {
    staticClass: "status"
  }, [_vm._v(" " + _vm._s(_vm.applymentStatusFormat(_vm.detail.applymentStatus)) + " "), _vm.detail.applymentStatus == 'REFUND_HANDING' ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v("(" + _vm._s(_vm.refundStatusFormat(_vm.detail.orderInfo.orderRefund.status)) + ")")]) : _vm._e(), _vm.detail.amends ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v("(Supplemental: " + _vm._s(_vm.amendsStatusFormat(_vm.detail.amends)) + ")")]) : _vm._e(), _vm.detail.hasRenew ? _c('span', {
    staticClass: "fs14"
  }, [_vm._v(_vm._s(_vm.renewStatusFormat(_vm.detail.linkedRenewStatus)))]) : _vm._e(), _vm.detail.laterPay ? _c('span', {
    staticClass: "fs12"
  }, [_vm._v(" (Credit: "), _vm.detail.orderInfo ? [_vm._v(" " + _vm._s(_vm.orderStatusFormat(_vm.detail.orderInfo.status)) + " ")] : _vm._e(), _vm._v(" ) ")], 2) : _vm._e()]), _c('div', {
    staticClass: "page-header-heading-left"
  }, [_c('div', {
    staticClass: "heading-title"
  }, [_vm._v("Transaction No.: " + _vm._s(_vm.detail.applymentNo))])]), _c('div', {
    staticClass: "page-header-heading-content"
  }, [_c('el-descriptions', {
    attrs: {
      "column": 4,
      "direction": "vertical"
    }
  }, [_c('el-descriptions-item', {
    attrs: {
      "label": "Transaction No."
    }
  }, [_vm._v(_vm._s(_vm.detail.applymentNo))]), _c('el-descriptions-item', {
    attrs: {
      "label": "Checker"
    }
  }, [_c('user-toggle', {
    attrs: {
      "userList": _vm.userList,
      "user": _vm.detail.checker,
      "ownershipRole": "checker"
    }
  })], 1), _c('el-descriptions-item', {
    attrs: {
      "label": "Reviewer"
    }
  }, [_c('user-toggle', {
    attrs: {
      "userList": _vm.userList,
      "user": _vm.detail.auditor,
      "ownershipRole": "auditor"
    }
  })], 1), _c('el-descriptions-item', {
    attrs: {
      "label": "Coordinator"
    }
  }, [_c('user-toggle', {
    attrs: {
      "userList": _vm.userList,
      "user": _vm.detail.coordinator,
      "ownershipRole": "coordinator"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "page-header-heading-right"
  }, [_c('div', {
    staticClass: "opts"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openOwnershipHistoryModal
    }
  }, [_vm._v("Ownership History")]), _c('el-button', {
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": _vm.openStatusHistoryModal
    }
  }, [_vm._v("Status Track")]), (_vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'ACRA_REJECTED' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT') && _vm.detail.orderInfo && _vm.detail.orderInfo.status == 'COMPLETE' && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleInitiateRefund
    }
  }, [_vm._v(" Apply for Refund ")]) : _vm._e(), _vm.detail.applymentStatus === 'REFUND_APPLYING' && _vm.isApplymentAuth('company:check') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": function click($event) {
        return _vm.handleRefundJudge('check');
      }
    }
  }, [_vm._v(" Refund Check ")]) : _vm._e(), _vm.detail.applymentStatus === 'REFUND_APPLY_AGREED' && _vm.isApplymentAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": function click($event) {
        return _vm.handleRefundJudge('audit');
      }
    }
  }, [_vm._v(" Refund Review ")]) : _vm._e(), _vm.detail.applymentStatus === 'REFUND_HANDING' && _vm.detail.orderInfo && _vm.detail.orderInfo.orderRefund && _vm.detail.orderInfo.orderRefund.status == 'REFUND_INFO_NOT_ENOUGH' && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleRefundInfoUpdate
    }
  }, [_vm._v(" Refund Info Update ")]) : _vm._e(), _vm.detail.applymentStatus === 'AUDIT_SUCCESS' && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleAcraApply
    }
  }, [_vm._v(" ACRA Processing ")]) : _vm._e(), _vm.detail.applymentStatus === 'ACRA_APPLYING' && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleAcraFeedback
    }
  }, [_vm._v(" ACRA Feedback ")]) : _vm._e(), _vm.detail.amends != 1 && (_vm.detail.applymentStatus === 'ACRA_APPLYING' || _vm.detail.applymentStatus === 'REVISING') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleMakeAmends
    }
  }, [_vm._v(" Make Supplemental ")]) : _vm._e(), (_vm.detail.applymentStatus === 'CHECKING' || _vm.detail.applymentStatus === 'REVISE_FINISH') && _vm.isApplymentAuth('company:check') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleCheckFinish
    }
  }, [_vm._v(" Finish Check ")]) : _vm._e(), _vm.detail.applymentStatus === 'AUDITING' && _vm.isApplymentAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleAuditFinish
    }
  }, [_vm._v(" Finish Review ")]) : _vm._e(), (_vm.detail.applymentStatus === 'ILLEGAL' || _vm.detail.applymentStatus === 'AUDIT_FAIL' || _vm.detail.applymentStatus === 'ACRA_REJECTED' || _vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'CORRECTED' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "danger",
      "size": "medium"
    },
    on: {
      "click": _vm.handleReject
    }
  }, [_vm._v(" Reject to Client ")]) : _vm._e(), (_vm.detail.applymentStatus === 'REVISING' || _vm.detail.applymentStatus === 'REFUND_APPLY_DENY' || _vm.detail.applymentStatus === 'REFUND_APPLY_REJECT' || _vm.detail.applymentStatus === 'CORRECTED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleReviseFinish
    }
  }, [_vm._v(" Revision Submit ")]) : _vm._e(), (_vm.detail.applymentStatus === 'FINISH' || _vm.detail.applymentStatus === 'SUSPEND_TRANSACTION_APPLY_REJECT') && (_vm.detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType === 'FUND_ADMIN_CUSTOMIZED') && _vm.isApplymentAuth('company:coordinate') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleTerminationApply
    }
  }, [_vm._v(" Apply for Termination ")]) : _vm._e(), _vm.detail.applymentStatus === 'SUSPEND_TRANSACTION_APPLYING' && (_vm.detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType === 'FUND_ADMIN_CUSTOMIZED') && _vm.isApplymentAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "size": "medium"
    },
    on: {
      "click": _vm.handleAuditTerminated
    }
  }, [_vm._v(" Termination Review ")]) : _vm._e()], 1)])])])], 1), !(_vm.detail.isLaterService && (_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED')) ? _c('div', {
    staticClass: "steps-bar-wrapper"
  }, [_c('el-steps', {
    attrs: {
      "direction": "vertical",
      "align-center": "",
      "space": 80
    }
  }, [_vm.getStepName('companyName') ? _c('el-step', {
    attrs: {
      "status": _vm.getStepStatus(_vm.getStepName('companyName'))
    },
    nativeOn: {
      "click": function click($event) {
        _vm.jumpStep(_vm.getStepName('companyName'));
      }
    }
  }) : _vm._e(), _vm.getStepName('kycQuestionnaire') ? _c('el-step', {
    attrs: {
      "status": _vm.getStepStatus(_vm.getStepName('kycQuestionnaire'))
    },
    nativeOn: {
      "click": function click($event) {
        _vm.jumpStep(_vm.getStepName('kycQuestionnaire'));
      }
    }
  }) : _vm._e(), _vm.getStepName('companyDetails') ? _c('el-step', {
    attrs: {
      "status": _vm.getStepStatus(_vm.getStepName('companyDetails'))
    },
    nativeOn: {
      "click": function click($event) {
        _vm.jumpStep(_vm.getStepName('companyDetails'));
      }
    }
  }) : _vm._e(), _vm.getStepName('upload') ? _c('el-step', {
    attrs: {
      "status": _vm.getStepStatus(_vm.getStepName('upload'))
    },
    nativeOn: {
      "click": function click($event) {
        _vm.jumpStep(_vm.getStepName('upload'));
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "step mt50"
  }, [_c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('base-info', {
    attrs: {
      "detail": _vm.detail,
      "invoiceObj": _vm.invoiceObj,
      "quotationVList": _vm.quotationVList
    },
    on: {
      "refreshInvoiceList": _vm.getInvoiceList
    }
  })], 1)], 1), !(_vm.detail.isLaterService && (_vm.detail.businessType == 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType == 'FUND_ADMIN_CUSTOMIZED')) ? _c('div', [_vm.getStepName('companyName') ? _c('div', {
    ref: _vm.getStepName('companyName'),
    staticClass: "pt40"
  }) : _vm._e(), _vm.getStepName('companyName') ? _c('div', {
    staticClass: "step mt40",
    attrs: {
      "id": "Step1"
    }
  }, [_vm._v(" " + _vm._s(void (_vm.Step1 = _vm.stepsTree[_vm.getStepName("companyName")])) + " "), _c('step-header', {
    attrs: {
      "label": _vm.getStepName('companyName')
    }
  }, [_c('div', {
    staticClass: "state mr20"
  }, [_c('el-button', {
    staticClass: "mr20",
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": function click($event) {
        _vm.openStepsHistoryModal(_vm.getStepName('companyName'));
      }
    }
  }, [_vm._v("Steps History")]), _vm.Step1 ? _c('div', {
    staticClass: "border-type",
    class: {
      'no-pass-button': _vm.Step1 && _vm.Step1.status == 0,
      'pass-button': _vm.Step1 && _vm.Step1.status == 1,
      'revised-button': _vm.Step1 && _vm.Step1.status == 2,
      'no-checking-button': _vm.Step1 && _vm.Step1.status == 3
    }
  }, [_vm.Step1 && _vm.Step1.status == 0 ? _c('span', {
    staticClass: "el-icon-error"
  }) : _vm._e(), _vm.Step1 && _vm.Step1.status == 1 ? _c('span', {
    staticClass: "el-icon-success"
  }) : _vm._e(), _vm.Step1 && _vm.Step1.status == 2 ? _c('span', {
    staticClass: "el-icon-warning"
  }) : _vm._e(), _vm.Step1 && _vm.Step1.status == 3 ? _c('span', {
    staticClass: "el-icon-mouse"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.stepStatusFormat(_vm.Step1)) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "opts"
  }, [_vm.checkStepVisible(_vm.getStepName('companyName')) ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        _vm.openStepModal(_vm.getStepName('companyName'));
      }
    }
  }, [_vm._v(" Review ")]) : _vm._e()], 1)]), _c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('company-info', {
    attrs: {
      "detail": _vm.detail,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  }), _c('shareholders-detail', {
    staticClass: "mt40",
    attrs: {
      "stepName": "companyName",
      "applymentId": _vm.detail.id,
      "applymentStatus": _vm.detail.applymentStatus,
      "companyMemberList": _vm.detail.companyMemberList,
      "hasNomineeDirector": _vm.detail.hasNomineeDirector,
      "businessType": _vm.detail.businessType,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  })], 1)], 1) : _vm._e(), _vm.getStepName('kycQuestionnaire') ? _c('div', {
    ref: _vm.getStepName('kycQuestionnaire'),
    staticClass: "pt40"
  }) : _vm._e(), _vm.detail.questionnaire && _vm.getStepName('kycQuestionnaire') ? _c('div', {
    staticClass: "step mt40",
    attrs: {
      "id": "Step2"
    }
  }, [_vm._v(" " + _vm._s(void (_vm.Step2 = _vm.stepsTree[_vm.getStepName("kycQuestionnaire")])) + " "), _c('step-header', {
    attrs: {
      "label": _vm.getStepName('kycQuestionnaire')
    }
  }, [_c('div', {
    staticClass: "state mr20"
  }, [_c('el-button', {
    staticClass: "mr20",
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": function click($event) {
        _vm.openStepsHistoryModal(_vm.getStepName('kycQuestionnaire'));
      }
    }
  }, [_vm._v(" Steps History ")]), _vm.isApplymentAuth('company:coordinate') || _vm.isApplymentAuth('company:check') || _vm.isApplymentAuth('company:audit') ? _c('el-button', {
    staticClass: "mr20",
    attrs: {
      "type": "text",
      "icon": "el-icon-edit-outline"
    },
    on: {
      "click": _vm.openRiskAssessmentModal
    }
  }, [_vm._v(" Risk Assessment "), _vm.detail.isRiskAssessmentFinished === 1 && _vm.riskAssessmentObj.suggest == 1 ? _c('span', {
    staticStyle: {
      "color": "#02cc90"
    }
  }, [_vm._v("(RA-passed)")]) : _vm._e(), _vm.detail.isRiskAssessmentFinished === 1 && _vm.riskAssessmentObj.suggest == 0 ? _c('span', {
    staticStyle: {
      "color": "#ff7a37"
    }
  }, [_vm._v("(RA-failed)")]) : _vm._e()]) : _vm._e(), _vm.Step2 ? _c('div', {
    staticClass: "border-type",
    class: {
      'no-pass-button': _vm.Step2 && _vm.Step2.status == 0,
      'pass-button': _vm.Step2 && _vm.Step2.status == 1,
      'revised-button': _vm.Step2 && _vm.Step2.status == 2,
      'no-checking-button': _vm.Step2 && _vm.Step2.status == 3
    }
  }, [_vm.Step2 && _vm.Step2.status == 0 ? _c('span', {
    staticClass: "el-icon-error"
  }) : _vm._e(), _vm.Step2 && _vm.Step2.status == 1 ? _c('span', {
    staticClass: "el-icon-success"
  }) : _vm._e(), _vm.Step2 && _vm.Step2.status == 2 ? _c('span', {
    staticClass: "el-icon-warning"
  }) : _vm._e(), _vm.Step2 && _vm.Step2.status == 3 ? _c('span', {
    staticClass: "el-icon-mouse"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.stepStatusFormat(_vm.Step2)) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "opts"
  }, [_vm.checkStepVisible(_vm.getStepName('kycQuestionnaire')) ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        _vm.openStepModal(_vm.getStepName('kycQuestionnaire'));
      }
    }
  }, [_vm._v(" Review ")]) : _vm._e()], 1)]), _vm.detail.questionnaire ? _c('el-card', [_c('questionnaire', {
    key: new Date().getTime(),
    attrs: {
      "applymentId": _vm.detail.id,
      "applymentStatus": _vm.detail.applymentStatus,
      "questionnaire": _vm.detail.questionnaire,
      "businessType": _vm.detail.businessType,
      "fundInfoList": _vm.detail.fundInfoList || [],
      "businessGenre": _vm.detail.businessGenre,
      "registeredOfficeType": _vm.detail.registeredOfficeType,
      "registeredOfficeAddress": _vm.detail.registeredOfficeAddress,
      "registeredOfficeCity": _vm.detail.registeredOfficeCity,
      "registeredOfficeCountry": _vm.detail.registeredOfficeCountry,
      "registeredOfficePostalCode": _vm.detail.registeredOfficePostalCode,
      "registeredOfficeId": _vm.detail.registeredOfficeId,
      "nativeRegisteredAddress": _vm.nativeRegisteredAddress,
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector,
      "hasNomineeDirector": _vm.detail.hasNomineeDirector
    }
  }), _vm.detail.businessType === 'SERVICE_TRANSFER' || _vm.detail.businessType === 'COMPREHENSIVE_CUSTOMIZED' || _vm.detail.businessType === 'FUND_ADMIN_CUSTOMIZED' ? _c('shareholders-detail', {
    staticClass: "mt40",
    attrs: {
      "stepName": "kycQuestionnaire",
      "applymentId": _vm.detail.id,
      "applymentStatus": _vm.detail.applymentStatus,
      "fundInfoList": _vm.detail.fundInfoList || [],
      "companyMemberList": _vm.detail.companyMemberList,
      "companyFundMemberList": _vm.detail.companyFundMemberList,
      "hasNomineeDirector": _vm.detail.hasNomineeDirector,
      "businessType": _vm.detail.businessType,
      "appointNomineeDirector": _vm.detail.questionnaire.appointNomineeDirector || {
        type: null,
        name: null
      },
      "headline": _vm.detail.businessType === 'FUND_ADMIN_CUSTOMIZED' ? "Information of the proposed fund manager's officer(s)" : "Information of the proposed company's officer(s)",
      "isNeedNomineeDirector": _vm.isNeedNomineeDirector
    }
  }) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.getStepName('companyDetails') ? _c('div', {
    ref: _vm.getStepName('companyDetails'),
    staticClass: "pt40"
  }) : _vm._e(), _vm.getStepName('companyDetails') ? _c('div', {
    staticClass: "step mt40",
    attrs: {
      "id": "Step3"
    }
  }, [_vm._v(" " + _vm._s(void (_vm.Step3 = _vm.stepsTree[_vm.getStepName("companyDetails")])) + " "), _c('step-header', {
    attrs: {
      "label": _vm.getStepName('companyDetails')
    }
  }, [_c('div', {
    staticClass: "state mr20"
  }, [_c('el-button', {
    staticClass: "mr20",
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": function click($event) {
        _vm.openStepsHistoryModal(_vm.getStepName('companyDetails'));
      }
    }
  }, [_vm._v(" Steps History ")]), _vm.Step3 ? _c('div', {
    staticClass: "border-type",
    class: {
      'no-pass-button': _vm.Step3 && _vm.Step3.status == 0,
      'pass-button': _vm.Step3 && _vm.Step3.status == 1,
      'revised-button': _vm.Step3 && _vm.Step3.status == 2,
      'no-checking-button': _vm.Step3 && _vm.Step3.status == 3
    }
  }, [_vm.Step3 && _vm.Step3.status == 0 ? _c('span', {
    staticClass: "el-icon-error"
  }) : _vm._e(), _vm.Step3 && _vm.Step3.status == 1 ? _c('span', {
    staticClass: "el-icon-success"
  }) : _vm._e(), _vm.Step3 && _vm.Step3.status == 2 ? _c('span', {
    staticClass: "el-icon-warning"
  }) : _vm._e(), _vm.Step3 && _vm.Step3.status == 3 ? _c('span', {
    staticClass: "el-icon-mouse"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.stepStatusFormat(_vm.Step3)) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "opts"
  }, [_vm.checkStepVisible(_vm.getStepName('companyDetails')) ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        _vm.openStepModal(_vm.getStepName('companyDetails'));
      }
    }
  }, [_vm._v(" Review ")]) : _vm._e()], 1)]), _c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('finance-info', {
    attrs: {
      "detail": _vm.detail
    }
  }), _c('shareholders-info', {
    staticClass: "mt40",
    attrs: {
      "companyMemberList": _vm.detail.companyMemberList,
      "businessType": _vm.detail.businessType
    }
  }), _c('extra-info', {
    attrs: {
      "detail": _vm.detail,
      "nativeRegisteredAddress": _vm.nativeRegisteredAddress
    }
  })], 1)], 1) : _vm._e(), _vm.getStepName('upload') ? _c('div', {
    ref: _vm.getStepName('upload'),
    staticClass: "pt40"
  }) : _vm._e(), _vm.getStepName('upload') ? _c('div', {
    staticClass: "step mt40",
    attrs: {
      "id": "Step4"
    }
  }, [_vm._v(" " + _vm._s(void (_vm.Step4 = _vm.stepsTree[_vm.getStepName("upload")])) + " "), _c('step-header', {
    attrs: {
      "label": _vm.getStepName('upload')
    }
  }, [_c('div', {
    staticClass: "state mr20"
  }, [_c('el-button', {
    staticClass: "mr20",
    attrs: {
      "type": "text",
      "icon": "el-icon-tickets"
    },
    on: {
      "click": function click($event) {
        _vm.openStepsHistoryModal(_vm.getStepName('upload'));
      }
    }
  }, [_vm._v("Steps History")]), _vm.Step4 ? _c('div', {
    staticClass: "border-type",
    class: {
      'no-pass-button': _vm.Step4 && _vm.Step4.status == 0,
      'pass-button': _vm.Step4 && _vm.Step4.status == 1,
      'revised-button': _vm.Step4 && _vm.Step4.status == 2,
      'no-checking-button': _vm.Step4 && _vm.Step4.status == 3
    }
  }, [_vm.Step4 && _vm.Step4.status == 0 ? _c('span', {
    staticClass: "el-icon-error"
  }) : _vm._e(), _vm.Step4 && _vm.Step4.status == 1 ? _c('span', {
    staticClass: "el-icon-success"
  }) : _vm._e(), _vm.Step4 && _vm.Step4.status == 2 ? _c('span', {
    staticClass: "el-icon-warning"
  }) : _vm._e(), _vm.Step4 && _vm.Step4.status == 3 ? _c('span', {
    staticClass: "el-icon-mouse"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.stepStatusFormat(_vm.Step4)) + " ")]) : _vm._e()], 1), _c('div', {
    staticClass: "opts"
  }, [_vm.checkStepVisible(_vm.getStepName('upload')) ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        _vm.openStepModal(_vm.getStepName('upload'));
      }
    }
  }, [_vm._v("Review")]) : _vm._e()], 1)]), _c('el-card', {
    attrs: {
      "shadow": "hover"
    }
  }, [_c('materials', {
    attrs: {
      "detail": _vm.detail
    }
  })], 1)], 1) : _vm._e()]) : _vm._e(), _vm.stepModalVisible ? _c('step-modal', {
    ref: "stepModal"
  }) : _vm._e(), _c('ownership-history', {
    ref: "ownershipModal",
    attrs: {
      "applymentId": _vm.detail.id
    }
  }), _c('step-history', {
    ref: "stepHistoryModal",
    attrs: {
      "applymentId": _vm.detail.id
    }
  }), _c('status-history', {
    ref: "statusHistoryModal",
    attrs: {
      "applymentId": _vm.detail.id
    }
  }), _c('initiate-refund', {
    ref: "initiateRefundModal",
    attrs: {
      "detail": _vm.detail
    }
  }), _c('initiate-refund-list', {
    ref: "initiateRefundListModal",
    attrs: {
      "detail": _vm.detail
    }
  }), _c('refund-judge', {
    ref: "refundJudgeModal"
  }), _c('acra-feedback', {
    ref: "acraFeedbackModal",
    attrs: {
      "detail": _vm.detail
    }
  }), _c('refund-info-update', {
    ref: "refundInfoUpdateModal",
    attrs: {
      "orderInfo": _vm.detail.orderInfo
    }
  }), _c('make-amends', {
    ref: "makeAmendsModal",
    attrs: {
      "businessGenre": _vm.detail.businessGenre,
      "businessType": _vm.detail.businessType,
      "applymentNo": _vm.detail.applymentNo
    },
    on: {
      "upInvoiceList": _vm.getInvoiceList
    }
  }), _c('termination-apply', {
    ref: "terminationApplyModal",
    attrs: {
      "detail": _vm.detail
    }
  }), _c('termination-judge', {
    ref: "terminationJudgeModal",
    attrs: {
      "detail": _vm.detail
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }