var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-container"
  }, [_c('div', [_c('el-form', {
    staticClass: "el-form-filter",
    attrs: {
      "inline": true,
      "model": _vm.dataForm
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.getDataList(1);
      }
    }
  }, [_c('el-form-item', [_c('el-input', {
    staticClass: "wd500 input-with-select-lg",
    class: {
      clientCodeBlankActive: _vm.dataForm.noClientCode
    },
    attrs: {
      "placeholder": _vm.kwPlaceHolder,
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "keyword", $$v);
      },
      expression: "dataForm.keyword"
    }
  }, [_c('el-select', {
    attrs: {
      "slot": "prepend",
      "placeholder": "please select"
    },
    on: {
      "change": _vm.kwTypeChange
    },
    slot: "prepend",
    model: {
      value: _vm.dataForm.kwType,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "kwType", $$v);
      },
      expression: "dataForm.kwType"
    }
  }, _vm._l(_vm.kwTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "filterable": "",
      "multiple": "",
      "clearable": "",
      "placeholder": "Country of Company"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "select coordinator",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.coordinatorIds,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "coordinatorIds", $$v);
      },
      expression: "dataForm.coordinatorIds"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "unassigned",
      "value": -1
    }
  }), _vm._l(_vm.userList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.nickname || item.username,
        "value": item.id
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "select checker",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.checkerIds,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "checkerIds", $$v);
      },
      expression: "dataForm.checkerIds"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "unassigned",
      "value": -1
    }
  }), _vm._l(_vm.userList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.nickname || item.username,
        "value": item.id
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "placeholder": "select reviewer",
      "multiple": "",
      "clearable": ""
    },
    model: {
      value: _vm.dataForm.auditorIds,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "auditorIds", $$v);
      },
      expression: "dataForm.auditorIds"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "unassigned",
      "value": -1
    }
  }), _vm._l(_vm.userList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.nickname || item.username,
        "value": item.id
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-date-picker', {
    staticStyle: {
      "width": "384px"
    },
    attrs: {
      "value-format": "timestamp",
      "type": "daterange",
      "range-separator": "~",
      "start-placeholder": "Start Time",
      "end-placeholder": "End Time"
    },
    model: {
      value: _vm.tradeTimeRange,
      callback: function callback($$v) {
        _vm.tradeTimeRange = $$v;
      },
      expression: "tradeTimeRange"
    }
  })], 1), _c('el-form-item', [_c('el-select-all', {
    attrs: {
      "optionList": _vm.applymentStatusScreenList,
      "placeholder": "Status"
    },
    on: {
      "elSelectAllChange": function elSelectAllChange(list) {
        _vm.dataForm.applymentStatus = list;
      }
    }
  })], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Linked Transaction"
    },
    model: {
      value: _vm.linkedTransaction,
      callback: function callback($$v) {
        _vm.linkedTransaction = $$v;
      },
      expression: "linkedTransaction"
    }
  }, _vm._l(_vm.linkedTransactionList, function (item) {
    return _c('el-option', {
      key: item.title,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "clearable": "",
      "placeholder": "Entity Type"
    },
    model: {
      value: _vm.dataForm.businessGenre,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "businessGenre", $$v);
      },
      expression: "dataForm.businessGenre"
    }
  }, _vm._l(_vm.entityTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', [_c('el-select', {
    attrs: {
      "multiple": "",
      "clearable": "",
      "placeholder": "Risk Rating"
    },
    model: {
      value: _vm.dataForm.riskRating,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "riskRating", $$v);
      },
      expression: "dataForm.riskRating"
    }
  }, [_c('el-option', {
    attrs: {
      "label": "Blank",
      "value": "null"
    }
  }), _vm._l(_vm.riskRatingTypes, function (item) {
    return _c('el-option', {
      key: item.title,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  })], 2)], 1), _c('el-form-item', [_c('el-button', {
    attrs: {
      "icon": "el-icon-search",
      "type": "primary",
      "plain": ""
    },
    on: {
      "click": function click($event) {
        return _vm.getDataList(1);
      }
    }
  }, [_vm._v("Search")])], 1)], 1)], 1), _c('div', {
    staticClass: "mt20"
  }, [_vm.isAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "small",
      "icon": "el-icon-thumb",
      "disabled": _vm.dataListSelections.length <= 0
    },
    on: {
      "click": _vm.handleBatchAudit
    }
  }, [_vm._v(" Reviewer Finish Approve ")]) : _vm._e(), _vm.isAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "small",
      "icon": "el-icon-thumb",
      "disabled": _vm.dataListSelections.length <= 0
    },
    on: {
      "click": _vm.handleBatchRefundAudit
    }
  }, [_vm._v(" Reviewer Refund Approve ")]) : _vm._e(), _vm.isAuth('company:audit') ? _c('el-button', {
    attrs: {
      "type": "primary",
      "plain": "",
      "size": "small",
      "icon": "el-icon-download",
      "disabled": _vm.dataListSelections.length <= 0
    },
    on: {
      "click": _vm.handleExportExcel
    }
  }, [_vm._v(" Export Excel ")]) : _vm._e()], 1), _c('div', {
    staticClass: "mt20"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.dataListLoading,
      expression: "dataListLoading"
    }],
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "max-height": "600",
      "data": _vm.dataList,
      "default-sort": {
        prop: 'createTime',
        order: 'descending'
      }
    },
    on: {
      "selection-change": _vm.selectionChangeHandle,
      "sort-change": _vm.tableSortChange
    }
  }, [_c('el-table-column', {
    attrs: {
      "type": "selection",
      "header-align": "center",
      "align": "center",
      "width": "50"
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "left",
      "prop": "applymentNo",
      "align": "center",
      "header-align": "center",
      "label": "Transaction No.",
      "min-width": "160"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "createTime",
      "header-align": "center",
      "align": "center",
      "label": "Create Time",
      "width": "260",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "commitTime",
      "header-align": "center",
      "align": "center",
      "label": "Submit Time",
      "width": "260",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "terminatedTime",
      "header-align": "center",
      "align": "center",
      "label": "Terminated Time",
      "width": "260",
      "sortable": "custom"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "clientCodeList",
      "header-align": "center",
      "align": "center",
      "label": "Client Code",
      "width": "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.clientCodeList && scope.row.clientCodeList.length > 0 ? _c('span', [_vm._v(_vm._s(scope.row.clientCodeList.join(", ")))]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "riskAssessmentData.rating",
      "header-align": "center",
      "align": "center",
      "label": "Risk Rating",
      "width": "102",
      "formatter": _vm.riskRatingFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applymentStatus",
      "header-align": "center",
      "align": "center",
      "label": "Status",
      "width": "200",
      "formatter": _vm.statusFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Linked Transaction",
      "width": "180",
      "formatter": _vm.linkedTransactionFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "businessGenre",
      "header-align": "center",
      "align": "center",
      "label": "Entity Type",
      "width": "180",
      "formatter": _vm.entityTypeFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applicantName",
      "header-align": "center",
      "align": "center",
      "label": "Applicant Name",
      "width": "160"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "applicantEmail",
      "header-align": "center",
      "align": "center",
      "label": "Applicant Email",
      "width": "200"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "country",
      "header-align": "center",
      "align": "center",
      "label": "Country of Company",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "proposedCompanyName1",
      "header-align": "center",
      "align": "center",
      "label": "Proposed Company Name",
      "width": "400",
      "formatter": _vm.companyNameFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Billing Party",
      "width": "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.orderInfoList ? _vm._l(scope.row.orderInfoList, function (item) {
          return _c('div', {
            key: "orderInfoList".concat(item.id)
          }, [_vm._v(_vm._s(item.billingParty) + " (" + _vm._s(item.businessNo) + ")")]);
        }) : [scope.row.orderInfo && scope.row.orderInfo.billingParty ? [_c('div', [_vm._v(_vm._s(scope.row.orderInfo.billingParty) + " (" + _vm._s(scope.row.orderInfo.businessNo) + ")")])] : _vm._e()], _vm._l(scope.row.supplementOrderInfoList, function (item) {
          return _c('div', {
            key: "supplementOrderInfoList".concat(item.id)
          }, [_vm._v(" " + _vm._s(item.billingParty) + " (" + _vm._s(item.supplementNo) + ") ")]);
        })];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "header-align": "center",
      "align": "center",
      "label": "Fund(s) Name",
      "width": "400"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.fundInfoList, function (item, index) {
          return _c('span', {
            key: "fund".concat(item.id)
          }, [_vm._v(_vm._s(index != 0 ? "、" : "") + " " + _vm._s(item.fundName))]);
        });
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "prop": "coordinator",
      "header-align": "center",
      "align": "center",
      "label": "Coordinator",
      "width": "200",
      "formatter": _vm.auditorFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "checker",
      "header-align": "center",
      "align": "center",
      "label": "Checker",
      "width": "200",
      "formatter": _vm.auditorFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "auditor",
      "header-align": "center",
      "align": "center",
      "label": "Reviewer",
      "width": "200",
      "formatter": _vm.auditorFormat
    }
  }), _c('el-table-column', {
    attrs: {
      "fixed": "right",
      "header-align": "center",
      "align": "center",
      "width": "180",
      "label": "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c('el-button', {
          staticClass: "fwb",
          attrs: {
            "type": "text"
          },
          on: {
            "click": function click($event) {
              return _vm.detailHandler(scope.row.id);
            }
          }
        }, [_vm._v("Detail")])];
      }
    }])
  })], 1), _c('el-pagination', {
    attrs: {
      "current-page": _vm.current,
      "page-sizes": [10, 20, 50, 100],
      "page-size": _vm.size,
      "total": _vm.total,
      "layout": "total, sizes, prev, pager, next, jumper"
    },
    on: {
      "size-change": _vm.sizeChangeHandle,
      "current-change": _vm.currentChangeHandle
    }
  })], 1), _c('batch-audit', {
    ref: "batchAuditModal",
    on: {
      "refreshDataList": _vm.getDataList
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }