var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "960px",
      "title": !_vm.dataForm.id ? 'Add Invoice Info' : 'Edit Invoice Info',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "label-width": "160px"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.dataFormSubmit();
      }
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Entity type",
      "prop": "type"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select type"
    },
    model: {
      value: _vm.dataForm.type,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "type", $$v);
      },
      expression: "dataForm.type"
    }
  }, _vm._l(_vm.entityTypes, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Title",
      "prop": "title"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Invoice title (company name)",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "title", $$v);
      },
      expression: "dataForm.title"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Title(CN)",
      "prop": "titleCn"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Chinese company name"
    },
    model: {
      value: _vm.dataForm.titleCn,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "titleCn", $$v);
      },
      expression: "dataForm.titleCn"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Address",
      "prop": "address"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Address",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.address,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "address", $$v);
      },
      expression: "dataForm.address"
    }
  })], 1), _c('el-row', [_c('el-col', {
    attrs: {
      "lg": 20,
      "span": 20
    }
  }, [_c('el-row', [_c('el-col', {
    attrs: {
      "lg": 13,
      "span": 9
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Country",
      "prop": "country"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "placeholder": "Select Country"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Postal Code",
      "prop": "postalCode"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Postal Code",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.postalCode,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "postalCode", $$v);
      },
      expression: "dataForm.postalCode"
    }
  })], 1)], 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Registration No.",
      "prop": "registrationNumber"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Registration No.",
      "oninput": _vm.isEn
    },
    model: {
      value: _vm.dataForm.registrationNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "registrationNumber", $$v);
      },
      expression: "dataForm.registrationNumber"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Tax Rate",
      "prop": "taxRate"
    }
  }, [_c('el-input-number', {
    attrs: {
      "controls-position": "right",
      "min": 0,
      "step": 0.01,
      "label": "Tax Rate"
    },
    model: {
      value: _vm.dataForm.taxRate,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "taxRate", $$v);
      },
      expression: "dataForm.taxRate"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Status",
      "prop": "status"
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.dataForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "status", $$v);
      },
      expression: "dataForm.status"
    }
  }, [_c('el-radio', {
    attrs: {
      "label": 0
    }
  }, [_vm._v("Disable")]), _c('el-radio', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("Enable")])], 1)], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("Submit")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }