var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "600px",
      "title": "Config",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "label-position": "left",
      "label-width": "110px"
    }
  }, _vm._l(_vm.dataForm.employersList, function (item, index) {
    return _c('el-form-item', {
      key: "Employer".concat(index),
      attrs: {
        "label": "Employer(".concat(index + 1, ")"),
        "prop": 'employersList.' + index,
        "rules": {
          required: true,
          message: 'Please Enter',
          trigger: 'submit'
        }
      }
    }, [_c('div', {
      staticClass: "flex align-center"
    }, [_c('el-input', {
      staticClass: "mr20 wd350",
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.dataForm.employersList[index],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm.employersList, index, $$v);
        },
        expression: "dataForm.employersList[index]"
      }
    }), _c('span', {
      staticClass: "fs22 cursor-pointer blue el-icon-circle-plus-outline",
      on: {
        "click": function click($event) {
          return _vm.dataForm.employersList.splice(index + 1, 0, '');
        }
      }
    }), index > 0 ? _c('span', {
      staticClass: "fs22 ml12 cursor-pointer red el-icon-remove-outline",
      on: {
        "click": function click($event) {
          return _vm.dataForm.employersList.splice(index, 1);
        }
      }
    }) : _vm._e()], 1)]);
  }), 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }