var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    staticClass: "module-selector",
    attrs: {
      "width": "840px",
      "title": "Select Service",
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_vm.modules && _vm.modules.length > 0 ? [_c('div', {
    staticClass: "module-selector-box"
  }, _vm._l(_vm.modules, function (module, index) {
    return _c('module-item', {
      key: module.id,
      attrs: {
        "selectable": ""
      },
      model: {
        value: _vm.modules[index],
        callback: function callback($$v) {
          _vm.$set(_vm.modules, index, $$v);
        },
        expression: "modules[index]"
      }
    });
  }), 1), _c('div', {
    staticClass: "text-center mt20 mb15"
  }, [_c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.finishHandler();
      }
    }
  }, [_vm._v("OK")])], 1)] : _c('el-empty', {
    attrs: {
      "description": " "
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }