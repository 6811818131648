var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    class: 'icon-svg icon-svg__' + _vm.name,
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c('use', {
    attrs: {
      "xlink:href": _vm.icon
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }