var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "750px",
      "title": !_vm.dataForm.id ? 'Add Quotation (V)' : 'Edit Quotation (V)',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "286px"
    }
  }, [_c('el-form-item', {
    attrs: {
      "label": "Linked Transaction No.",
      "prop": "businessNo"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "placeholder": "Transaction No.",
      "clearable": "",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getbusinessNoRemote,
      "loading": _vm.businessNoLoading
    },
    on: {
      "clear": function clear($event) {
        return _vm.getbusinessNoRemote('');
      },
      "focus": function focus($event) {
        return _vm.getbusinessNoRemote('');
      },
      "change": _vm.businessNoChange
    },
    model: {
      value: _vm.dataForm.businessNo,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "businessNo", $$v);
      },
      expression: "dataForm.businessNo"
    }
  }, _vm._l(_vm.businessNoList, function (item, xv) {
    return _c('el-option', {
      key: "".concat(item.businessNo).concat(xv),
      attrs: {
        "label": "".concat(item.businessNo, "-").concat(item.companyName),
        "value": item.businessNo
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Billing Party",
      "prop": "billingParty"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Billing party"
    },
    model: {
      value: _vm.dataForm.billingParty,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "billingParty", $$v);
      },
      expression: "dataForm.billingParty"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Address"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Address"
    },
    model: {
      value: _vm.dataForm.address,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "address", $$v);
      },
      expression: "dataForm.address"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Country"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width text-left",
    attrs: {
      "placeholder": "Country",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.country,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Code"
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "Code"
    },
    model: {
      value: _vm.dataForm.postalCode,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "postalCode", $$v);
      },
      expression: "dataForm.postalCode"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Currency",
      "prop": "paymentDetail.currency"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "placeholder": "Currency",
      "filterable": ""
    },
    on: {
      "change": _vm.currencyChange
    },
    model: {
      value: _vm.dataForm.paymentDetail.currency,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.paymentDetail, "currency", $$v);
      },
      expression: "dataForm.paymentDetail.currency"
    }
  }, _vm._l(_vm.currencyList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _vm.dataForm.paymentDetail.currency != 'SGD' ? _c('el-form-item', {
    attrs: {
      "label": "Exchange Rate",
      "prop": "paymentDetail.exchangeRate"
    }
  }, [_c('el-input', {
    directives: [{
      name: "positivePoint4",
      rawName: "v-positivePoint4"
    }],
    attrs: {
      "placeholder": "0.0000"
    },
    model: {
      value: _vm.dataForm.paymentDetail.exchangeRate,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.paymentDetail, "exchangeRate", $$v);
      },
      expression: "dataForm.paymentDetail.exchangeRate"
    }
  })], 1) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "Amount",
      "prop": "paymentDetail.amount"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.paymentDetail.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.paymentDetail, "amount", $$v);
      },
      expression: "dataForm.paymentDetail.amount"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v(_vm._s(_vm.dataForm.paymentDetail.currencyUnit))])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Description (For External Disclosure)",
      "prop": "applyReason"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "English wording is mandatory, Chinese translation is optional"
    },
    model: {
      value: _vm.dataForm.applyReason,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "applyReason", $$v);
      },
      expression: "dataForm.applyReason"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Third party fee",
      "prop": "paymentDetail.thirdPartyFee"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.paymentDetail.thirdPartyFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.paymentDetail, "thirdPartyFee", $$v);
      },
      expression: "dataForm.paymentDetail.thirdPartyFee"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v(_vm._s(_vm.dataForm.paymentDetail.currencyUnit))])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Note For Third Party",
      "prop": "noteForThirdParty"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width text-left",
    attrs: {
      "placeholder": "Note For Third Party",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.noteForThirdParty,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "noteForThirdParty", $$v);
      },
      expression: "dataForm.noteForThirdParty"
    }
  }, _vm._l(_vm.thirdPartyFeeEnOptions, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Other Fee",
      "prop": "paymentDetail.otherFee"
    }
  }, [_c('el-input', {
    directives: [{
      name: "minusPoint2",
      rawName: "v-minusPoint2"
    }],
    attrs: {
      "placeholder": "0.00"
    },
    model: {
      value: _vm.dataForm.paymentDetail.otherFee,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm.paymentDetail, "otherFee", $$v);
      },
      expression: "dataForm.paymentDetail.otherFee"
    }
  }, [_c('template', {
    slot: "prefix"
  }, [_vm._v(_vm._s(_vm.dataForm.paymentDetail.currencyUnit))])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "Note For Other Fee",
      "prop": "noteForOther"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width text-left",
    attrs: {
      "placeholder": "Note For Other Fee",
      "filterable": ""
    },
    model: {
      value: _vm.dataForm.noteForOther,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "noteForOther", $$v);
      },
      expression: "dataForm.noteForOther"
    }
  }, _vm._l(_vm.otherFeeEnOptions, function (item) {
    return _c('el-option', {
      key: item,
      attrs: {
        "label": item,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Attachments",
      "prop": "attachmentUrls"
    }
  }, [_c('el-multiple-file-uploader', {
    model: {
      value: _vm.dataForm.attachmentUrls,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "attachmentUrls", $$v);
      },
      expression: "dataForm.attachmentUrls"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }