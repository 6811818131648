var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "1300px",
      "title": "Edit Questionnaire",
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "autocomplete": "off",
      "label-position": "top"
    }
  }, [_vm._l(_vm.quJSON, function (data, idx) {
    return _c('div', {
      key: data.title
    }, [_c('el-form-item', {
      attrs: {
        "label": idx + 1 + '. ' + data.title,
        "prop": data.model
      }
    }, [data.type === 'textarea' ? _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 11,
        "xl": 8
      }
    }, [_c('el-input', {
      attrs: {
        "type": "textarea",
        "rows": 5
      },
      model: {
        value: _vm.dataForm[data.model],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, data.model, $$v);
        },
        expression: "dataForm[data.model]"
      }
    })], 1)], 1) : _vm._e(), data.type === 'input' ? _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 11,
        "xl": 8
      }
    }, [_c('el-input', {
      attrs: {
        "type": "input"
      },
      model: {
        value: _vm.dataForm[data.model],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, data.model, $$v);
        },
        expression: "dataForm[data.model]"
      }
    })], 1)], 1) : _vm._e(), data.type === 'multiplebox' ? _c('multiple-box', {
      attrs: {
        "options": data.options
      },
      model: {
        value: _vm.dataForm[data.model],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, data.model, $$v);
        },
        expression: "dataForm[data.model]"
      }
    }) : _vm._e(), data.type === 'radio' ? _c('el-radio-group', {
      model: {
        value: _vm.dataForm[data.model],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, data.model, $$v);
        },
        expression: "dataForm[data.model]"
      }
    }, _vm._l(data.options, function (item) {
      return _c('el-radio', {
        key: item.value,
        attrs: {
          "label": item.value
        }
      }, [_vm._v(_vm._s(item.title))]);
    }), 1) : _vm._e(), _vm.dataForm[data.model] && data.type === 'radio-input' ? _c('el-radio-group', {
      staticClass: "flex align-center",
      model: {
        value: _vm.dataForm[data.model][data.secondary],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm[data.model], data.secondary, $$v);
        },
        expression: "dataForm[data.model][data.secondary]"
      }
    }, _vm._l(data.options, function (item) {
      return _c('el-radio', {
        key: item.value,
        staticClass: "flex align-center",
        attrs: {
          "label": item.value
        }
      }, [_c('div', {
        staticClass: "flex align-center"
      }, [_vm._v(" " + _vm._s(item.title) + " "), item.other && item.other.type == 'input' && _vm.dataForm[data.model][data.secondary] == item.value ? _c('div', {
        staticClass: "ml8"
      }, [_c('el-input', {
        attrs: {
          "type": "input"
        },
        model: {
          value: _vm.dataForm[data.model][item.other.model],
          callback: function callback($$v) {
            _vm.$set(_vm.dataForm[data.model], item.other.model, $$v);
          },
          expression: "dataForm[data.model][item.other.model]"
        }
      })], 1) : _vm._e()])]);
    }), 1) : _vm._e(), _c('el-row', [_c('el-col', {
      attrs: {
        "lg": 10,
        "xl": 8
      }
    }, [data.type === 'country' ? _c('el-select', {
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "multiple": "",
        "filterable": ""
      },
      model: {
        value: _vm.dataForm[data.model],
        callback: function callback($$v) {
          _vm.$set(_vm.dataForm, data.model, $$v);
        },
        expression: "dataForm[data.model]"
      }
    }, [_vm._l(_vm.countryList, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      }, [_vm._v(_vm._s(item.title))]);
    }), _vm.businessGenre == 'COMPANY' ? _c('el-option', {
      attrs: {
        "label": "N/A",
        "value": "NONE"
      }
    }, [_vm._v("N/A")]) : _vm._e()], 2) : _vm._e()], 1)], 1), data.type === 'table' ? _c('div', [_c('el-button', {
      staticClass: "mb20",
      attrs: {
        "type": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.addMember(data.model);
        }
      }
    }, [_vm._v("Add beneficial owner")]), _c('el-table', {
      key: Math.random(),
      attrs: {
        "data": _vm.dataForm[data.model],
        "border": ""
      }
    }, [_c('el-table-column', {
      attrs: {
        "prop": "memberType",
        "label": "Type",
        "align": "center"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [scope.row.memberType == 'CORPORATE' ? _c('p', [_vm._v("Company")]) : _vm._e(), scope.row.memberType == 'INDIVIDUAL' ? _c('p', [_vm._v("Individual")]) : _vm._e()];
        }
      }], null, true)
    }), _c('el-table-column', {
      attrs: {
        "prop": "fullName",
        "label": "Name",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "nationality",
        "label": "Country / Nationality",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "phone",
        "label": "Mobile",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "email",
        "label": "Email",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "label": "Operation",
        "align": "center"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c('el-button', {
            attrs: {
              "type": "text",
              "size": "small"
            },
            on: {
              "click": function click($event) {
                return _vm.updateHandle(scope.$index, data.model, scope.row);
              }
            }
          }, [_vm._v("Edit")]), _c('el-button', {
            attrs: {
              "type": "text",
              "size": "small"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteMember(scope.$index, data.model);
              }
            }
          }, [_vm._v("Delete")])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e(), data.type === 'tableList' ? _c('div', [_c('el-button', {
      staticClass: "mb20",
      attrs: {
        "type": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.addMember(data.model);
        }
      }
    }, [_vm._v("Add fund")]), _c('el-table', {
      key: Math.random(),
      attrs: {
        "data": _vm.fundInfoList,
        "border": ""
      }
    }, [_c('el-table-column', {
      attrs: {
        "prop": "fundName",
        "label": "Proposed Fund Name",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundType",
        "label": "Type",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundRegistrationCountry",
        "label": "Registration Country",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "prop": "fundRegistration",
        "label": "Registration address",
        "align": "center"
      }
    }), _c('el-table-column', {
      attrs: {
        "label": "Operation",
        "align": "center"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c('el-button', {
            attrs: {
              "type": "text",
              "size": "small"
            },
            on: {
              "click": function click($event) {
                return _vm.updateHandle(scope.$index, data.model, scope.row);
              }
            }
          }, [_vm._v("Edit")]), _c('el-button', {
            attrs: {
              "type": "text",
              "size": "small"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteMember(scope.$index, data.model);
              }
            }
          }, [_vm._v("Delete")])];
        }
      }], null, true)
    })], 1)], 1) : _vm._e()], 1)], 1);
  }), _vm.businessType == 'SERVICE_TRANSFER' ? _c('div', [_c('el-form-item', {
    attrs: {
      "label": "".concat(_vm.quJSON.length + 1, ". Registered Office")
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.registeredForm.registeredOfficeType,
      callback: function callback($$v) {
        _vm.$set(_vm.registeredForm, "registeredOfficeType", $$v);
      },
      expression: "registeredForm.registeredOfficeType"
    }
  }, _vm._l(_vm.registerOfficeTypeList, function (item) {
    return _c('div', {
      key: item.value
    }, [_c('el-radio', {
      staticClass: "pt15 pb15",
      attrs: {
        "label": item.value
      }
    }, [_c('span', [_vm._v(_vm._s(item.title))]), item.value === 1 && _vm.registeredForm.registeredOfficeType === 1 ? _c('div', {
      staticClass: "mt15 ml24"
    }, [_c('el-radio-group', {
      model: {
        value: _vm.registeredForm.registeredOfficeId,
        callback: function callback($$v) {
          _vm.$set(_vm.registeredForm, "registeredOfficeId", $$v);
        },
        expression: "registeredForm.registeredOfficeId"
      }
    }, _vm._l(_vm.nativeRegisteredAddress, function (office) {
      return _c('el-radio', {
        key: office.id,
        attrs: {
          "label": office.id
        }
      }, [_vm._v(_vm._s(_vm.formatNativeAddress(office)))]);
    }), 1)], 1) : _vm._e(), item.value === 2 && _vm.registeredForm.registeredOfficeType === 2 ? _c('div', {
      staticClass: "mt10 ml24"
    }, [_c('el-row', {
      attrs: {
        "gutter": 20
      }
    }, [_c('el-col', {
      attrs: {
        "span": 9
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Address"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.registeredForm.registeredOfficeAddress,
        callback: function callback($$v) {
          _vm.$set(_vm.registeredForm, "registeredOfficeAddress", $$v);
        },
        expression: "registeredForm.registeredOfficeAddress"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "City"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.registeredForm.registeredOfficeCity,
        callback: function callback($$v) {
          _vm.$set(_vm.registeredForm, "registeredOfficeCity", $$v);
        },
        expression: "registeredForm.registeredOfficeCity"
      }
    })], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Country"
      }
    }, [_c('el-select', {
      attrs: {
        "placeholder": "Select Country",
        "filterable": ""
      },
      model: {
        value: _vm.registeredForm.registeredOfficeCountry,
        callback: function callback($$v) {
          _vm.$set(_vm.registeredForm, "registeredOfficeCountry", $$v);
        },
        expression: "registeredForm.registeredOfficeCountry"
      }
    }, _vm._l(_vm.countryList, function (item) {
      return _c('el-option', {
        key: item.value,
        attrs: {
          "label": item.title,
          "value": item.value
        }
      });
    }), 1)], 1)], 1), _c('el-col', {
      attrs: {
        "span": 5
      }
    }, [_c('el-form-item', {
      attrs: {
        "label": "Code"
      }
    }, [_c('el-input', {
      attrs: {
        "oninput": _vm.isEn
      },
      model: {
        value: _vm.registeredForm.registeredOfficePostalCode,
        callback: function callback($$v) {
          _vm.$set(_vm.registeredForm, "registeredOfficePostalCode", $$v);
        },
        expression: "registeredForm.registeredOfficePostalCode"
      }
    })], 1)], 1)], 1)], 1) : _vm._e()])], 1);
  }), 0)], 1)], 1) : _vm._e(), _vm.businessType == 'SERVICE_TRANSFER' && _vm.isNeedNomineeDirector || _vm.businessType == 'COMPREHENSIVE_CUSTOMIZED' ? [_c('el-form-item', {
    attrs: {
      "label": "Nominee Director Service"
    }
  }, [_c('el-checkbox', {
    staticClass: "checkboxCustom",
    attrs: {
      "true-label": 1,
      "false-label": 0
    },
    model: {
      value: _vm.hasNomineeDirector,
      callback: function callback($$v) {
        _vm.hasNomineeDirector = $$v;
      },
      expression: "hasNomineeDirector"
    }
  }, [_vm._v(" We consent to appoint one Nominee Director recommended by EStar Business Services Pte. Ltd. (\"EStar\"), and we understand that any legal impact caused by the Nominee Director's action has nothing to do with EStar. ")])], 1)] : _vm._e()], 2), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.dataFormSubmit();
      }
    }
  }, [_vm._v("OK")])], 1), _vm.addOrUpdateVisible ? _c('add-or-update', {
    ref: "addOrUpdate",
    attrs: {
      "businessType": _vm.businessType
    },
    on: {
      "change": _vm.doUpdateMember
    }
  }) : _vm._e(), _vm.updateFundVisible ? _c('update-fund', {
    ref: "updateFund",
    on: {
      "change": _vm.doUpdateFund
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }