var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', {
    attrs: {
      "width": "650px",
      "title": !_vm.dataForm.id ? 'Add Client code' : 'Edit Client code',
      "close-on-click-modal": false,
      "visible": _vm.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c('el-form', {
    ref: "dataForm",
    attrs: {
      "model": _vm.dataForm,
      "rules": _vm.dataRule,
      "autocomplete": "off",
      "label-width": "110px"
    }
  }, [_c('el-form-item', {
    staticClass: "is-required",
    attrs: {
      "label": "Client code",
      "prop": "clientCode"
    }
  }, [_c('el-input', {
    attrs: {
      "oninput": "value=value.replace(/[^a-zA-Z0-9]/g, '')",
      "placeholder": "Client code (Please enter the number)",
      "maxlength": "30",
      "show-word-limit": ""
    },
    model: {
      value: _vm.dataForm.clientCode,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "clientCode", $$v);
      },
      expression: "dataForm.clientCode"
    }
  }, [_c('template', {
    slot: "prepend"
  }, [_vm._v("c")])], 2)], 1), _c('el-form-item', {
    attrs: {
      "label": "UEN",
      "prop": "uens"
    }
  }, [_c('el-select', {
    staticClass: "fluid-width",
    attrs: {
      "multiple": "",
      "placeholder": "UEN",
      "filterable": "",
      "remote": "",
      "remote-method": _vm.getUenRemote,
      "loading": _vm.uenLoading,
      "value-key": "uen"
    },
    on: {
      "clear": function clear($event) {
        return _vm.getUenRemote('');
      },
      "focus": function focus($event) {
        return _vm.getUenRemote('');
      }
    },
    model: {
      value: _vm.dataForm.uens,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "uens", $$v);
      },
      expression: "dataForm.uens"
    }
  }, _vm._l(_vm.uenList, function (item, index) {
    return _c('el-option', {
      key: "".concat(item.uen).concat(index),
      attrs: {
        "label": item.uen,
        "value": item
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Country",
      "prop": "country"
    }
  }, [_c('el-select', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "filterable": "",
      "placeholder": "Select Country"
    },
    model: {
      value: _vm.dataForm.country,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "country", $$v);
      },
      expression: "dataForm.country"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('el-option', {
      key: item.value,
      attrs: {
        "label": item.title,
        "value": item.value
      }
    });
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "Contact Info",
      "prop": "contact"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Contact Info"
    },
    model: {
      value: _vm.dataForm.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "contact", $$v);
      },
      expression: "dataForm.contact"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Introducer",
      "prop": "introducer"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Introducer"
    },
    model: {
      value: _vm.dataForm.introducer,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "introducer", $$v);
      },
      expression: "dataForm.introducer"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "Description",
      "prop": "remark"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "autosize": {
        minRows: 4
      },
      "resize": "none",
      "placeholder": "Description"
    },
    model: {
      value: _vm.dataForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.dataForm, "remark", $$v);
      },
      expression: "dataForm.remark"
    }
  })], 1)], 1), _c('span', {
    staticClass: "dialog-footer",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm.isAuth('sys:client:update') ? _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.dataFormSubmit
    }
  }, [_vm._v("OK")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }